import React, { useRef } from "react";
import styles from "./index.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import GoogleIcon from "../../assets/Google_Icons.png";
import EmailIcon from "../../assets/Email.png";
import SkipIcon from "../../assets/Skip.png";
import EmailForm from "./component/EmailForm";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useReviewsJobContext } from "../../../../Api/reviews";
import VerifyForm from "./component/VerifyForm";
import Congratulation from "./component/Congratulation";
import ICON from "../../Utils/icons";
const ReviewAuth = ({ show, setShow }) => {
    const googleLoginWrapperRef = useRef(null);
    const { appendUserDetailsInReviewsForm } = useReviewsJobContext();
    const handleLoginSuccess = (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse.credential);
            const username = decoded.name;
            const email = decoded.email;
            const picture = decoded.picture || "No picture available";
            const userId = localStorage.getItem("userId");
            appendUserDetailsInReviewsForm(
                `/v1/review/${userId}`,
                {
                    username,
                    email,
                    picture,
                },
                () => {
                    setShow(
                        (prev) => {
                            prev.set("congratulation", true);
                            prev.delete("auth-option");
                            return prev;
                        },
                        { replace: true }
                    );
                }
            );
            console.log("Login Success:", { username, email });
        } catch (error) {
            console.error("Failed to decode token:", error);
        }
    };

    const handleLoginError = () => {
        console.error("Login Failed");
    };
    const handleCustomClick = () => {
        if (googleLoginWrapperRef.current) {
            console.log(googleLoginWrapperRef);
            const googleButton = googleLoginWrapperRef.current.querySelector('[role="button"]');
            if (googleButton) googleButton.click();
        }
    };

    return (
        show.get("show") === "true" && (
            <div className={styles.container_wrapper}>
                <div className={styles.container}>
                    {show.get("verify") === "true" && <VerifyForm setShow={setShow} show={show} />}
                    {show.get("email") === "true" && <EmailForm setShow={setShow} />}
                    {show.get("congratulation") === "true" && <Congratulation setShow={setShow} />}
                    {show.get("auth-option") === "true" && (
                        <div>
                            <h2>
                            Register today to  <span>compare hospital environments, salaries, and peer reviews</span>, and take control of your healthcare career path.
                                {/* Be the first to <span>compare work cultures</span> and receive the <span>latest job updates</span>. Stay informed and ahead of the curve! */}
                            </h2>
                            <div ref={googleLoginWrapperRef} style={{ display: "none" }}>
                                <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginError} />
                            </div>
                            <div className={styles.body}>
                                <div className={styles.auth_option} onClick={handleCustomClick}>
                                    <img src={GoogleIcon} alt="Google" srcset="" />
                                    <h3>{"Continue with Google"}</h3>
                                </div>
                                <div
                                    onClick={() =>
                                        setShow(
                                            (prev) => {
                                                prev.set("email", true);
                                                prev.delete("auth-option");
                                                return prev;
                                            },
                                            { replace: true }
                                        )
                                    }
                                    style={{ paddingBlock: "0.9rem" }}
                                    className={styles.auth_option}
                                >
                                    <img src={EmailIcon} alt="Email" srcset="" />
                                    <h3>{"Continue with Email"}</h3>
                                </div>
                                {/* <div className={styles.or}>
                                    <div></div>
                                    <p>OR</p> <div></div>
                                </div> */}
                                {/* <div onClick={() => {}} className={styles.skip_option}>
                                    <img src={SkipIcon} alt="Skip" srcset="" />
                                    <h3>{"Skip and Submit"}</h3>
                                </div> */}
                            </div>
                        </div>
                    )}

                    {
                        <div
                            onClick={() => {
                                if (show.get("auth-option") === "true") {
                                    setShow(
                                        (prev) => {
                                            prev.set("congratulation", true);
                                            prev.delete("auth-option");
                                            return prev;
                                        },
                                        { replace: true }
                                    );
                                } else {
                                    setShow((prev) => {
                                        prev.delete("auth-option");
                                        prev.delete("email");
                                        prev.delete("congratulation");
                                        prev.delete("verify");
                                        prev.delete("show");
                                        return prev;
                                    });
                                    window.location.reload();
                                }
                            }}
                            className={styles.cut}
                        >
                            {ICON.CUT}
                        </div>
                    }
                </div>
            </div>
        )
    );
};

export default ReviewAuth;
