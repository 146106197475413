import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ICON from "../../../../Utils/icons";
import { useReviewsJobContext } from "../../../../../../Api/reviews";

const VerifyForm = ({ setShow, show }) => {
    const { verifyUserTokenInReviewsForm } = useReviewsJobContext();
    const token = show.get("token");
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (token) {
            verifyUserTokenInReviewsForm(
                `/v1/review/${token}`,
                {
                    isVerified: true,
                },
                () => {
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShow(
                            (prev) => {
                                prev.delete("verify");
                                prev.set("congratulation", true);
                                return prev;
                            },
                            { replace: true }
                        );
                    }, 3000);
                }
            );
        }
    }, [token]);

    return (
        <div className={styles.container}>
            {showSuccess ? (
                <div className={styles.info_alert}>
                    <div className={styles.tick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 15 15">
                            <path fill="none" stroke="currentColor" d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 1 1 0-14a7 7 0 0 1 0 14Z" />
                        </svg>
                    </div>
                    <p>Your email has been successfully verified. Thank you!</p>
                </div>
            ) : (
                <div className={styles.info_alert}>
                    {ICON.EMAIL}
                    <p>{`Please check your email. We have sent you a verification link.`}</p>
                </div>
            )}
        </div>
    );
};

export default VerifyForm;
