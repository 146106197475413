import React from "react";
import styles from "./index.module.css";
import ICON from "../../../../Utils/icons";

const Congratulation = () => {
    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: "",
                    text: "",
                    url: "https://medmanch.com/reviews",
                })
                .then(() => console.log("Share successful"))
                .catch((error) => console.error("Error sharing:", error));
        } else {
            alert("Sharing is not supported in your browser.");
        }
    };

    const handleWhatsAppShare = () => {
        const message = encodeURIComponent("https://medmanch.com/reviews");
        const whatsappUrl = `https://wa.me/?text=${message}`;
        window.open(whatsappUrl, "_blank");
    };

    return (
        <div className={styles.container}>
            <h2>Congratulations</h2>
            <p>for being the</p>
            <div className={styles.buffer}>
                <p>{localStorage.getItem("userSequence") || "200"}th</p>
            </div>
            <p className={styles.sub_heading}>
                <span>Person</span> who cares for helping future Doctors make the right choice!
            </p>
            <p>Share With your colleagues and make an even bigger Impact</p>
            <div className={styles.button_container}>
                <button onClick={handleShare}>{ICON.SHARE} Share Link</button>
                <button onClick={handleWhatsAppShare}>{ICON.WHATSAPP} WhatsApp</button>
            </div>
        </div>
    );
};

export default Congratulation;
