import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./TableComponent.css"; // Add a CSS file for styling
import ReactPaginate from "react-paginate";
import { axiosInstance, useAxiosInterceptors } from "../../Api/Interceptors";
// import  axiosInstance from "../../ApiIntegration/Interceptors"
// import {getToken} from "../../ApiIntegration/token"

const TableComponent = ({ tableData }) => {
    const [data, setData] = useState(tableData);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [suggestions, setSuggestions] = useState(tableData.autoSuggestion);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchRef = useRef(null);

    const [sortBy, setSortBy] = useState(null);
    const [order, setOrder] = useState(null);

    //console.log(tableData)

    useEffect(() => {
        setData(tableData);
        generateSuggestions(tableData.rows);
        tableData.sorting && setSortBy(tableData.initialSort);
        tableData.sorting && setOrder(tableData.initialSortOrder);
    }, [tableData]);

    //   //console.log(suggestions)

    const [isLoadingExport, setIsLoadingExport] = useState(false);

    const handleExportClick = () => {
        setIsLoadingExport(true);
        // Simulate download completion after 3 seconds
        setTimeout(() => {
            setIsLoadingExport(false);
        }, 4000);
    };
    useEffect(() => {
        if (data.pagination) {
            fetchPageData(currentPage, itemsPerPage, sortBy, order, searchText);
        }
    }, [currentPage, itemsPerPage, sortBy, order, data.pagination]);

    //console.log(data.paginationUrl)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchPageData = async (page, limit, sortBy, order, searchText) => {
        setIsLoading(true);
        setError(null);
        try {
            const result = await axiosInstance.get(data.paginationUrl.replace("input", page).replace("count", limit), {
                params: { sortBy, order, searchText },
            });
            const newData = { ...data.getTableData(result.data) };
            setData(newData);
            generateSuggestions(newData.rows);
        } catch (err) {
            // setError('Failed to fetch data');
        } finally {
            setIsLoading(false);
        }
    };
    const generateSuggestions = (rows) => {
        //console.log(rows);
        if (!rows) return;
        const uniqueSuggestions = new Set();

        rows.forEach((row) => {
            let count = 0;

            Object.values(row).forEach((value) => {
                if (count >= 4) return; // Exit the loop if we have already processed four objects
                if (value && typeof value === "object" && value.value && value.type != "hidden") {
                    uniqueSuggestions.add(value.value.toString());
                } else if (value && value.type != "hidden") {
                    uniqueSuggestions.add(value.toString());
                }
                count++;
            });
        });

        //console.log(uniqueSuggestions);
        setSuggestions(Array.from(uniqueSuggestions));
    };

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    //   const handleSearch = async () =>{
    //       setIsLoading(true);
    //       setError(null);
    //       try {
    //         const result = await axios.get(data.searchUrl.replace('inputText', searchText));
    //         setData({ getTableData: data.getTableData, ...data.getTableData(result.data) });
    //       } catch (err) {
    //         setError('Failed to fetch search results');
    //       } finally {
    //         setIsLoading(false);
    //       }
    //     }

    const fetchSuggestions = useCallback(
        debounce(async (searchText) => {
            setError(null);
            try {
                const result = await axiosInstance.get(data.autoSuggestionUrl.replace("inputText", searchText));
                setSuggestions(result.data.autoSuggestions || []);
            } catch (err) {
                setError("Failed to fetch suggestions");
            }
        }, 300),
        [searchText]
    );

    const handleSuggestionClick = (suggestion) => {
        setSearchText(suggestion);
        fetchPageData(currentPage, itemsPerPage, sortBy, order, suggestion);
        setShowSuggestions(false);
    };

    const handleSearchClick = () => {
        setShowSuggestions(true);
    };

    const handleExport = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Simulating export loading delay
            await new Promise((resolve) => setTimeout(resolve, 1500));
            // Implement export logic, e.g., download the file
        } catch (err) {
            setError("Failed to export data");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrint = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Simulating print loading delay
            await new Promise((resolve) => setTimeout(resolve, 1500));
            // Implement print logic, e.g., open print dialog with the data
        } catch (err) {
            setError("Failed to print data");
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        setSearchText(event.target.value);
        //  event.target.value.length > 0  && fetchSuggestions(event.target.value);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to the first page when items per page changes
    };

    const getCurrentItemRange = () => {
        const start = (currentPage - 1) * itemsPerPage + 1;
        const end = Math.min(currentPage * itemsPerPage, data.totalItemCount);
        return `${start} to ${end}`;
    };

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const handleSort = (headerItem) => {
        const newOrder = sortBy === headerItem && order === "asc" ? "desc" : "asc";
        setSortBy(headerItem);
        setOrder(newOrder);
    };

    const getSortIcon = (headerItem) => {
        //console.log(headerItem)
        if (sortBy === headerItem) {
            return order === "asc" ? "↑" : "↓";
        }
        return "";
    };

    return (
        <div className="table-container">
            <div className="table-heading">
                {data.title && <h2>{data.title}</h2>}

                <div className="search_export_print_container">
                    {data.searchBar && (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                fetchPageData(currentPage, itemsPerPage, sortBy, order, searchText);
                            }}
                            ref={searchRef}
                            className="search-container"
                        >
                            <input type="text" placeholder="Search..." value={searchText} onChange={handleInputChange} onClick={handleSearchClick} className="search-bar" />
                            {/* {showSuggestions && suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )} */}
                        </form>
                    )}

                    <div className="utility-buttons">
                        {data.reset && (
                            <button
                                onClick={() => {
                                    setSearchText("");
                                    setCurrentPage(1);
                                    fetchPageData(1, itemsPerPage, sortBy, order, "");
                                }}
                                className="reset"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="currentColor"
                                        d="M12 17q0-1.125.463-2.075t1.237-1.65L15.85 17zm2.5 4.3q-.925-.525-1.55-1.375T12.1 18h4.325zm1.925-5.3L14.5 12.7q.575-.325 1.2-.513T17 12q.425 0 .813.075t.762.2zM17 22q-.425 0-.812-.075t-.763-.2L17.575 18l1.925 3.3q-.575.325-1.2.513T17 22m.575-6l1.925-3.3q.925.525 1.55 1.375T21.9 16zm2.725 4.725L18.15 17H22q0 1.125-.45 2.075t-1.25 1.65M12 5Q9.075 5 7.038 7.038T5 12q0 1.8.813 3.3T8 17.75V16q0-.425.288-.712T9 15t.713.288T10 16v4q0 .425-.288.713T9 21H5q-.425 0-.712-.288T4 20t.288-.712T5 19h1.35Q4.8 17.75 3.9 15.938T3 12q0-1.875.713-3.512t1.924-2.85t2.85-1.925T12 3q2.825 0 5.088 1.575t3.262 4.05q.15.4 0 .775t-.55.525t-.788 0t-.537-.55q-.775-1.95-2.513-3.162T12 5"
                                    />
                                </svg>
                                Reset
                            </button>
                        )}
                        {data.export && (
                            <div>
                                <a download href={`${data.exportDataUrl}&token=${JSON.parse(localStorage.getItem("userToken"))?.refreshToken}`} onClick={handleExportClick}>
                                    <button className="export">
                                        {isLoadingExport ? (
                                            <p
                                                style={{
                                                    paddingInline: "1.5rem",
                                                    margin: "0",
                                                    paddingBlock: "0rem",
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                    <path
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeDasharray="15"
                                                        strokeDashoffset="15"
                                                        strokeLinecap="round"
                                                        strokeWidth="2"
                                                        d="M12 3C16.9706 3 21 7.02944 21 12"
                                                    >
                                                        <animate fill="freeze" attributeName="strokeDashoffset" dur="0.3s" values="15;0" />
                                                        <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                                                    </path>
                                                </svg>{" "}
                                            </p>
                                        ) : (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            fill="currentColor"
                                                            d="m12 5l-.707-.707l.707-.707l.707.707zm1 9a1 1 0 1 1-2 0zM6.293 9.293l5-5l1.414 1.414l-5 5zm6.414-5l5 5l-1.414 1.414l-5-5zM13 5v9h-2V5z"
                                                        />
                                                        <path stroke="currentColor" strokeWidth="2" d="M5 16v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1" />
                                                    </g>
                                                </svg>
                                                Export
                                            </>
                                        )}
                                    </button>
                                </a>
                            </div>
                        )}
                        {data.print && (
                            <a download={"Registration List"} href={`${data.printUrl}&token=${JSON.parse(localStorage.getItem("userToken"))?.refreshToken}`}>
                                <button className="print">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5">
                                        <path
                                            d="M6 17.9827C4.44655 17.9359 3.51998 17.7626 2.87868 17.1213C2 16.2426 2 14.8284 2 12C2 9.17157 2 7.75736 2.87868 6.87868C3.75736 6 5.17157 6 8 6H16C18.8284 6 20.2426 6 21.1213 6.87868C22 7.75736 22 9.17157 22 12C22 14.8284 22 16.2426 21.1213 17.1213C20.48 17.7626 19.5535 17.9359 18 17.9827"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        ></path>
                                        <path opacity="0.5" d="M9 10H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M19 14L5 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path
                                            d="M18 14V16C18 18.8284 18 20.2426 17.1213 21.1213C16.2426 22 14.8284 22 12 22C9.17157 22 7.75737 22 6.87869 21.1213C6 20.2426 6 18.8284 6 16V14"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M17.9827 6C17.9359 4.44655 17.7626 3.51998 17.1213 2.87868C16.2427 2 14.8284 2 12 2C9.17158 2 7.75737 2 6.87869 2.87868C6.23739 3.51998 6.06414 4.44655 6.01733 6"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        ></path>
                                        <circle opacity="0.5" cx="17" cy="10" r="1" fill="currentColor"></circle>
                                        <path opacity="0.5" d="M15 16.5H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path opacity="0.5" d="M13 19H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                    Print
                                </button>
                            </a>
                        )}

                        {data.allAction && (
                            <button onClick={data.allAction} className="all_action">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="currentColor"
                                        d="M12 21q-1.875 0-3.512-.712t-2.85-1.925t-1.925-2.85T3 12t.713-3.512t1.924-2.85t2.85-1.925T12 3v2Q9.075 5 7.038 7.038T5 12t2.038 4.963T12 19zm4-4l-1.4-1.425L17.175 13H9v-2h8.175L14.6 8.4L16 7l5 5z"
                                    />
                                </svg>
                                Action
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {isLoading && <p>Loading...</p>}
            {error && <p className="error-message">{error}</p>}

            <table className="table">
                <thead>
                    <tr>
                        {Object.keys(data.rows && data.rows[0] ? data.rows[0] : {}).map((headerItem, index) => {
                            return (
                                data.rows[0]?.[headerItem].type !== "hidden" && (
                                    <th key={index} onClick={() => tableData.sorting && handleSort(data.rows[0]?.[headerItem].key)}>
                                        {headerItem} {tableData.sorting && getSortIcon(data.rows[0]?.[headerItem].key)}
                                    </th>
                                )
                            );
                        })}
                        {data.action && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.rows &&
                        data.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => {
                                    return (
                                        cell.type !== "hidden" && (
                                            <td data-cell={Object.keys(data.rows[0])[cellIndex]} key={cellIndex}>
                                                {cell.value}
                                            </td>
                                        )
                                    );
                                })}
                                {data.action && (
                                    <td data-cell="Action">
                                        <div className="action-button">
                                            {data.actionData.map((action, actionIndex) => (
                                                <p
                                                    key={actionIndex}
                                                    onClick={() => {
                                                        action.functions(row?.[`${action.Id}`].value);
                                                    }}
                                                    className=""
                                                    title={action.label}
                                                >
                                                    {getSvg(action.name)}
                                                </p>
                                            ))}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>

            {data.pagination && (
                <div className="pagination_container">
                    <div>
                        <p>
                            Showing {getCurrentItemRange()} of {data.totalItemCount} Entries
                        </p>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                    </div>

                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(data.totalPage || 0)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        forcePage={Math.ceil(currentPage - 1)}
                    />
                </div>
            )}
        </div>
    );
};

export default TableComponent;

function getSvg(name) {
    switch (name) {
        case "Edit":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4.5 h-4.5">
                    <path
                        opacity="0.5"
                        d="M22 10.5V12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2H13.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    ></path>
                    <path
                        d="M17.3009 2.80624L16.652 3.45506L10.6872 9.41993C10.2832 9.82394 10.0812 10.0259 9.90743 10.2487C9.70249 10.5114 9.52679 10.7957 9.38344 11.0965C9.26191 11.3515 9.17157 11.6225 8.99089 12.1646L8.41242 13.9L8.03811 15.0229C7.9492 15.2897 8.01862 15.5837 8.21744 15.7826C8.41626 15.9814 8.71035 16.0508 8.97709 15.9619L10.1 15.5876L11.8354 15.0091C12.3775 14.8284 12.6485 14.7381 12.9035 14.6166C13.2043 14.4732 13.4886 14.2975 13.7513 14.0926C13.9741 13.9188 14.1761 13.7168 14.5801 13.3128L20.5449 7.34795L21.1938 6.69914C22.2687 5.62415 22.2687 3.88124 21.1938 2.80624C20.1188 1.73125 18.3759 1.73125 17.3009 2.80624Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    ></path>
                    <path
                        opacity="0.5"
                        d="M16.6522 3.45508C16.6522 3.45508 16.7333 4.83381 17.9499 6.05034C19.1664 7.26687 20.5451 7.34797 20.5451 7.34797M10.1002 15.5876L8.4126 13.9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    ></path>
                </svg>
            );
        case "View":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
                    <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    ></path>
                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="currentColor" strokeWidth="1.5"></path>
                </svg>
            );
        case "Delete":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
                    <path d="M20.5001 6H3.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path
                        d="M18.8334 8.5L18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    ></path>
                    <path opacity="0.5" d="M9.5 11L10 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path opacity="0.5" d="M14.5 11L14 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path
                        opacity="0.5"
                        d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    ></path>
                </svg>
            );
        case "Duplicate":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                    <rect width="336" height="336" x="128" y="128" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" rx="57" ry="57" />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                        d="m383.5 128l.5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24m168-168v160m80-80H216"
                    />
                </svg>
            );
        case "Reset":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M12 17q0-1.125.463-2.075t1.237-1.65L15.85 17zm2.5 4.3q-.925-.525-1.55-1.375T12.1 18h4.325zm1.925-5.3L14.5 12.7q.575-.325 1.2-.513T17 12q.425 0 .813.075t.762.2zM17 22q-.425 0-.812-.075t-.763-.2L17.575 18l1.925 3.3q-.575.325-1.2.513T17 22m.575-6l1.925-3.3q.925.525 1.55 1.375T21.9 16zm2.725 4.725L18.15 17H22q0 1.125-.45 2.075t-1.25 1.65M12 5Q9.075 5 7.038 7.038T5 12q0 1.8.813 3.3T8 17.75V16q0-.425.288-.712T9 15t.713.288T10 16v4q0 .425-.288.713T9 21H5q-.425 0-.712-.288T4 20t.288-.712T5 19h1.35Q4.8 17.75 3.9 15.938T3 12q0-1.875.713-3.512t1.924-2.85t2.85-1.925T12 3q2.825 0 5.088 1.575t3.262 4.05q.15.4 0 .775t-.55.525t-.788 0t-.537-.55q-.775-1.95-2.513-3.162T12 5"
                    />
                </svg>
            );
    }
}
