import React from "react";
import styles from "./index.module.css";

const ReviewDetails = ({ reviewDetails }) => {
    const formatText = (input) => {
        return input
            .replace(/([A-Z])/g, " $1")
            .replace(/_/g, " ")
            .replace(/^\s*/, "")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const renderDetails = (key, value, parentKey = "") => {
        const formattedKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
            // Render array items
            return value.map((item, index) =>
                typeof item === "object" ? (
                    Object.entries(item).map(([nestedKey, nestedValue]) => renderDetails(`[${index}].${nestedKey}`, nestedValue, formattedKey))
                ) : (
                    <li key={`${formattedKey}[${index}]`}>
                        <span>{formatText(`${formattedKey}[${index}]`)}:</span> <span>{item || "NA"}</span>
                    </li>
                )
            );
        } else if (typeof value === "object" && value !== null) {
            // Render nested object keys
            return Object.entries(value).map(([nestedKey, nestedValue]) => renderDetails(nestedKey, nestedValue, formattedKey));
        } else {
            // Render primitive values
            return (
                <li key={formattedKey}>
                    <span>{formatText(formattedKey)}:</span> <span>{value || "NA"}</span>
                </li>
            );
        }
    };

    return <ul className={styles.assetDetailsList}>{Object.entries(reviewDetails || {}).map(([key, value]) => renderDetails(key, value))}</ul>;
};

export default ReviewDetails;
