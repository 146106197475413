import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.css";
import { toast } from "react-toastify";
import { useReviewsJobContext } from "../../../../Api/reviews";
import { ROLES_LIST } from "../../../../Utils/Constants";
import { Navbar } from "../../../../Layout";
import TableComponent from "../../../../Components/CustomTable";
import AlertBox from "../../../../Components/AlertBox";
import { useVerifyRole } from "../../../../Utils/Helpers";
import ReviewDetails from "../../components/reviewDetails";
import Popup from "../../components/Popup/Popup";
import ICON from "../../Utils/icons";
import Utils from "../../Utils";
const ReviewList = () => {
    const [activeTab, setActiveTab] = useState("");
    const [loading, setLoading] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const { getReviewList, reviewList } = useReviewsJobContext();
    const isVerifiedRole = useVerifyRole([ROLES_LIST.ADMIN]);
    const URL = process.env.REACT_APP_API_BASE_URL;
    const [reviewDetails, setReviewDetails] = useState(null);
    const notify = (message) => toast(message);

    useEffect(() => {
        getReviewList(`/v1/review?tab=${activeTab}`);
    }, [activeTab]);

    // console.log(reviewList);

    function getTableData(data) {
        const actionData = [
            {
                name: "View",
                functions: (index) => {
                    setReviewDetails(data.data.find((item) => item._id === index));
                },
                label: "View Details",
                Id: "Id",
            },
        ];
        // if (isVerifiedRole) {
        // actionData.push();
        // }
        return {
            title: "Review List",
            rows: data?.data?.map((item) => {
                return {
                    Id: { key: "id", value: item._id, type: "hidden" },
                    Hospital: { key: "organizationName", value: item.organizationName },
                    Course: { key: "course", value: item.course },
                    "Passing Rate": { key: "passingRate", value: item.passingRate },
                    "Created At": { key: "createdAt", value: Utils.getFormateDate(item.createdAt) },
                };
            }),
            action: true,
            actionData,
            searchBar: true,
            searchUrl: `/v1/review?searchText=inputText`,
            export: true,
            exportDataUrl: `${URL}/v1/review?exportFormat=excel&tab=${activeTab}`,
            print: true,
            printUrl: `${URL}/v1/review?exportFormat=pdf&tab=${activeTab}`,
            reset: true,
            pagination: true,
            paginationUrl: `/v1/review?page=input&limit=count&tab=${activeTab}`,
            totalPage: data.totalPages || 1,
            totalItemCount: data.totalDocuments || 3,
            //   "autoSuggestionUrl":"/getautosuggestion/Users?searchText=inputText&fields=employeename,email,employeeId,contactNumber",
            sorting: true,
            //   "initialSort":'employeeId',
            //   "initialSortOrder":'asc',
            getTableData: getTableData,
            //   "allAction": () => {setShowActionPopUp(!showActionpopUp)},
        };
    }

    const tableData = useMemo(() => getTableData(reviewList), [reviewList]);

    // function deleteData(url) {
    //     setLoading(true);
    //     axiosInstance
    //         .delete(url)
    //         .then((response) => {
    //             //console.log(response.data)
    //             getData(activeTab === 1 ? `/v1/waitlist` : `/v1/contact/contacts`);
    //             setLoading(false);
    //             notify(response.data.message);
    //             setAlertShow(false);
    //         })
    //         .catch((error) => {
    //             //console.log(error)
    //             setLoading(false);
    //             notify(error?.response?.data?.message);
    //             setAlertShow(false);
    //         });
    // }

    if (!isVerifiedRole) {
        return "Access Denied";
    }

    return (
        <div className={styles.visiting_admin_dashboard_main_container_wrapper}>
            <Navbar />
            <div className={styles.visiting_admin_dashboard_main_container}>
                <div className={styles.tabs}>
                    {[
                        { label: "All", value: "" },
                        { label: "Review With Email Verification", value: "reviewWithUserVerification" },
                        { label: "Review Without Email Verification", value: "reviewWithoutUserVerification" },
                    ].map((item) => (
                        <h3 onClick={() => setActiveTab(item.value)} className={activeTab === item.value && styles.active}>
                            {item.label}
                        </h3>
                    ))}
                </div>

                {loading ? (
                    <p className={styles.loading}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
                            <circle cx="18" cy="12" r="0" fill="currentColor">
                                <animate
                                    attributeName="r"
                                    begin=".67"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;2;0;0"
                                />
                            </circle>
                            <circle cx="12" cy="12" r="0" fill="currentColor">
                                <animate
                                    attributeName="r"
                                    begin=".33"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;2;0;0"
                                />
                            </circle>
                            <circle cx="6" cy="12" r="0" fill="currentColor">
                                <animate
                                    attributeName="r"
                                    begin="0"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;2;0;0"
                                />
                            </circle>
                        </svg>
                    </p>
                ) : (
                    <TableComponent tableData={tableData} />
                )}
                {alertShow && (
                    <AlertBox
                        data={{
                            heading: "Delete",
                            description: `are you sure you want to delete row`,
                            action: [
                                {
                                    label: "Yes",
                                    actionFunction: () => {
                                        const url = activeTab === 1 ? `${URL}/waitlist/${deleteId}` : `v1/contact/contacts/${deleteId}`;
                                        // deleteData(url);
                                    },
                                    className: "yes_button",
                                    loading: loading,
                                },
                                {
                                    label: "No",
                                    actionFunction: () => {
                                        setAlertShow(false);
                                    },
                                    className: "no_button",
                                    // loading:loading
                                },
                            ],
                            setClose: () => {
                                setAlertShow(false);
                            },
                        }}
                    />
                )}
            </div>
            <Popup
                style={{ body: { padding: "0" } }}
                width={"70%"}
                show={reviewDetails}
                icon={ICON.REVIEW}
                title={"Review Details"}
                subTitle={"Comprehensive Review Information"}
                onClose={() => setReviewDetails(null)}
            >
                <ReviewDetails reviewDetails={reviewDetails} />
            </Popup>
        </div>
    );
};

export default ReviewList;
