import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./Store/index.js";
import { GovernmentJobProvider } from "./Api/governmentJob.js";
import { LoadingProvider } from "./Api/loading.js";
import { BrowserRouter } from "react-router-dom";
import { PrivateJobProvider } from "./Api/privatejobPosting.js";
import { ReviewsJobProvider } from "./Api/reviews.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Provider store={store}>
                    <GovernmentJobProvider>
                        <LoadingProvider>
                            <PrivateJobProvider>
                                <ReviewsJobProvider>
                                    <App />
                                </ReviewsJobProvider>
                            </PrivateJobProvider>
                        </LoadingProvider>
                    </GovernmentJobProvider>
                </Provider>
            </GoogleOAuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
