import constants from "./constants";

class Utils {
    static calculateProgress(formValue, setProgress) {
        const totalSections = 13;
        const weightPerSection = 100 / totalSections;

        let filledSections = 0;

        Object.entries(formValue).forEach(([key, value]) => {
            if (typeof value === "object" && !Array.isArray(value)) {
                const subValues = Object.values(value);
                if (subValues.some((subValue) => subValue !== null && subValue !== "" && subValue !== undefined)) {
                    filledSections++;
                }
            } else if (Array.isArray(value)) {
                if (value.length > 0 && value.some((item) => Object.values(item).some((val) => val !== null && val !== "" && val !== undefined))) {
                    filledSections++;
                }
            } else {
                if (value !== null && value !== "" && value !== undefined) {
                    filledSections++;
                }
            }
        });

        const progress = Math.min(filledSections * weightPerSection, 100);
        setProgress(progress.toFixed(0));
    }
    static validateForm = (setErrors, formValues) => {
        const newErrors = {};

        Object.keys(constants.validateSchema).forEach((field) => {
            const rule = constants.validateSchema[field];
            const value = formValues[field];

            if (field === "ratings") {
                rule.forEach((item) => {
                    const ratingValue = value?.find((rating) => rating.criteria === item.criteria)?.stars;
                    if (item.required && !ratingValue) {
                        newErrors.ratings = {
                            ...(newErrors.ratings || {}),
                            [item.criteria]: item.message,
                        };
                    }
                });
            } else if (typeof rule === "object" && !Array.isArray(rule)) {
                Object.keys(rule).forEach((subField) => {
                    const subValue = value?.[subField];
                    console.log(subField);
                    if (typeof rule[subField].required === "function") {
                        // Call the function with formValues to determine if the field is required
                        if (rule[subField].required(formValues) && (subValue === undefined || subValue === null || subValue === "")) {
                            newErrors[field] = {
                                ...(newErrors[field] || {}),
                                [subField]: rule[subField].message,
                            };
                        }
                    } else if (rule[subField].required && (subValue === undefined || subValue === null || subValue === "")) {
                        newErrors[field] = {
                            ...(newErrors[field] || {}),
                            [subField]: rule[subField].message,
                        };
                    }
                });
            } else if (typeof rule.required === "function" ? rule.required(formValues) : rule.required) {
                if (!value) {
                    newErrors[field] = rule.message;
                }
            }
        });
        console.log(newErrors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    static generateRandomString() {
        return "xxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, function () {
            return ((Math.random() * 16) | 0).toString(16); // Generate random hex values
        });
    }
    static getFormateDate = (dateString) => {
        const dateObj = new Date(dateString);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        if (day < 10) {
            day = "0" + day;
        }
        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    };
}

export default Utils;
