import React, { useState, useEffect, useCallback } from "react";
import Select, { components } from "react-select";
import { FixedSizeList as List } from "react-window";

// ... keeping existing indicator components unchanged ...
const DropdownIndicator = (props) => {
    if (props.selectProps.isDisabled) {
        return null;
    }
    return <components.DropdownIndicator {...props} />;
};

const MultiValueRemove = (props) => {
    if (props.selectProps.isDisabled) {
        return null;
    }

    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                ...props.innerProps,
                style: {
                    ...props.innerProps.style,
                    cursor: "pointer",
                    color: "rgb(115, 103, 240)",
                    transition: "color 0.2s",
                },
                onMouseEnter: (e) => (e.currentTarget.style.backgroundColor = "rgb(233, 231, 253)"),
                onMouseLeave: (e) => (e.currentTarget.style.backgroundColor = "rgb(233, 231, 253)"),
            }}
        />
    );
};

const CustomSingleValue = (props) => <components.SingleValue {...props}>{props.isDisabled ? <span>{props.data.label}</span> : props.children}</components.SingleValue>;

const CustomMultiValueLabel = (props) => <components.MultiValueLabel {...props}>{props.data.disabled ? <span>{props.data.label}</span> : props.children}</components.MultiValueLabel>;

const MenuList = (props) => {
    const { options, children, maxHeight, getValue, onLoadMore } = props;
    const height = 35;
    const [value] = getValue();

    const initialOffset = options.indexOf(value) * height;

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight + 10) {
            onLoadMore();
        }
    };

    return (
        <div onScroll={handleScroll} style={{ maxHeight, overflowY: "auto" }}>
            <List height={maxHeight} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset} width="100%">
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        </div>
    );
};

const CustomSelect = ({ name, formValues, placeholder, handleSelectChange, options, multiple, required, disabled, style, classNames, borderColor = "#108A00" }) => {
    const [visibleOptions, setVisibleOptions] = useState(options.slice(0, 500));
    const [offset, setOffset] = useState(500);

    // Function to create a custom option from input
    const createCustomOption = (input) => ({
        label: input,
        value: input,
        isCustom: true,
    });

    const loadMoreOptions = useCallback(() => {
        if (offset < options.length) {
            const nextOptions = options.slice(offset, offset + 500);
            setVisibleOptions((prev) => [...prev, ...nextOptions]);
            setOffset((prev) => prev + 500);
        }
    }, [offset, options]);

    // Optimized filtering function
    const filterOptions = useCallback(
        (inputValue) => {
            if (!inputValue) {
                return options.slice(0, 500);
            }

            const lowerInput = inputValue.toLowerCase();
            const filtered = options.filter((option) => option.label.toLowerCase().includes(lowerInput));

            const shouldAddCustomOption = !filtered.some((option) => option.label.toLowerCase() === lowerInput);

            if (shouldAddCustomOption) {
                filtered.push(createCustomOption(inputValue));
            }

            return filtered;
        },
        [options]
    );

    // Direct input change handler
    const handleInputChange = useCallback(
        (newValue, { action }) => {
            if (action === "input-change") {
                // Immediately update visible options
                setVisibleOptions(filterOptions(newValue));

                // // Ensure input gets focus on mobile devices
                // const inputElement = document.querySelector(`.react-select-container input`);
                // if (inputElement) {
                //     inputElement.removeAttribute("readonly");
                //     requestAnimationFrame(() => {
                //         inputElement.focus();
                //     });
                // }
            }
            return newValue;
        },
        [filterOptions]
    );

    // Reset options when component mounts or options change
    useEffect(() => {
        setVisibleOptions(options.slice(0, 500));
        setOffset(500);
    }, [options]);

    return (
        <Select
            id={name}
            name={name}
            value={multiple ? options.filter((option) => formValues[name]?.includes(option.value)) : options.find((option) => option.value === formValues[name])}
            onChange={(selectedOption) =>
                handleSelectChange({
                    target: {
                        name,
                        value: multiple ? selectedOption.map((opt) => opt.value) : selectedOption?.value,
                    },
                })
            }
            onInputChange={handleInputChange}
            options={visibleOptions}
            isMulti={multiple}
            required={required || false}
            isDisabled={disabled}
            isSearchable={true}
            placeholder={placeholder}
            components={{
                DropdownIndicator,
                MultiValueRemove,
                SingleValue: CustomSingleValue,
                MultiValueLabel: CustomMultiValueLabel,
                MenuList: (props) => <MenuList {...props} onLoadMore={loadMoreOptions} />,
            }}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "white",
                    textAlign: "left",
                    color: "white",
                    fontSize: "0.9rem",
                    borderColor: state.isFocused ? borderColor : "lightgray",
                    boxShadow: state.isFocused ? `0 0 0 1px ${borderColor}` : "none",
                    "&:hover": {
                        borderColor: borderColor,
                    },
                    paddingBlock: "0.1rem",
                    paddingInline: "0.15rem",
                    borderRadius: "0.5rem",
                    fontWeight: "400",
                }),
                menu: (provided) => ({
                    ...provided,
                    textAlign: "left",
                    fontSize: "0.9rem",
                    color: "#999",
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? "rgb(115, 103, 240)" : "white",
                    color: state.isFocused ? "white" : "#333",
                    cursor: "pointer",
                    fontStyle: state.data.isCustom ? "italic" : "normal",
                }),
                multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "rgb(233, 231, 253)",
                    color: "white",
                    borderRadius: "5px",
                    paddingInline: "0.5rem",
                }),
                multiValueLabel: (provided) => ({
                    ...provided,
                    color: "rgb(115, 103, 240)",
                }),
                ...style,
            }}
            className={["react-select-container", ...classNames].join(" ")}
            blurInputOnSelect={false}
        />
    );
};

export default CustomSelect;
