const constants = {
    collegeList: [
        "Aarupadai Veedu Medical College, Pondicherry",
        "Abhishek I Mishra Memorial Medical College & Research, Bhiali",
        "Acharya Harihar Regional Cancer Centre, Cuttack",
        "Acharya Shri Chander College of Medical Sciences, Jammu",
        "ACPM Medical College, Dhule",
        "ACS Medical College and Hospital, Chennai",
        "ACSR Government Medical College Nellore",
        "Adesh Institute of Medical Sciences & Research, Bhatinda",
        "Adesh Medical College and Hospital, Shahabad, Kurukshetra, Haryana",
        "Adichunchanagiri Institute of Medical Sciences Bellur",
        "Agartala Government Medical College,Agartala",
        "Ajay Sangaal Institute of Medical Sciences and Ayushman Hospital",
        "A J Institute of Medical Sciences & Research Centre, Mangalore",
        "Akash Institute of Medical Sciences & Research Centre, Devanhalli, Bangalore, Karnataka",
        "Al-Ameen Medical College,Bijapur",
        "Al-Azhar Medical College and Super Speciality Hospital, Thodupuzha",
        "Al Falah School of Medical Sciences & Research Centre, Faridabad",
        "All India Institute of Hygiene & Public Health, Kolkata",
        "All India Institute of Medical Sciences, Bhatinda",
        "All India Institute of Medical Sciences, Bhopal",
        "All India Institute of Medical Sciences, Bhubaneswar",
        "All India Institute of Medical Sciences, Bibinagar",
        "All India Institute of Medical Sciences, Bilaspur",
        "All India Institute of Medical Sciences, Deoghar",
        "All India Institute of Medical Sciences, Gorakhpur",
        "All India Institute of Medical Sciences, Guwahati",
        "All India Institute of Medical Sciences, Jodhpur",
        "All India Institute of Medical Sciences, Kalyani, Nadia",
        "All India Institute of Medical Sciences, Madurai",
        "All India Institute of Medical Sciences, Mangalagiri, Vijayawada",
        "All India Institute of Medical Sciences, Nagpur",
        "All India Institute of Medical Sciences, New Delhi",
        "All India Institute of Medical Sciences, Patna",
        "All India Institute of Medical Sciences, Rae Bareli",
        "All India Institute of Medical Sciences, Raipur",
        "All India Institute of Medical Sciences, Rajkot",
        "All India Institute of Medical Sciences, Rishikesh",
        "All India Institute of Medical Sciences, Vijaypur",
        "All India Institute of Physical Med. and Rehabilitation, Mumbai",
        "Alluri Sitaram Raju Academy of Medical Sciences, Eluru",
        "Amala Institute of Medical Sciences, Thrissur",
        "Amaltas Institute of Medical Sciences, Dewas",
        "American International Institute of Medical Sciences, Bedwas",
        "Amrita School of Medicine, Elamkara, Kochi",
        "Amrita School of Medicine, Faridabad",
        "Ananta Institute of Medical Sciences & Research Centre, Rajsamand",
        "Ananya College of Medicine & Research",
        "Andaman & Nicobar Islands Institute of Medical Sciences, Port Blair",
        "Andhra Medical College, Visakhapatnam",
        "Annaii Medical College and Hospital",
        "Annaii Medical College and Hospital, Pennalur, Kanchipuram",
        "Annapoorna Medical College & Hospital, Salem",
        "Anugrah Narayan Magadh Medical College, Gaya",
        "Apollo Institute of Medical Sciences and Research, Chittoor",
        "Apollo Institute of Medical Sciences and Research, Hyderabad",
        "Aravind Eye Hospitals & Post Graduate Institute of Ophthalmology, Madurai",
        "Armed Forces Medical College, Pune",
        "Army College of Medical Sciences, New Delhi",
        "Army Hospital Research & Referral, New Delhi",
        "Arunai Medical College And Hospital",
        "Arundathi Institute of Medical Sciences",
        "Ashwini Rural Medical College, Hospital & Research Centre, Solapur",
        "Assam Medial College, Dibrugarh",
        "Atal Bihari Vajpayee Institute of Medical Sciences and Dr. RML Hospital, New Delhi",
        "Autonomous State Medical College, Akbarpur",
        "Autonomous State Medical College and Hospital Auraiya",
        "Autonomous State Medical College and Hospital Gonda",
        "Autonomous State Medical College and Hospital Kaushambi",
        "Autonomous State Medical College and Hospital Lakhimpuri kheri",
        "Autonomous State Medical College Kushinagar",
        "Autonomous State Medical College, Lalitpur",
        "Autonomous State Medical College Pilibhit",
        "Autonomous State Medical College Pratapgarh",
        "Autonomous State Medical College, Siddharthnagar",
        "Autonomous State Medical College Society, Etah, Uttar Pradesh",
        "Autonomous State Medical College Society, Fatehpur",
        "Autonomous State Medical College Society Ghazipur",
        "Autonomous State Medical College Society, Hardoi",
        "Autonomous State Medical College, Sonebhadra",
        "Autonomous State Medical College Sultanpur",
        "Autonomous State Society Medical College Mirzapur",
        "Ayaan Institute of Medical Sciences, Teaching Hospital & Research Centre, Kanaka Mamidi, R.R. Dist",
        "Azeezia Instt of Medical Science,Meeyannoor,Kollam",
        "Baba Kina Ram Autonomous State Medical College and Hospital",
        "Banas Medical College and Research Institute, Palanpur, Gujarat",
        "Bangalore Medical College and Research Institute, Bangalore",
        "Bankura Sammilani Medical College, Bankura",
        "Barasat Government Medical College & Hospital",
        "Basaveswara Medical College and Hospital, Chitradurga",
        "Belagavi Institute of Medical Sciences, Belagavi",
        "Believers Church Medical College Hospital, Thiruvalla, Kerala",
        "BGS Global Institute of Medical Sciences, Bangalore",
        "BGS Medical College and Hospital",
        "Bhaarat Medical College & Hospital",
        "Bhabha Atomic Research Centre,Mumbai",
        "Bhagwan Mahavir Institute of Medical Sciences, Pawapuri (Formely known as Vardhman Institute of Medical Sciences, Pawapuri)",
        "Bhagyoday Medical College",
        "Bharati Vidyapeeth Deemed University Medical College & Hospital, Sangli",
        "Bharati Vidyapeeth University Medical College, Pune",
        "Bharatratna Atal Bihari Vajpayee Medical College, Pune",
        "Bhaskar Medical College, Yenkapally",
        "Bhopal Memorial Hospital & Research Centre, Bhopal",
        "Bidar Institute of Medical Sciences,Bidar",
        "B. J. Govt. Medical College, Pune",
        "B J Medical College, Ahmedabad",
        "B.K.L. Walawalkar Rural Medical College, Ratnagiri",
        "Bombay Hospital Institute of Medical Sciences, Mumbai",
        "BPS Government Medical College for Women, Sonepat",
        "BRD Medical College, Gorakhpur",
        "Bundelkhand Medical College, Sagar",
        "Burdwan Medical College, Burdwan",
        "Calcutta National Medical College, Kolkata",
        "Career Instt. Of Medical Sciences & Hospital, Lucknow",
        "Central Health Education Bureau, New Delhi",
        "Central Institute of Psychiatry, Ranchi",
        "Chacha Nehru Bal Chikitsalaya",
        "Chalmeda Anand Rao Insttitute Of Medical Sciences, Karimnagar",
        "Chamrajanagar Institute of Medical Sciences, Karnataka",
        "Chandulal Chandrakar Memorial Govt. Medical College, Durg",
        "Chengalpattu Medical College, Chengalpattu",
        "Chettinad Hospital & Research Institute, Kanchipuram",
        "Chhattisgarh Institute of Medical Sciences, Bilaspur",
        "Chikkaballapura Institute of Medical Sciences",
        "Chikkamagaluru Institute of Medical Sciences, Chikkamagaluru",
        "Chintpurni Medical College, Pathankot, Gurdaspur",
        "Chirayu Medical College and Hospital, Bairagarh,Bhopal",
        "Chitradurga Medical College and Research Institute",
        "Chittaranjan National Cancer Institute",
        "Chittaranjan Seva Sadan Hospital, Kolkata",
        "Christian Medical College, Ludhiana",
        "Christian Medical College, Vellore",
        "CMR Institute of Medical Sciences",
        "Coimbatore Medical College, Coimbatore",
        "College of Medicine and JNM Hospital,Kalyani,Nadia",
        "College of Medicine and Sagore Dutta Hospital, Kolkata",
        "Command Hospital Air Force, Bangalore",
        "Command Hospital,Chandimandir",
        "Command Hospital, Kolkata",
        "Command Hospital, Lucknow",
        "Coochbehar Government Medical College & Hospital, Coochbehar, WB",
        "CU Shah Medical College, Surendra Nagar",
        "Darbhanga Medical College, Lehriasarai",
        "Datta Meghe Medical College, Nagpur",
        "Dayanand Medical College & Hospital, Ludhiana",
        "Deccan College of Medical Sciences, Hyderabad",
        "Dhanalakshmi Srinivasan Institute of Medical Sciences and Hospital,",
        "Dhanalakshmi Srinivasan Medical College and Hospital,Perambalur",
        "Dharanidhar Medical College & Hospital (DDMCH), Keonjhar,",
        "Dharwad Institute of Mental Health and Neuro Sciences, Dharwad",
        "Dhubri Medical College, Dhubri",
        "Diamond Harbour Government Medical College and Hospital, West Bengal",
        "Diphu Medical College & Hospital, Diphu, Assam",
        "Doon Medical College, Dehradun, Uttarakhand",
        "Dr. Baba Saheb Ambedkar Medical College, Rohini, Delhi",
        "Dr. B. Borooah Cancer Institute (Regional Cancer Centre), Guwahati",
        "Dr. B.C. Roy Memorial Hospital for Children, Kolkata",
        "Dr. B C Roy Post Graduate Institute of Paediatric Sciences,Kolkata",
        "Dr BR Ambedkar Medical College, Bangalore",
        "Dr B R Ambedkar State Institute of Medical Sciences, SAS Nagar , Mohali",
        "Dr. Chandramma Dayananda Sagar Instt. of Medical Education & Research, Harohalli, Hubli",
        "Dr. D Y Patil Medical College, Hospital and Research Centre, Pimpri, Pune",
        "Dr. D Y Patil Medical College, Kolhapur",
        "DRIEMS Institute of Health Sciences and Hospital, Kairapari",
        "Dr.Kiran C.Patel Medical College and Research Institute",
        "Dr. M.K. Shah Medical College & Research Centre, Ahmedabad",
        "Dr. Moopen s Medical College, Wayanad, Kerala",
        "Dr. N.D. Desai Faculty of Medical Science and Research, Nadiad",
        "Dr. N Y Tasgaonkar Institute of Medical Science",
        "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, Amravati",
        "Dr. Patnam Mahender Reddy Institute of Medical Sciences, Chevella, Rangareddy",
        "Dr. P.S.I. Medical College , Chinoutpalli",
        "Dr. Radhakrishnan Government Medical College, Hamirpur, H.P",
        "Dr. Rajendar Prasad Government Medical College, Tanda, H.P",
        "Dr. Rajendra Gode Medical College, Amravati",
        "Dr. Ram Manohar Lohia Institute of Medical Sciences,Lucknow",
        "Dr. Shankarrao Chavan Govt. Medical College, Nanded",
        "Dr SN Medical College, Jodhpur",
        "Dr. Somervel Memorial CSI Hospital & Medical College, Karakonam, Thiruvananthapuram",
        "Dr S S Tantia Medical College Hospital & Research Centre",
        "Dr. Ulhas Patil Medical College & Hospital, Jalgaon",
        "Dr Vaishampayan Memorial Medical College, Solapur",
        "Dr.Vasantrao Pawar Med. Col. Hosp. & Research Centre,Nasik (Prev. NDMVP Samaj Medical College)",
        "Dr. Vithalrao Vikhe Patil Foundations Medical College & Hospital, Ahmednagar",
        "Dr. VRK Womens Medical College, Aziznagar",
        "Dumka Medical College, Dighi Dumka",
        "East Point College of Medical Sciences & Research Centre, Bangalore",
        "East West Institute of Medical Sciences and Research",
        "Employees State Insurance Coporation Medical College, Sanath Nagar, Hyderabad",
        "Employees State Insurance Corporation Medical College,Alwar",
        "Employees State Insurance Corporation Medical College, Bangalore",
        "Employees State Insurance Corporation Medical College, Faridabad",
        "Employees State Insurance Corporation Medical College, Gulbarga",
        "Employees State Insurance Corporation Medical College, Joka, Kolkata",
        "Employees State Insurance Corporation Medical College,Patna",
        "Era Lucknow Medical College , Lucknow",
        "E.S.I.C. Medical College & Hospital, K.K. Nagar, Chennai earlier known as ESIC Medical College & PGIMSR, K.K Nagar, Chennai",
        "ESI-PGIMSR,ESI-Hospital,Andheri(E),Mumbai",
        "ESI-PGIMSR,ESI-Hospital,Basaidarapur,New Delhi",
        "ESI-PGIMSR,ESI Hospital.Manicktala,Kolkata",
        "ESI-PGIMSR,ESI-Hospital,Parel,Mumbai",
        "Faculty of Medicine and Health Sciences, Gurgaon (Formarly SGGST Medical College & R Centre,Gurgaon)",
        "Faculty of Medicine , Sri Lalithambigai Medical College and Hospital",
        "Fakhruddin Ali Ahmed Medical College, Barpeta, Assam",
        "Father Colombo Institute of Medical Sciences",
        "Father Mullers Medical College, Mangalore",
        "Fathima Instt. of Medical Sciences,Kadapa",
        "F.H. Medical College & Hospital, Etamdapur, Agra",
        "Gadag Institute of Medical Sciences, Mallasamudra, Mulgund Road, Gadag",
        "Gajra Raja Medical College, Gwalior",
        "Gandhi Medical College, Bhopal",
        "Gandhi Medical College, Secunderabad",
        "Gauhati Medical College, Guwahati",
        "Gautam Buddha Chikitsa Mahavidyalaya, Dehradum",
        "Gayathri Vidya Parishad Institute of Health Care & Medical Technology, Visakhapatnam",
        "G.B. Pant Institute of Postgraduate Medical Education and Research, New Delhi",
        "G.C.R.G. Institute of Medical Sciences, Lucknow",
        "GCS Medical College, Ahmedabad",
        "Geetanjali Medical College & Hospital,Udaipur",
        "Gian Sagar Medical College & Hospital, Patiala",
        "GITAM Institute of Medical Sciences and Research, Visakhapatnam",
        "GMERS Medical College, Dharpur Patan",
        "GMERS Medical College, Gandhinagar",
        "GMERS Medical College, Gotri, Vadodara",
        "GMERS Medical College, Hadiyol, Himmatnagar",
        "GMERS Medical College, Junagadh",
        "GMERS Medical College, Navsari",
        "GMERS Medical College, Rajpipla",
        "GMERS Medical College, Sola, Ahmedabad",
        "GMERS Medical College, Vadnagar, Mehsana",
        "GMERS Medical College, Valsad",
        "Goa Medical College, Panaji",
        "Gouri Devi Institute of Medical Sciences and Hospital, Durgapur",
        "Government Allopathic Medical College, Banda, UP",
        "Government Dharmapuri Medical College, Dharmapuri",
        "Government Erode Medical College & Hospital, Perundurai (Formerly IRT Perundurai Medical College)",
        "Government Institute of Medical Sciences, Kasna, Greater Noida",
        "Government Medical College, Akola",
        "Government Medical College, Alibag",
        "Government Medical College, Alwar",
        "Government Medical College, Ambernath",
        "Government Medical College, Amravati",
        "Government Medical College, Amritsar",
        "Government Medical College, Ananthapuram",
        "Government Medical College, Anantnag",
        "Government Medical college and District Hospital, Ratnagiri",
        "Government Medical college and Hospital , Haridwar",
        "Government Medical College and Hospital, Jajpur",
        "Government Medical College,Ariyalur",
        "Government Medical College, Aurangabad",
        "Government Medical College, Badaun, U.P.",
        "Government Medical College, Banswara",
        "Government Medical College, Baramulla",
        "Government Medical College, Baran",
        "Government Medical College, Barmer",
        "Government Medical College, Bettiah",
        "Government Medical College, Bhadradri Kothagudem",
        "Government Medical College, Bhandara",
        "Government Medical College, Bharatpur, Rajasthan",
        "Government Medical College (Bharat Ratna Shri Atal Bihari Vajpyee Memorial Med. Col.), Rajnandgaon",
        "Government Medical College, Bhavnagar",
        "Government Medical College, Bhilwara, Rajasthan",
        "Government Medical College, Buldhana",
        "Government Medical College, Bundi",
        "Government Medical College, Chandigarh",
        "Government Medical College, Chandrapur",
        "Government Medical College, Chhindwara, MP",
        "Government Medical College, Chittorgarh",
        "Government Medical College, Churachandpur",
        "Government Medical College, Churu",
        "Government Medical College, Datia, MP",
        "Government Medical College, Dausa",
        "Government Medical College, Dholpur",
        "Government Medical College,Dindigul",
        "Government Medical College, Dungarpur",
        "Government Medical College, Eluru",
        "Government Medical College, Ernakulam",
        "Government Medical College & ESIC Hospital, Coimbatore, Tamil Nadu.",
        "Government Medical College, Faizabad",
        "Government Medical College, Firozabad",
        "Government Medical College, Gadchiroli",
        "Government Medical College, Gondia",
        "Government Medical College, Handwara",
        "Government Medical College, Hanumangarh",
        "Government Medical College, Hingoli",
        "Government Medical College & Hospital, Baramati",
        "Government Medical College & Hospital (Renamed as Bhima Bhoi Medical College & Hospital), Balangir",
        "Government Medical College & Hospital (Renamed as Fakir Mohan Medical College & Hospital), Balasore",
        "Government Medical College, Idukki.",
        "Government Medical College (Institute of Integrated Medical Sciences), Yakkara, Palakkad",
        "Government Medical College, Jagtial",
        "Government Medical College, Jalgaon",
        "Government Medical College, Jalna",
        "Government Medical College, Jammu",
        "Government Medical College Jangaon",
        "Government Medical College, Jayashankar Bhupalpally",
        "Government Medical College, Jhunjhunu",
        "Government Medical College, Jogulamba",
        "Government Medical College, Kadapa",
        "Government Medical College Kallakurichi",
        "Government Medical College, Kamareddy",
        "Government Medical College, Kanker",
        "Government Medical College, Kannauj",
        "Government Medical College, Karauli",
        "Government Medical College, Karimnagar",
        "Government Medical College, Karur",
        "Government Medical College, Kathua",
        "Government Medical College, Khammam",
        "Government Medical College, Khandwa, MP",
        "Government Medical College, Konni",
        "Government Medical College, Korba",
        "Government Medical College, Kota",
        "Government Medical College, Kottayam",
        "Government Medical College, Kozhikode, Calicut",
        "Government Medical College Krishnagiri",
        "Government Medical College, Kumuram Bheem Asifabad",
        "Government Medical College, Latur",
        "Government Medical College, Machilipatnam",
        "Government Medical College, Mahabubabad",
        "Government Medical College, Mahabubnagar",
        "Government Medical College, Mahasamund",
        "Government Medical College, Maheshwaram",
        "Government Medical College, Mancherial",
        "Government Medical College, Manjeri, Malapuram Dist.",
        "Government Medical College, Medak",
        "Government Medical College, Miraj",
        "Government Medical College, Morbi",
        "Government Medical College, Mulugu",
        "Government Medical College, Mumbai",
        "Government Medical College Nagapattinam",
        "Government Medical College, Nagarkurnool",
        "Government Medical College, Nagaur",
        "Government Medical College, Nagpur",
        "Government Medical College, Nahan, Sirmour H.P.",
        "Government Medical College, Nalgonda",
        "Government Medical College, Namakkal",
        "Government Medical College, Nandurbar",
        "Government Medical College, Nandyal",
        "Government Medical College, Narayanpet",
        "Government Medical College, Narsampet",
        "Government Medical College, Nashik",
        "Government Medical College, Nirmal",
        "Government Medical College, Nizamabad",
        "Government Medical College, Omandurar",
        "Government Medical College, Ongole (Previously known as Rajiv Gandhi Institute of Medical Sciences, Ongole,)",
        "Government Medical College, Osmanabad",
        "Government Medical College, Paderu",
        "Government Medical College, Pali, Rajasthan",
        "Government Medical College, Panchmahal Godhra",
        "Government Medical College, Parbhani",
        "Government Medical College, Parippally, Kollam",
        "Government Medical College, Patiala",
        "Government Medical College, Porbandar",
        "Government Medical College (Prev.Uttarakhand Forest Hospital Trust Med.Col.), Haldwani",
        "Government Medical College, Pudukottai, Tamil Nadu",
        "Government Medical College, Purnea",
        "Government medical College, Quthbullapur",
        "Government Medical College, Rajamahendravaram",
        "Government Medical College, Rajanna Sircilla",
        "Government Medical College, Rajouri, J&K",
        "Government Medical College, Ramagundam",
        "Government Medical College Ramanathapuram",
        "Government Medical College, Rampur, Basti",
        "Government Medical College, Ratlam",
        "Government Medical College, Sangareddy",
        "Government Medical College ,Satara",
        "Government Medical College, Satna",
        "Government Medical College, Sawai Madhopur",
        "Government Medical College, Seoni",
        "Government Medical College, Shahdol, MP",
        "Government Medical College, Shahjahanpur, UP",
        "Government Medical College, Shivpuri, MP",
        "Government Medical College, Siddipet",
        "Government Medical College, Sindhudurg",
        "Government Medical College, Sirohi",
        "Government Medical College, Sri Ganganagar",
        "Government Medical College, Srinagar",
        "Government Medical College, Sundargarh",
        "Government Medical College & Super facility Hospital, Azamgarh",
        "Government Medical College, Surat",
        "Government Medical College, Suryapet",
        "Government Medical College, The Nilgiris",
        "Government Medical College, Thiruvallur",
        "Government Medical College, Thrissur",
        "Government Medical College ,Tiruppur",
        "Government Medical College, Udhampur",
        "Government Medical College, Vidisha, MP",
        "Government Medical College, Vikarabad",
        "Government Medical College, Virudhunagar",
        "Government Medical College, Vizianagaram",
        "Government Medical College, Wanaparthy",
        "Government Medical College, Washim",
        "Government Medical College, Yadadri",
        "Government Siddhartha Medical College, Vijaywada",
        "Government Sivagangai Medical College, Sivaganga",
        "Government Thiruvannamalai Medical College, Thiruvannamalai",
        "Government Vellore Medical College, Vellore",
        "Government Villupuram Medical College, Villupuram",
        "Govt. Medical College, Doda, Kashmir",
        "Govt. Medical College, Kolkata",
        "Govt. Medical College,Pariyaram, Kannur (Prev. Known as Academy of Medical Sciences)",
        "Govt. Mohan Kumaramangalam Medical College, Salem- 30",
        "Grant Medical College, Mumbai",
        "Graphic Era Institute of Medical Sciences",
        "Great Eastern Medical School and Hospital,Srikakulam",
        "G R Medical College Hospital & Research Centre",
        "GSL Medical College, Rajahmundry",
        "G.S. Medical College & Hospital, Hapur, UP",
        "GSVM Medical College, Kanpur",
        "Gujarat Adani Institute of Medical Sciences, Bhuj",
        "Gulbarga Institute of Medical Sciences, Gulbarga",
        "Guntur Medical College, Guntur",
        "Guru Govind Singh Medical College, Faridkot",
        "Guru Nanak Dev University, Amritsar",
        "Gwalior Mansik Arogyashala, Madhya Pradesh",
        "Hamdard Institute of Medical Sciences & Research, New Delhi",
        "Hassan Institute of Medical Sciences, Hassan",
        "Haveri Institute of Medical Sciences, Haveri",
        "Hazaribagh Medical College, Hazaribagh",
        "H.B.T. Medical College & Dr. R.N. Cooper Municipal General Hospital, Juhu, Mumbai",
        "Heritage Institute of Medical Sciences, Varanasi",
        "Himalaya Medical College and Hospital, Patna",
        "Himalayan Institute of Medical Sciences, Dehradun",
        "Hind Institute of Medical Sciences , Barabanki",
        "Hind Institute of Medical Sciences, Sitapur",
        "Hindu Rao Hospital, Delhi",
        "Hi-Tech Medical College & Hospital, Bhubaneswar",
        "Hi-Tech Medical College & Hospital, Rourkela",
        "Homi Bhabha Cancer Hospital and Research Centre, Visakhapatnam",
        "Homi Bhabha Cancer Hospital, Sangrur, Punjab",
        "Homi Bhabha Cancer Hospital, Varanasi, Uttar Pradesh",
        "ICARE Institute of Medical Sciences & Research, Haldia, Purba Midanpore",
        "Index Medical College Hospital & Research Centre,Indore",
        "Indian Institute of Medical Science & Research, Jalna",
        "Indira Gandhi Institute of Child Health, Bangalore",
        "Indira Gandhi Institute of Medical Sciences,Sheikhpura, Patna",
        "Indira Gandhi Medical College & Hospital, Nagpur",
        "Indira Gandhi Medical College & Research Institute, Puducherry",
        "Indira Gandhi Medical College, Shimla",
        "Indira Medical College & Hospitals, Thiruvallur",
        "Institute of Aerospace Medicine Indian Air Force, Bangaluru",
        "Institute of Child Health, Kolkata",
        "Institute of Human Behaviour and Allied Sciences, Delhi",
        "Institute of Liver and Biliary Sciences, New Delhi",
        "Institute of Medical Science and Research, Vidyagiri, Satara",
        "Institute of Medical Sciences, BHU, Varansi",
        "Institute of Medical Sciences & SUM Hospital",
        "Institute of Mental Health and Hospital, Agra",
        "Institute of Navel Medicine, INHS, Aswini",
        "Institute of Nephro-Urology, Bangalore",
        "Institute of Nuclear Medicine & Allied Sciences, New Delhi",
        "Institute of Opthalmology Joseph Eye Hospital, Tiruchirapalli",
        "Institute of Postgraduate Medical Education & Research, Kolkata",
        "Instt. Of Medical Sciences & SUM Hospital, Bhubaneswar",
        "Integral Institute of Medical Sciences & Research, Lucknow",
        "IQ-City Medical College, Burdwan",
        "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College JGMMMC",
        "Jagannath Gupta Institute of Medical Sciences & Hospital, Kolkata",
        "Jaipur National University Institute of Medical Sciences and Resarch Centre, Jagatpura, Jaipur",
        "Jalpaiguri Government Medical College",
        "Jannayak Karpoori Thakur Medical College & Hospital, Madhepura, Bihar",
        "Jawaharlal Institute of Postgraduate Medical Education & Research, Puducherry",
        "Jawaharlal Nehru Institute of Medical Sciences,Porompet,Imphal",
        "Jawaharlal Nehru Medical College, Ajmer",
        "Jawaharlal Nehru Medical College, Aligarh",
        "Jawaharlal Nehru Medical College, Belgaum",
        "Jawaharlal Nehru Medical College, Bhagalpur",
        "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha",
        "Jhalawar Medical College, Jhalawar",
        "Jhargram Government Medical College & Hospital",
        "JIET Medical College And Hospital",
        "JIS School of Medical Science & Research, Howrah",
        "JJM Medical College, Davangere",
        "JMN Medical College, Nadia",
        "Jorhat Medical College & Hospital , Jorhat",
        "J R Medical College and Hospital",
        "JSS Medical College, Mysore",
        "Jubilee Mission Medical College & Research Institute, Thrissur",
        "Kakatiya Medical College, Warangal",
        "Kalinga Institute of Medical Sciences, Bhubaneswar",
        "Kalpana Chawala Govt. Medical College, Karnal, Haryana",
        "Kalyan Singh Government Medical College, Hospital and Kasturba Mahila Chikitsalaya",
        "Kamineni Academy of Medical Sciences & Research Center, Hyderabad",
        "Kamineni Institute of Medical Sciences, Narketpally",
        "Kanachur Institute of Medical Sciences, Mangalore",
        "Kannur Medical College, Kannur",
        "KanyaKumari Government Medical College, Asaripallam",
        "Kanyakumari Medical Mission Research Centre",
        "K A P Viswanathan Government Medical College, Trichy",
        "Karnataka Institute of Medical Sciences, Hubballi",
        "Karpagam Faculty of Medical Sciences & Research, Coimbatore",
        "Karpaga Vinayaga Institute of Medical Sciences,Maduranthagam",
        "Karuna Medical College, Palakkad",
        "Karwar Institute of Medical Sciences, Karwar",
        "Kasturba Hospital, New Delhi",
        "Kasturba Medical College, Mangalore",
        "Kasturba Medical College, Manipal",
        "Katihar Medical College, Katihar",
        "Katuri Medical College, Guntur",
        "K.D. Medical College Hospital & Research Centre, Mathura",
        "Kempegowda Institute of Medical Sciences, Bangalore",
        "Kerala Medical College, Mangode, Palakkad",
        "Khaja Bandanawaz University - Faculty of Medical Sciences, Gulbarga",
        "Kidwai Memorial Institute of Oncology, Bangalore",
        "Kilpauk Medical College, Chennai",
        "King George Medical University, Lucknow",
        "Kiran Medical College, Surat",
        "KJ Somaiyya Medical College & Research Centre, Mumbai",
        "KMCH Institute of Health Sciences and Research, Coimbatore",
        "KMC Medical College & Hospital",
        "KMCT Medical College,Kozhikode, Calicut",
        "Kodagu Institute of Medical Sciences, Kodagu",
        "Kokrajhar Medical College",
        "Konaseema Institute of Medical Sciences & Research Foundation, Amalapuram",
        "Koppal Institute of Medical Sciences, Koppal",
        "KPC Medical College,Jadavpur,Kolkata",
        "Krishna Institute of Medical Sciences, Karad",
        "Krishna Mohan Medical College & Hospital, Mathura",
        "Krishnanagar Institute of Medical Sciences",
        "K S Hegde Medical Academy, Mangalore",
        "Kurnool Medical College, Kurnool",
        "K V G Medical College, Sullia",
        "Lady Hardinge Medical College, New Delhi",
        "Lakhimpur Medical College",
        "Late Shri Baliram Kashyap Memorial NDMC Govt. Medical College, Jagdalpur",
        "Late Shri Lakhi Ram Agrawal Memorial Govt. Medical College, Raigarh",
        "Laxmi Chandravansi Medical College & Hospital",
        "LLRM Medical College, Meerut",
        "LNCT Medical College & Sewakunj Hospital, Indore",
        "L.N. Medical College and Research Centre,Bhopal",
        "Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health, Tezpur",
        "Lord Buddha Koshi Medical College and Hospital, Saharsa",
        "Madha Medical College and Hospital, Thandalam, Chennai",
        "Madhubani Medical College, Madhubani",
        "Madras Medical College, Chennai",
        "Madurai Medical College, Madurai",
        "Mahadevappa Rampure Medical College, Kalaburagi, Gulbarga",
        "Mahamaya Rajkiya Allopathic Medical College, Ambedkarnagar",
        "Maharaja Agrasen Medical College, Agroha",
        "Maharajah Institute of Medical Sciences, Vizianagaram",
        "Maharani Laxmi Bai Medical College, Jhansi",
        "Maharashtra Institute of Medical Education & Research, Talegaon,Pune",
        "Maharashtra Institute of Medical Sciences & Research, Latur",
        "Maharashtra Post Graudate Institute of Medical Education & Research",
        "Maharishi Markandeshwar College of Medical Sciences & Research, Sadopur",
        "Maharishi Markandeshwar Institute Of Medical Sciences & Research, Mullana, Ambala",
        "Maharishi Markandeshwar Medical College & Hospital, Solan",
        "Maharshi Devraha Baba Autonomous State Medical College, Deoria",
        "Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha",
        "Mahatma Gandhi Medical College and Hospital, Sitapur, Jaipur",
        "Mahatma Gandhi Medical College & Research Institute, Pondicherry",
        "Mahatma Gandhi Mission Medical College, Vashi",
        "Mahatma Gandhi Missions Medical College, Aurangabad",
        "Mahatma Gandhi Missions Medical College, Navi Mumbai",
        "Mahatma Gandhi Missions Medical College, Nerul, Navi Mumbai",
        "Mahatma Vidur Autonomous State Medical College",
        "Mahaveer Institute of Medical Sciences & Research, Bhopal",
        "Mahavir Institute of Medical Sciences, Vikarabad, Telengana",
        "Maheshwara Medical College, Chitkul, Patancheru, Medak",
        "Major S D Singh Medical College and Hospital, Fathehgarh, Farrukhabad",
        "Malabar Cancer Centre Post Graduate Institute of Oncology Sciences and Research, Modakkallur",
        "Malabar Medical College, Kozhikode,Calicut",
        "Malankara Orthodox Syrian Church Medical College, Kolenchery",
        "Malda Medical College & Hospital, Malda",
        "Malla Reddy Institute of Medical Sciences, Hyderabad",
        "Mallareddy Medical College for Women, Hyderabad",
        "Mamata Academy of Medical Sciences, Bachupally",
        "Mamata Medical College, Khammam",
        "Mandya Institute of Medical Sciences, Mandya",
        "Manipal Tata Medical College,Baridih Jameshedpur",
        "Mata Gujri Memorial Medical College, Kishanganj",
        "Maulana Azad Medical College, New Delhi",
        "Mayo Institute of Medical Sciences, Barabanki",
        "Medical College, Baroda",
        "Medical College, Thiruvananthapuram",
        "Mediciti Institute Of Medical Sciences, Ghanpur",
        "Meenakshi Medical College and Research Institute, Enathur",
        "Melmaruvathur Adiparasakthi Instt. Medical Sciences and Research",
        "M E S Medical College , Perintalmanna Malappuram Distt.Kerala",
        "M G M Medical College, Indore",
        "M G M Medical College, Jamshedpur",
        "Midnapore Medical College, Midnapore",
        "MKCG Medical College, Berhampur",
        "MNR Medical College & Hospital, Sangareddy",
        "Moti Lal Nehru Medical College, Allahabad",
        "Mount Zion Medical College, Chayalode, Ezhamkulam Adoor, Pathanamthitta",
        "MP Shah Medical College,Jamnagar",
        "M S Ramaiah Medical College, Bangalore",
        "Murshidabad Medical College & Hospitals, Murshidabad",
        "Muzaffarnagar Medical College, Muzaffarnagar",
        "MVJ Medical College and Research Hospital, Bangalore",
        "Mysore Medical College and Research Instt. (Prev.name Government Medical College), Mysore",
        "Nagaland Institute of Medical Sciences & Research",
        "Nagaon Medical College",
        "Nalanda Medical College, Patna",
        "Nalbari Medical College, Nalbari",
        "NAMO Medical Education and Research Institute, Silvassa",
        "Nandha Medical College & Hospital Erode",
        "Naraina Medical College & Research Centre",
        "Narayana Medical College, Nellore",
        "Narayan Medical College & Hospital, Sasaram",
        "Narendra Modi Medical College, Ahmedabad (Formely known as Ahmedabad Muncipal Coporation Medical Edu. Trust Medical College)",
        "National Capital Region Institute of Medical Sciences, Meerut",
        "National Institute of Health and Family Welfare, New Delhi",
        "National Institute of Medical Science & Research, Jaipur",
        "National Institute of Mental Health & Neuro Sciences, Bangalore",
        "National Institute of Virology, Pune",
        "Navodaya Medical College, Raichur",
        "N.C. Medical College & Hospital, Panipat",
        "Neelima Institute of Medical Sciences, Medchal",
        "Netaji Subhash Chandra Bose Medical College, Jabalpur",
        "Netaji Subhas Medical College & Hospital, Amhara, Bihta, Patna",
        "Netaji Subhas National Instt. Of Sports Medicine, Patiala",
        "Nilratan Sircar Medical College, Kolkata",
        "Nimra Institute of Medical Sciences, Krishna Dist., A.P.",
        "Nizams Institute of Medical Sciences, Hyderabad",
        "N. K. P. Salve Instt. of Medical Sciences and Research Centre and Lata Mangeshkar Hospital, Nagpur",
        "Noida International Institute Of Medical Sciences",
        "Nootan Medical College and Research Centre, Mehsana",
        "North Bengal Medical College, Darjeeling",
        "North Delhi Muncipal Corporation Medical College, Delhi",
        "North Eastern Indira Gandhi Regional Instt. of Health and Medical Sciences, Shillong",
        "Not Available",
        "Nova Institute of Medical Sciences and Research Centre",
        "NRI Institute of Medical Sciences, Visakhapatnam",
        "NRI Medical College, Guntur",
        "Osmania Medical College, Hyderabad",
        "Pacific Institute of Medical Sciences, Umarda, Udaipur",
        "Pacific Medical College & Hospital, Bhilo Ka Bedla, Udaipur",
        "Padmashree Dr. D.Y.Patil Medical College, Navi Mumbai",
        "Palakkad Institute of Medical Sciences, Palakkad Kerala",
        "Palamu Medical College, Palamu",
        "Pandit Deendayal Upadhyay Medical College, Rajkot",
        "Panimalar Medical College Hospital & Research Institute, Chennai,Tamil Nadu",
        "Parbhani Medical College, Parbhani",
        "Parul Institute of Medical Sciences & Research, Vadodara",
        "PA Sangma International Medical College & Hospital",
        "Patna Medical College, Patna",
        "Peoples College of Medical Sciences & Research Centre, Bhanpur, Bhopal",
        "P E S Institute Of Medical Sciences and Research, Kuppam",
        "PES University Institute of Medical Sciences and Reseach Bangalore",
        "Pimpri Chinchwad Municipal Corporation PG Institute, Pimpri",
        "P K Das Institute of Medical Sciences, Palakkad, Kerala",
        "Pondicherry Institute of Medical Sciences & Research, Pondicherry",
        "Post Graduate Institute of Child Health, Noida (Formely known as Super Speciality Paediatric Hospital & Post Graduate Teaching Institute)",
        "Post Graduate Institute of Medical Education and Research & Capital Hospital, Bhubaneswar",
        "Postgraduate Institute of Medical Education & Research, Chandigarh",
        "Post Graduate Institute of Medical Sciences, Navi Mumbai",
        "Postgraduate Institute of Swasthiyog Pratisthan, Miraj",
        "Prafulla Chandra Sen Government Medical College & Hospital",
        "Prakash Institute of Medical Sciences & Research, Sangli",
        "Pramukhswami Medical College, Karmsad",
        "Prasad Institute of Medical Sciences, Lucknow",
        "Prathima Institute Of Medical Sciences, Karimnagar",
        "Prathima Relief Institue of Medical Sciences",
        "PSG Institute of Medical Sciences, Coimbatore",
        "PSP Medical College Hospital and Research Institute",
        "Pt. B D Sharma Postgraduate Institute of Medical Sciences, Rohtak (Haryana)",
        "Pt. Jawahar Lal Nehru Government Medical College, Chamba",
        "Pt. J N M Medical College, Raipur",
        "Pt. Raghunath Murmu Medical College and Hospital, Baripada, Odisha",
        "Punjab Institute of Medical Sciences, Jalandhar",
        "Purulia Government Medical College & Hospital",
        "Pushpagiri Institute Of Medical Sciences and Research Centre, Tiruvalla",
        "Radha Devi Jageshwari Memorial Medical College and Hospital",
        "Raichur Institute of Medical Sciences,Raichur",
        "Raiganj Government Medical College & Hospital, Raiganj",
        "Raipur Institute of Medical Sciences (RIMS), Raipur",
        "Rajah Muthiah Medical College, Annamalainagar",
        "Rajan Babu T B Institute, Delhi",
        "Rajarajeswari Medical College & Hospital, Bangalore",
        "Rajashree Chatrapati Shahu Maharaj Government Medical College, Kolhapur",
        "Rajendra Institute of Medical Sciences, Ranchi",
        "Rajiv Gandhi Institute of Medical Sciences, adilabad",
        "Rajiv Gandhi Institute of Medical Sciences, Srikakulam",
        "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, Thane",
        "Rajkiya Allopathic Medical College, Bahraich, UP",
        "Rajkiya Medical College Jalaun, Orai, Uttar Pradesh",
        "Rajmata Shrimati Devendra kumari Singhdeo Government Medical College, Surguja ( C.G.)",
        "Rajshree Medical Research Institute, Bareilly",
        "Rama Medical College and Hospital , Kanpur",
        "Rama Medical College Hospital and Research Centre, Hapur",
        "Ram Krishna College Hospital & Research Centre, Bhopal",
        "Rampurhat Government Medical College & Hospital, Rampurhat",
        "Ranchi Instt. Of Neuro-Psychiatry and Allied Sciences,Kanke,Ranchi",
        "Rangaraya Medical College, Kakinada",
        "Regional Cancer Centre, Thiruvanthapuram",
        "Regional centre for Cancer Research and treatment,Adyar,Chennai",
        "Regional Institute of Medical Sciences, Imphal",
        "Regional Instt. of Opthalmology Previously Known asNehru Instt.of Opthalmo, Sitapur",
        "RG Kar Medical College, Kolkata",
        "RIMT Medical College and Hospital",
        "RKDF Medical College Hospital & Research Centre, Jatkhedi, Bhopal",
        "R N T Medical College, Udaipur",
        "Rohilkhand Medical College & Hospital, Bareilly",
        "RUHS College of Medical Sciences, Jaipur",
        "Rural Medical College, Loni",
        "Ruxmaniben Deepchand Gardi Medical College, Ujjain",
        "R.V.M. Institute of Medical Sciences and Research Centre, Siddipet",
        "Safdarjung Hospital, New Delhi",
        "Saheed Laxman Nayak Medical College & Hospital, Koraput",
        "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi",
        "SAL Institute of Medical Sciences, Ahmedabad",
        "Sambharam Institute of Medical Sciences & Research, Kolar",
        "Sancheti Institute for Orthpaedics & Rehabilitation, Pune",
        "Sanjay Gandhi Institute of Trauma and Orthopaedics, Bangalore",
        "Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow",
        "Sanjeevan Medical Foundation ENT Post Graduate Training Instt., Miraj",
        "Sankara Netralaya Medical Research Foundation, Chennai",
        "Santhiram Medical College, Nandyal",
        "Santiniketan Medical College, Bolpur, West Bengal",
        "Santosh Medical College, Ghaziabad",
        "Sapthagiri Institute of Medical Sciences & Research Centre, Bangalore",
        "Saraswati Institute of Medical Sciences, Hapur",
        "Saraswati Medical College, Unnao, U.P.",
        "Sarat Chandra Chattopadhyay Government Medical College & Hospital",
        "Sardar Patel Medical College, Bikaner",
        "Saveetha Medical College and Hospital, Kanchipuram",
        "SBKS Medical Instt. & Research Centre, Vadodra",
        "SCB Medical College, Cuttack",
        "School of Medical Sciences & Research,Greater Noida",
        "School of Medical Sciences, Sri Satya Sai University of Technology and Medical Sciences",
        "School of Tropical Medicine, Kolkata",
        "SDM College of Medical Sciences & Hospital, Sattur, Dharwad",
        "SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases, Bangalore",
        "Seth GS Medical College, and KEM Hospital, Mumbai",
        "Shadan Institute of Medical Sciences,Research Centre and Teaching Hospital, Peerancheru",
        "Shahed Nirmal Mahto Medical College & Hospital, Dhanbad",
        "Shaheed Hasan Khan Mewati Government Medical College, Nalhar",
        "Shaikh-UL-Hind Maulana Mahmood Hasan Medical College, Saharanpur",
        "Shantabaa Medical College, Amreli",
        "Sher-I-Kashmir Instt. Of Medical Sciences, Srinagar",
        "Shija Academy of Health Sciences",
        "Shimoga Institute of Medical Sciences,Shimoga",
        "Shree Narayan Medical Institute and Hospital",
        "Shri Atal Bihari Vajpayee Government Medical College, Faridabad",
        "Shri Atal Bihari Vajpayee Medical College & Research Institute",
        "Shri Balaji Institute of Medical Science",
        "Shri B M Patil Medical College, Hospital & Research Centre, Vijayapura(Bijapur",
        "Shridevi Institute of Medical Sciences & Research Hospital, Tumkur",
        "Shri Gorakshnath Medical College Hospital & Research Centre",
        "Shri Guru Ram Rai Institute of Medical & Health Sciences, Dehradun",
        "Shri Kalyan Govt. Medical College, Sikar, Rajasthan",
        "Shri Krishna Medical College, Muzzafarpur",
        "Shri Lal Bahadur Shastri Government Medical College, Mandi, HP",
        "Shri Ramchandra Institute of Medical Sciences, Aurangabad",
        "Shri Ramkrishna Institute of Medical Sciences & Sanaka Hospitals, Durgapur",
        "Shri Ram Murti Smarak Institute of Medical Sciences, Bareilly",
        "Shri Rawatpura Sarkar Institute of Medical Sciences and Research",
        "Shri Sathya Sai Medical College and Research Institute, Kancheepuram",
        "Shri Shankaracharya Institute of Medical Sciences, Bhilai",
        "Shri Siddhi Vinayak Medical College & Hospital",
        "Shri Vasant Rao Naik Govt. Medical College, Yavatmal",
        "Shyam Shah Medical College, Rewa",
        "Siddaganga Medical College and Research Institute, Tumakuru",
        "Sikkim Manipal Institute of Medical Sciences, Gangtok",
        "Silchar Medical College, Silchar",
        "Sindhudurg Shikshan Prasarak Mandal (SSPM) Medical College & Lifetime Hospital, Padave, Sindhudurg",
        "SKS Hospital Medical College & Research Centre",
        "SMBT Institute of Medical Sciences & Research Centre, Nandihills, Nashik",
        "SMS Medical College, Jaipur",
        "Smt. G.R. Doshi and Smt. K.M. Mehta Institute of Kidney Diseases and Research Centre, Dr. H.L. Trivedi Institute of Transplantation Sciences, Ahmedabad, Gujarat",
        "Smt. Kashibai Navale Medical College and General Hospital,Pune",
        "Smt. N.H.L.Municipal Medical College, Ahmedabad",
        "S. Nijalingappa Medical College & HSK Hospital & Research Centre, Bagalkot",
        "S N Medical College, Agra",
        "Soban Singh Jeena Government Institute of Medical Science & Research, Almora",
        "Sree Balaji Medical College and Hospital, Chennai",
        "Sree Chitra Thirunal Institute for Medical Science and Technology, Thiruvananthapura",
        "Sree Gokulam Medical College Trust & Research Foundation, Trivandrum",
        "Sree Mookambika Institute of Medical Sciences, Kanyakumari",
        "Sree Narayana Instt. of Medical Sciences, Chalakka,Ernakulam",
        "Sree Uthradom Thiurnal Academy of Medical Sciences,Trivandrum",
        "Sri Aurobindo Medical College and Post Graduate Institute , Indore",
        "Sri Balaji Medical College, Hospital and Research Institute, Chittoor",
        "Sri Bhausaheb Hire Government Medical College, Dhule",
        "Sri Chamundeshwari Medical College Hospital & Research Institute",
        "Sri Devaraj URS Medical College, Kolar",
        "Sri Guru Ram Das Institute of Medical Sciences and Research, Sri Amritsar",
        "Sri Jagannath Medical College & Hospital, Puri",
        "Sri Jayadeva Institute of Cardiology, Bangalore",
        "Sri Lakshmi Narayana Institute of Medical Sciences, Pondicherry",
        "Sri Madhusudan Sai Institute of Medical Sciences and Research",
        "Sri Manakula Vinayagar Medical College & Hospital, Pondicherry",
        "Sri Muthukumaran Medical College,Chennai",
        "Srinivasan Medical College and Hospital",
        "Srinivas Institute of Medical Research Centre, Srinivasnagar, mangalore",
        "Sri Ramachandra Medical College & Research Institute, Chennai",
        "Sri siddhartha Institute of Medical Sciences & Research Centre, Bangalore",
        "Sri Siddhartha Medical College, Tumkur",
        "Sri Venkateswaraa Medical College Hospital and Research Institute, Chennai",
        "Sri Venkateswaraa Medical College, Hospital & Research Centre, Pondicherry",
        "Sri Venkateswara Institute of Medical Sciences (SVIMS) , Tirupati",
        "S.R. Medical College & Research Centre, Akathumjuri, Vennicode, Varkala, Thiruvananthapuram",
        "SRM Medical College Hospital & Research Centre, Chengalpattu",
        "SR Patil Medical College and Hospital",
        "SRTR Medical College, Ambajogai",
        "S S Institute of Medical Sciences& Research Centre, Davangere",
        "Stanley Medical College, Chennai",
        "St. Johns Medical College, Bangalore",
        "ST Peters Medical College, Hospital & Research Institute",
        "Subbaiah Institute of Medical Sciences, Shimoga, Karnataka",
        "Subharti Medical College, Meerut",
        "Sudha Medical College",
        "Sukh Sagar Medical College & Hospital, Jabalpur",
        "Sundarlal Patwa Govt. Medical College",
        "Surabhi Institute of Medical Sciences, Siddipet, Telangana",
        "Surat Municipal Institute of Medical Education & Research, Surat",
        "SVIMS - Sri Padmavathi Medical College for Women, Alipiri Road, Tirupati",
        "S V Medical College, Tirupati",
        "S V S Medical College, Mehboobnagar",
        "Swaminayaran Institute of Medical Sciences & Research",
        "Swamy Vivekanandha Medical College Hospital And Research Institute",
        "Symbiosis Medical College for Women, Pune",
        "Tagore Medical College and Hospital, Chennai",
        "Tamralipto Government Medical College & Hospital",
        "Tata Memorial centre, Mumbai",
        "T D Medical College, Alleppey (Allappuzha)",
        "Teerthanker Mahaveer Medical College, Moradabad",
        "Terna Medical College, Navi Mumbai",
        "Tezpur Medical College & Hospital, Tezpur",
        "Thanjavur Medical College,Thanjavur",
        "The Gandhigram Institute of Rural Health and Family Welfare Trust, Ambathurai",
        "Theni Government Medical College,Theni",
        "The Oxford Medical College, Hospital & Research Centre, Bangalore",
        "The Tamilnadu Dr. MGR Medical University,Chennai",
        "Thiruvarur Govt. Medical College, Thiruvarur",
        "Thoothukudi Medical College, Thoothukudi",
        "Tinsukia Medical College",
        "Tirunelveli Medical College,Tirunelveli",
        "Tomo Riba Institute of Health & Medical Sciences, Naharlagun",
        "Topiwala National Medical College, Mumbai",
        "Travancore Medical College, Kollam",
        "Trichy SRM Medical College Hospital & Research Centre, Trichy",
        "Tripura Medical College and Dr. B R A M Teaching Hospital, Agartala",
        "Tripura Santiniketan Medical College & Hospital",
        "TRR Institute of Medical Sciences, Patancheru",
        "T S Misra Medical College & Hospital, Amusi, Lucknow",
        "Uma Nath Singh Autonomous State Medical College Society Jaunpur",
        "United Institute of Medical Sciences, Allahabad",
        "University College of Medical Sciences & GTB Hospital, New Delhi",
        "University College of Medicine, Kolkata",
        "Uttar Pradesh University of Medical Sciences, (Prev. UP Rural Inst.of Med.Sc&R) Etawah",
        "Vardhman Mahavir Medical College & Safdarjung Hospital, Delhi",
        "Varun Arjun Medical College, Banthra, Shahjahanpur",
        "Vedantaa Institute of Medical Sciences, Palghar, Maharashtra",
        "Veer Chandra Singh Garhwali Govt. Medical Sc. & Research Instt, Srinagar, Pauri Garhwal",
        "Veer Surendra Sai Institute of Medical Sciences and Research, Burla",
        "Velammal Medical College Hospital and Research Institute, Madurai",
        "VELS Medical College & Hospital",
        "Venkateshwara Institute of Medical Sciences, Gajraula",
        "Vijaynagar Institute of Medical Sciences, Bellary",
        "Vinayaka Missions Kirupananda Variyar Medical College, Salem",
        "Vinayaka Missions Medical College, Karaikal, Pondicherry",
        "Virendra Kumar Sakhlecha Government Medical College",
        "Viswabharathi Medical College, Kurnool",
        "Vivekananda Institute of Medical Sciences, Kolkata",
        "VP Chest Institute of Medical Sciences, Delhi",
        "Vyas Medical College & Hospital",
        "Vydehi Institute Of Medical Sciences & Research Centre, Bangalore",
        "World College of Medical Sciences & Researc, Jhajjar, Haryana",
        "Yadgiri Institute of Medical Sciences, Yadgiri",
        "Yenepoya Medical College, Mangalore",
        "Zoram Medical College, Mizoram",
        "Zydus Medical College & Hospital, Dahod",
        "Anil Neerukonda Institute of Dental Sciences, Visakhapatnam",
        "C.K.S. Teja Institute of Dental Sciences & Research, Tirupati",
        "Care Dental College, Guntur",
        "Drs. Sudha & Nageswara Rao Siddhartha Institute of Dental Sciences, Gannavaram Mandalam",
        "G. Pulla Reddy Dental College & Hospital, Kurnool",
        "Gitam Dental College & Hospital, Vishakhapatnam",
        "Govt. Dental College & Hospital, Vijayawada",
        "Govt. Dental College, RIMS, Kadapa",
        "GSL Dental College & Hospital, Rajahmundry",
        "KIMS Dental College, Amalapuram",
        "Lenora Institute of Dental Sciences, Rajahmundry",
        "Narayana Dental College & Hospital, Nellore",
        "Nimra Institute of Dental Sciences",
        "Sibar Institute of Dental Sciences, Guntur",
        "Sree Sai Dental College & Research Institute, Srikakulam",
        "St. Joseph Dental College, Duggirala",
        "Vishnu Dental College, Bhimavaram",
        "Govt. Dental College, Dibrugarh, Assam",
        "Govt. Dental College, Silchar, Assam",
        "Regional Dental College, Guwahati",
        "Buddha Institute of Dental Sciences & Hospital, Kankarbagh, Patna",
        "Dr. B.R. Ambedkar Institute of Dental Sciences & Hospital, Patna",
        "Government Dental College & Hospital, Nalanda",
        "Mithila Minority Dental College & Hospital, Darbhanga",
        "Patna Dental College & Hospital, Patna",
        "Sarjug Dental College, Darbhanga",
        "Dr. Harvansh Singh Judge Institute of Dental Sciences & Hospital, Chandigarh",
        "Chattisgarh Dental College & Research Institute, Rajnandgaon",
        "Govt. Dental College, Raipur, Chhattisgarh",
        "Maitri College of Dentistry and Research Centre, Durg",
        "New Horizon Dental College & Research Instiute, Bilaspur",
        "Rungta College of Dental Sciences & Research, Bhilai",
        "Triveni Institute of Dental Sciences, Hosptial & Research Centre, Bilaspur",
        "Vaidik Dental College and Research Centre,",
        "Army Hospital (Research and Referral) Delhi Cantt, Delhi",
        "ESIC Dental College, Rohini, New Delhi",
        "Faculty of Dentistry, Jamia Millia Islamia, New Delhi",
        "Lady Hardinge Medical College & Smt. Sucheta Kriplani Hospital New Delhi",
        "Maulana Azad Dental College & Hospital, New Delhi",
        "University College of Medical Sciences, Dilshad Garden",
        "Goa Dental College & Hospital, Goa",
        "Ahmedabad Dental College & Hospital, Gandhinagar",
        "AMC Dental College, Ahmedabad",
        "College of Dental Sciences and Research Centre, Ahmedabad",
        "College of Dental Sciences, Amargadh",
        "Faculty of Dental Science, Dharmsinh Desai University, Nadiad",
        "Goenka Research Institute of Dental Sciences, Gandhinagar",
        "Govt. Dental College & Hospital, Ahmedabad",
        "Govt. Dental College Hospital, Jamnagar",
        "K.M. Shah Dental College & Hospital, Vadodara",
        "Karnavati School of Dentistry, Gandhinagar",
        "Manubhai Patel Dental College & Dental Hospital & SSR General Hospital, Vadodara",
        "Narsinhbhai Patel Dental College & Hospital, Visnagar",
        "Siddhpur Dental College, Patan",
        "D.A.V. Centenary Dental College, Yamuna nagar",
        "Jan Nayak Ch. Devi Lal Dental College, Sirsa",
        "Maharishi Markandeshwar College of Dental Sciences & Research, Mullana",
        "Manav Rachana Dental College, Faridabad",
        "PDM Dental College & Research Institute, Jhajjar",
        "Post Graduate Institute of Dental Sciences, Rohtak",
        "Sri Govind Tricentenary Dental College, Hospital & Research Institute",
        "Sudha Rustagi College of Dental Sciences & Research,Faridabad",
        "Swami Devi Dyal Hospital & Dental College, Panchkula",
        "Yamuna Institute of Dental Sciences & Research, Yamuna Nagar",
        "Bhojia Dental College & Hospital, Nalagarh",
        "H.P. Govt. Dental College & Hospital, Shimla",
        "Himachal Dental College, Sunder Nagar",
        "Himachal Institute of Dental Sciences, Poanta Sahib",
        "M.N.D.A.V. Dental College & Hospital, Solan",
        "Govt. Dental College, Srinagar",
        "Indira Gandhi Government Dental College, Jammu",
        "Institute of Dental Sciences, Sehora",
        "Awadh Dental College & Hospital, Jamshedpur",
        "Dental Institute Rajendra Institute of Medical Sciences, Ranchi",
        "Hazaribag College of Dental Sciences and Hospital, Hazaribag",
        "Vananchal Dental College & Hospital, Garhwa",
        "A.B. Shetty Memorial Institute of Dental Sciences, Mangalore",
        "A.J. Institute of Dental Sciences, Mangalore",
        "Air Force Institute of Dental Sciences, Bangalore",
        "Al Ameen Dental College & Hospital, Bijapur",
        "Al-Badar Rural Dental College & Hospital, Gulbarga",
        "AME’s Dental College & Hospital, Raichur",
        "Bangalore Institute of Dental Sciences & Hospital, Bangalore",
        "Bapuji Dental College & Hospital, Davangere",
        "College of Dental Sciences, Davangere",
        "Coorg Institute of Dental Sciences, Virajpat",
        "D.A. Pandu Memorial R.V. Dental College, Bangalore",
        "Dayanand Sagar College of Dental Sciences, Bangalore",
        "Dr. Syamala Reddy Dental College, Hospital & Research Centre, Bangalore",
        "ESIC Dental College, Gulbarga",
        "Farooqia Dental College & Hospital, Mysore",
        "Govt. Dental College & Research Institute, Bellary",
        "Govt. Dental College, Bangalore",
        "H.K.D.E.Ts Dental College, Hospital & Research Institute, Humnabad",
        "H.K.E. Society's, S. Nijalingappa Institute of Dental Sciences & Research",
        "JSS Dental College & Hospital, Mysore",
        "K.G.F. College of Dental Sciences & Hospital, KGF",
        "K.V.G. Dental College & Hospital, Sullia",
        "KLE Society’s Institute of Dental Sciences,Bangalore",
        "KLE Vishwanath Katti Institute of Dental Sciences, Belgaum",
        "Krishnadevaraya College of Dental Sciences & Hospital, Bangalore",
        "M.R. Ambedkar Dental College & Hospital, Bangalore",
        "M.S. Ramaiah Dental College, Bangalore",
        "Maaruti College of Dental Sciences & Research Centre, Bangalore",
        "Manipal College of Dental Sciences, Mangalore",
        "Manipal College of Dental Sciences, Manipal",
        "Maratha Mandal’s Dental College & Research Centre, Belgaum",
        "Navodaya Dental College, Raichur",
        "P.M.N.M. Dental College & Hospital, Bagalkot",
        "Rajarajeswari Dental College & Hospital, Bangalore",
        "S.B. Patil Dental College & Hospital, Bidar",
        "S.J.M. Dental College & Hospital, Chitradurga",
        "SDM College of Dental Sciences & Hospital, Dharwad",
        "Sharavathi Dental College & Hospital, Shimoga",
        "Sri Hasanamba Dental College & Hospital, Hassan",
        "Sri Rajiv Gandhi College of Dental Sciences & Hospital, Bangalore",
        "Sri Siddhartha Dental College, Tumkur",
        "Sri Venkateshwara Dental College & Hospital, Bangalore",
        "Srinivas Institute of Dental Sciences, Mangalore",
        "Subbaiah Institute of Dental Sciences, Purale, Shimoga",
        "The Oxford Dental College, Bangalore",
        "V.S. Dental College, Bangalore",
        "Vydehi Institute of Dental Sciences & Research, Bangalore",
        "Yenepoya Dental College & Hospital, Mangalore",
        "Al-Azhar Dental College, Kerala",
        "Amrita School of Dentistry, Kochi",
        "Annoor Dental College & Hospital, Ernakulam",
        "Azeezia College of Dental Sciences & Research, Kollam",
        "Century International Institute of Dental Science & Research Center, Kasaragod",
        "Educare Institute of Dental Sciences, Malappuram",
        "Govt. Dental College, Alappuzha",
        "Govt. Dental College, Kottayam",
        "Govt. Dental College, Kozhikode",
        "Govt. Dental College, Thrissur",
        "Govt. Dental College, Trivandrum",
        "Indira Gandhi Institute of Dental Sciences, Kothamangalam",
        "Kannur Dental College, kannur",
        "KMCT Dental College, Calicut",
        "Malabar Dental College & Research Centre, Malappuram",
        "Mar Baselios Dental College, Kothamangalam",
        "MES Dental College, Malappuram",
        "Noorul Islam College of Dental Sciences, Trivandrum",
        "Pariyaram Dental College, Academy of Medical Sciences, Kannur",
        "PMS College of Dental Science & Research, Thiruvananthpuram",
        "PSM College of Dental Sciences & Research, Trichur",
        "Pushpagiri College of Dental Sciences, Tiruvalla",
        "Royal Dental College, kerala",
        "Sree Anjaneya Medical Trust, Calicut",
        "Sri Sankara Dental College, Thiruvananthapuram",
        "St. Gregorios Dental College, Ernakulam",
        "Travancore Dental College",
        "Bhabha College of Dental Sciences, Bhopal",
        "College of Dental Sciences & Hospital, Indore",
        "College of Dentistry- Indore",
        "Guru Gobind Singh College of Dental Science & Research Centre, Burhanpur",
        "Hitkarini Dental College & Hospital, Jabalpur",
        "Index Institute of Dental Sciences, Indore",
        "Institute of Dental Education & Advance Studies (IDEAS), Gwalior",
        "Maharana Pratap College of Dentistry & Research Centre, Gwalior",
        "Mansarover Dental College, Bhopal",
        "Modern Dental College & Research Centre, Indore",
        "People’s Dental Academy, Bhopal",
        "Peoples College of Dental Sciences & Research Centre, Bhopal",
        "Rishiraj College of Dental Sciences & Research Centre, Bhopal",
        "RKDF Dental College & Research Centre, Bhopal",
        "Sri Aurobindo College of Dentistry, Indore",
        "Aditya Dental College, Beed",
        "Annasaheb Chudaman Patil Memorial Dental College, Dhule",
        "Armed Forces Medical College, Pune-1",
        "Bharati Vidyapeeth Dental College & Hospital, Navi Mumbai",
        "Bharati Vidyapeeth Dental College & Hospital, Pune",
        "Bharati Vidyapeeth Dental College & Hospital, Sangli",
        "Chatrapati Shahu Maharaj Shikshan Sanstha’s Dental College & Hospital, Aurangabad",
        "D.Y. Patil Dental School, Pune",
        "Dr. D.Y. Patil Dental College & Hospital, Pune",
        "Dr. Hedgewar Smruti Rugna Seva Mandals Dental College & Hospital, Hingoli",
        "Dr. Rajesh Ramdasji Kambe Dental College & Hospital, Akola",
        "Govt. Dental College & Hospital, Aurangabad",
        "Govt. Dental College & Hospital, Mumbai",
        "Govt. Dental College & Hospital, Nagpur",
        "Late Shri Yashwantrao Chavan Memorial Medical & Rural Development Foundation's Dental College & Hospital, Ahmednagar",
        "M.A. Rangoonwala College of Dental Sciences & Research Centre, Pune",
        "Maharashtra Institute of Dental Sciences & Research (Dental College)",
        "Mahatma Gandhi Missions Dental College & Hospital, Kamothe, Navi Mumbai",
        "Mahatma Gandhi Vidya Mandir’s Dental College & Hospital, Nashik",
        "Nair Hospital Dental College, Mumbai",
        "Nanded Rural Dental College & Research Center, Nanded",
        "Padmashree Dr. D.Y. Patil Dental College & Hospital, Navi Mumbai",
        "Pandit Dindayal Upadhyay Dental College, Solapur",
        "Rural Dental College, Loni",
        "S.M.B.T. Dental College & Hospital, Amrutnagar",
        "Saraswati Danwantri Dental College & Hospital, Parbhani",
        "School of Dental Sciences, Krishna Institute of Medical Sciences, Karad",
        "Sharad Pawar Dental College & Hospital, Wardha",
        "Sinhgad Dental College & Hospital, Pune",
        "SMBT Institute of Dental Sciences and Research, Nandihills, Nashik",
        "Swargiya Dadasaheb Kalmegh Smruti Dental College & Hospital, Nagpur",
        "Tatyasaheb Kore Dental College & Research Centre, New Pargaon",
        "Terna Dental College & Hospital, Navi Mumbai",
        "Vasantdada Patil Dental College and Hospital, Sangli",
        "Vidarbha Youth Welfare Society’s Dental College & Hospital, Amravati",
        "VSPM Dental College & Research Centre",
        "Yerala Medical Trust & Research Centre’s Dental College & Hospital, Navi Mumbai",
        "Yogita Dental College & Hospital, Ratnagiri",
        "Dental College, Jawaharlal Nehru Institute of Medical Sciences",
        "Dental College, RIMS, Imphal",
        "Hi-Tech Dental College & hospital, Bhubaneswar",
        "Institute of Dental Sciences, Bhubaneswar",
        "Kalinga Institute of Dental Sciences, Bhubaneswar",
        "S.C.B. Dental College & Hospital, Cuttack",
        "Indira Gandhi Institute of Dental Sciences, Pondicherry",
        "Mahatma Gandhi Post Graduate Institute of Dental Sciences, Puducherry",
        "Mahe Institute of Dental Sciences & Hospital, Mahe",
        "Sri Venkateshwaraa Dental College, Puducherry",
        "Adesh Institute of Dental Sciences & Research, Bathinda",
        "Baba Jaswant Singh Dental College Hospital & Research Institute, Ludhiana",
        "Christian Dental College, Ludhiana",
        "Dasmesh Institute of Research & Dental Sciences, Faridkot",
        "Desh Bhagat Dental College & Hospital, Mandi Gobindgarh",
        "Genesis Institute of Dental Sciences & Research, Ferozepur",
        "Gian Sagar Dental College & Hospital",
        "Govt. Dental College & Hospital, Patiala",
        "Guru Nanak Dev Dental College & Research Institute, Sunam",
        "Luxmi Bai Institute of Dental Sciences & Hospital, Patiala",
        "National Dental College & Hospital, Gulabgarh",
        "Pb. Govt. Dental College & Hospital, Amritsar",
        "Rayat Bahra Dental College, Mohali",
        "Shaheed Kartar Singh Sarabha Dental College & Hospital, Ludhiana",
        "Sri Guru Ram Das Institute of Dental Sciences & Research, Amritsar",
        "Sukhmani Dental College & Hospital, Derabassi",
        "Darshan Dental College & Hospital, Udaipur",
        "Daswani Dental College & Research Centre, Kota",
        "Eklavya Dental College & Hospital, Kotputli",
        "Geetanjali Dental & Research Institute, Udaipur",
        "Govt. Dental College & Hospital, Jaipur",
        "Jaipur Dental College, Jaipur",
        "Jodhpur Dental College General Hospital, Jodhpur",
        "Maharaja Ganga Singh Dental College & Research Centre, Sri Ganganagar",
        "Mahatma Gandhi Dental College & Hospital, Sitapura, Jaipur",
        "NIMS Dental College, Jaipur",
        "Pacific Dental College & Research Centre",
        "Pacific Dental College, udaipur",
        "R.R. Dental College & Hospital, Udaipur",
        "Rajasthan Dental College & Hospital, Jaipur",
        "Surendera Dental College & Research Institute, Sri Ganganagar",
        "Vyas Dental College & Hospital, Jodhpur",
        "Adhiparasakthi Dental College & Hospital, Melmaruvathur",
        "Asan Memorial Dental College & Hospital, Kancheepuram",
        "Best Dental Science College, Madurai",
        "Chettinad Dental College & Research Institute, Kancheepuram",
        "CSI College of Dental Sciences & Research, Madurai",
        "Dhanalakshmi Srinivasan Dental College",
        "Faculty of Dentistry, Rajah Muthiah Dental College & Hospital, Annamalai Nagar",
        "Government Dental College, Pudukkottai",
        "JKK Natrajah Dental College & Hospital, Komarapalayam",
        "K.S.R. Institute of Dental Science & Research, Tiruchengode",
        "Karpaga Vinayaga Institute of Dental Sciences, Kanchipuram",
        "Madha Dental College & Hospital, Kundrathur",
        "Meenakshi Ammal Dental College & Hospital, Chennai",
        "Nandha Dental College and Hospital",
        "Priyadarshini Dental College & Hospital, Pandur",
        "R.V.S. Dental College & Hospital, Sulur",
        "Ragas Dental College & Hospital, Chennai",
        "Rajas Dental College & Hospital, Kavalkinaru Jn",
        "S.R.M. Dental College, Ramapuram, Chennai",
        "Sathyabama University Dental College and Hospital, Chennai",
        "Saveetha Dental College & Hospital, Chennai",
        "Sree Balaji Dental College & Hospital, Narayanpuram",
        "Sree Mookambika Institute of Dental Sciences, Kulasekharam",
        "Sri Ramachandra Dental College & Hospital, Porur",
        "Sri Ramakrishna Dental College & Hospital, Coimbatore",
        "Sri Venkateswara Dental College & Hospital, Kancheepuram",
        "SRM Kattankulathur Dental College & Hospital, Kanchipuram",
        "Tagore Dental College & Hospital, Chennai",
        "Tamil Nadu Government Dental College & Hospital, Chennai",
        "Thai Moogambigai Dental College & Hospital, Chennai",
        "Vinayaka Mission’s Sankarachariyar Dental College, Salem",
        "Vivekanandha Dental College for Women, Elayampalayam",
        "Army College of Dental Sciences, Secunderabad",
        "Government Dental College and Hospital, Hyderabad",
        "Kamineni Institute of Dental Sciences, Nalgonda",
        "Malla Reddy Dental College for Women, Hyderabad",
        "Malla Reddy Institute of Dental Sciences,Hyderabad",
        "Mamata Dental College, Khamam",
        "Mamata Institute of Dental Sciences",
        "Meghna Institute of Dental Sciences, Nizamabad",
        "MNR DENTAL COLLEGE & HOSPITAL , MEDAK",
        "Panineeya Mahavidyalaya Institute of Dental Sciences & Research Centre, Hyderabad",
        "Sri Balaji Dental College, Hyderabad",
        "Sri Sai College of Dental Surgery, Vikarabad",
        "Sri Venkata Sai Institute of Dental Sciences, Hyderabad",
        "Tirumala Institute of Dental Sciences & Research Centre",
        "Agartala Government Dental College and IGM Hospital",
        "Babu Banarasi Das College of Dental Sciences, Lucknow",
        "Career Institute of Dental Sciences & Hospital, Lucknow",
        "Chandra Dental College & Hospital, safedabad",
        "D.J. College of Dental Sciences & Research, Modi Nagar",
        "Dental College Azamgarh",
        "Dr. Ziauddin Ahmad Dental College, Aligarh",
        "Faculty of Dental Sciences, Banaras Hindu University, Varanasi",
        "Faculty of Dental Sciences, Lucknow",
        "I.T.S. Centre for Dental Studies & Research, Ghaziabad",
        "I.T.S. Dental College, Hospital & Research Centre, Gr. Noida",
        "Inderprastha Dental College & Hospital, Ghaziabad",
        "Institute of Dental Sciences, Bareilly",
        "Institute of Dental Studies & Technology, Modinagar",
        "K.D. Dental College, Mathura",
        "Kalka Dental College & Hospital, Meerut",
        "Kothiwal Dental College & Research Centre, Moradabad",
        "Maharana Pratap Dental College & Hospital, Kanpur",
        "Purvanchal Institute of Dental Sciences, Gorakhpur",
        "Rama Dental College, Hospital & Research Centre, Kanpur",
        "Santosh Dental College & Hospital, Ghaziabad",
        "Saraswati Dental College, Lucknow",
        "Sardar Patel Post Graduate Institute of Dental & Medical Sciences, Lucknow",
        "School of Dental Sciences, Greater Noida",
        "Shree Bankey Bihari Dental College & Research Centre, Masuri",
        "Subharati Dental College, Meerut",
        "Teerthanker Mahaveer Dental College & Research Centre, Moradabad",
        "U.P. Rural Institute of Medical Sciences & Research, Saifai",
        "Narayan Swami Hospital and Dental College",
        "Seema Dental College & Hospital, Rishikesh",
        "Uttaranchal Dental College & Medical Research Institute, Dehradun",
        "Burdwan Dental College, Rajbati",
        "Dr. R. Ahmed Dental College & Hospital, Calcutta",
        "Gurunanak Institute of Dental Science & Research, Kolkatta",
        "Haldia Institute of Dental Sciences and Research, Banbishnupur",
        "Kusum Devi Sunderlal Dugar Jain Dental College & Hospital, Kolkata",
        "The North Bengal Dental College, Sushratangar",
        "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital , Srikot Ganganali Badrinath Marg Srikot Srinagar Pauri Garhwal Uttarakhand, Uttarakhand-246178",
        "166 Military Hospital, Raipur Satwari, Jammu, Jammu and Kashmir-180003",
        "7 Air Force Hospital, Nathu Singh Road, Kanput Cantt, Kanpur, Uttar Pradesh-208004",
        "A C P M Medical College, Sakri Road, Dhule, Maharashtra-424001",
        "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) south bye pass crossing, Tosham Road, Hisar, Haryana-125005",
        "Aadicura Superspeciality Hospital, (A Unit of Banyan Hospital Pvt. Ltd.) 5,6,7,Windward Park, Jetalpur Road, Vadodara, Gujarat-390007",
        "Aakash Healthcare Super Specialty Hospital, Plot, Road No. 201 Sector 3 Dwarka, Delhi-110075",
        "AAKASH HOSPITAL, NO:393/1 T.H ROAD, THIRUVOTTIYUR CHENNAI Tamil Nadu, Tamil Nadu-600019",
        "Aarthi Hospital, Door no. 60, Santhaipettai Road, Kovilpatti, Kovilpatti Taluk, Thoothukudi District Tamil Nadu , Tamil Nadu-628502",
        "AARTHI SCANS AND LABS, (A UNIT OF AARTHI SCANS PVT LTD) NO. 60, 100 FEET ROAD, VADAPALANI, CHENNAI, Tamil Nadu-600026",
        "Aarvy Healthcare Private Limited, Sector 90, Gurugram, Haryana, Haryana-122505",
        "AAYUSH MULTISPECIALITY HOSPITAL, NEAR OLD BUS STATION, SAVSAR PLOT, AYODHYAPURI MAIN ROAD, MORBI, Gujarat-363641",
        "Aayush NRI LEPL healthcare Private Limited, 48 -13 - 3 and 3A, SRI RAMACHANDRA NAGAR, VIJAYAWADA, Andhra Pradesh-520008",
        "Abhi Eye Hospital, JOTHI MILL STREET,JOTHI NAGAR,ATTUR-636102 Tamil Nadu , Tamil Nadu-636102",
        "Abirami Kidney Care Dr. Thangavelu Hospital, 580 Brough Road, Near Ravi Theatre Road, Erode, Tamil Nadu-638011",
        "Acchutha Eye Care, H3 EVN Road, Periyar Nagar, Near Surampatti Nal Road, Erode Tamil Nadu , Tamil Nadu-638009",
        "Accord Superspeciality Hospital, Sector 86, Faridabad, Haryana-121002",
        "Action Cancer hospital, H-2/FC-34, A-4 Paschim Vihar, New Delhi, Delhi-110063",
        "Adarsha Hospital, Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka-576102",
        "Aditya Birla Memorial Hospital, P.O. Chinchwad, Pune, Maharashtra-411033",
        "Aditya Diagnostics and Hospital, Bordoloi Avenue, Dibrugarh, Assam-786005",
        "ADK Jain Eye Hospital, Pathshala Road, Khekra, Dist. Baghpat , Uttar Pradesh-250101",
        "Advanced Medicare and Research Institute (AMRI), P-4 and 5, CIT Scheme 72, Block A, Gariahat Road, Kolkata, West Bengal-700029",
        "Ahalia Diabetes Hospital , Ahalia Campus, Kozhippara (PO), Palakkad (Dt.), , Kerala-678557",
        "Ahalia Foundation Eye Hospital, Palakkad, Kerala-678557",
        "Ahalia Hospital , (Women and Childrens Hospital - Maternal and Child Care Centre) Ahalia Campus, Kozhippara Post, Palakkad, Kerala-678557",
        "Ahooja Eye And Dental Institute, 560/1, Dayanand Colony, Gurgaon , Haryana-122001",
        "AIG Hospital, Mind Space Road, Gachibowli, Hyderabad, Telangana-500032",
        "Al-Aafia Civil Hospital , Mandikhera Distt Nuh (Mewat) Haryana Near Traffic Police Station Delhi Alwar Road Haryana , Haryana-122108",
        "Alakh Nayan Mandir Insttitute of Ophthalmology, Ashok Nagar, UDAIPUR, Rajasthan-313001",
        "Alam Hospital and Research Centre, Booty Road, Bariatu, Ranchi, Jharkhand-834009",
        "Alchemist Hospital, Sector-21, Panchkula Haryana , Haryana-134112",
        "ALL IS WELL MULTI SPECIALITY HOSPITAL, MOHAMMADPURA, BURHANPUR, Madhya Pradesh-450331",
        "Alliance Multispeciality Hospital LLP, W.No-15, Property no .151000208, Behind Suraj Gas godown, Chandur Road, Ichalkaranji. , Maharashtra-416115",
        "Alluri Sita Ramaraju Academy of Medical Sciences (ASRAM), ASRAM Hospital, Malkapuram PO, NH-5, Eluru, West Godavari Dist, Andhra Pradesh-534004",
        "Almadeena Institute of Medical Science, Changuvetty, kottakkal, Malappuram(district), Kerala-676503",
        "Amala Institute of Medical Sciences, Amala Nagar, THRISSUR, Kerala-680553",
        "Amandeep Hospital, G.T.Road, Model Town, Amritsar, Punjab-143001",
        "Amandeep Hospital, Dalhousie Road, Mamun Cantt, Pathankot, Punjab-145001",
        "Amandeep Medicity, Mall Road, Near Novelty Chownk,Maharaja Ranjit Singh Nagar, Joshi Colony, Amritsar, Punjab-143001",
        "Amar Hospital, #8, Bank Colony, Income Tax Office Road, Patiala, Punjab-147001",
        "AMAR HOSPITAL, Julai Nagar, Gangavathi, Karnataka-583227",
        "AMARA HOSPITAL, A UNIT OF G2 HEALTHCARE PRIVATE LIMITED) 7-3-166, RENIGUNTA - KADAPA HIGWAY, KARAKAMBADI(V), RENIGUNTA (M), TIRUPATI, ANDHRAPRADESH. Andhra Pradesh , Andhra Pradesh-517520",
        "Amardeep Eye Care , 12/694, 2nd Milestone, Kollam,, Kerala-691004",
        "AMRI Hospitals, Mukundapur, 230, Barakhola Lane, Behind Metro Cash and Carry on E.M.Bypass, Kolkata, West Bengal-700099",
        "Amrita Institute of Medical Sciences and Research Centre, Sector 88, Mata Amritanandmayi Marg, Faridabad, Haryana-121002",
        "Amrutha Childrens Hospital, 5-10-15, Kishenpura, Hanamkonda, Warangal, Telangana-506001",
        "Anand Eye Institute, #7-147/1, Nagendra Nagar Colony, Opp: Indian Oil Petrol Pump, Habsiguda Main Road, Hyderabad, Telangana-500007",
        "Anand Hospital and Eye Centre, 21, Bharat Mata Lane Jamnalal Bajaj Marg C-Scheme, Jaipur, Rajasthan-302",
        "Anand Rishiji Hospital and Medical Research Centre, 124-A, Anand Rishiji Marg, Ahmednagar, Maharashtra-414001",
        "Ananthapuri Hospital and Research Institute, Chacka, NH Bypass, Thiruvananthapuram, Kerala-695024",
        "ANDAMAN AND NICOBAR ISLANDS INSTITUTE OF MEDICAL SCIENCES, ATLANTA POINT, PORT BLAIR, SOUTH ANDAMAN, Andaman and Nicobar-744104",
        "Andhra Hospital (Vijayawada), C.V.R. Complex, Prakasham Road, Governor Pet, Vijaywada, Andhra Pradesh-520002",
        "ANIL KULKARNI EYE HOSPITAL, 2/20-21, Hiremath Plots, Near Vantmure Corner, Sangli Road, Miraj , Maharashtra-416410",
        "Ankura hospital for Women and Children , LB nagar Plot No. 10,11,12, Survey nos:9/1/J, Opp.Metro Pillar No.1643, Saroornagar Stadium Line, LB Nagar, Hyderabad , Telangana-500035",
        "Ankura hospital for Women and Children , Madinaguda #1-54/56/3,beside Bharath petroleum,Madinaguda,Hyderabad , Telangana-500050",
        "Ankura Hospital for Women and Children, 55 and 56 7th Phase KPHB JNTU-Hi Tech City Road, Hyderabad - , Telangana-500085",
        "ANKURA HOSPITAL FOR WOMEN AND CHILDREN(A UNIT OF ANKURA TIRUPATI HOSPITALS PVT LTD), D.NO.19-3-14/N,RS NO 5-2A/4 AND 5-2A/5 WARD NO 19, BLOCK NO 3 RENIGUNTA ROAD , P AND T COLONY, TIRUPATI CHITTOOR , Andhra Pradesh-517501",
        "ANKURA MEDICAL AND RESEARCH CENTRE PVT.LTD., PLOT NO1-18, RAGHAVENDRANAGAR, PEERZADIGUDA, NARAPALLY, HYDERABAD, PO UPPAL, , Telangana-500039",
        "Anugraha Eye Hospital, Navabhag Main Road, Behind Central Bus Stand, Near Secab College, Vijayapura. Karnataka , Karnataka-586101",
        "Anup Institute of Orthopaedics and Rehabilitation, G 75-77 , P.C.Colony, Kankarbagh, Patna, Bihar-800020",
        "ANURADHA SUPERSPECIALITY EYE HOSPITAL LLP, PLOT NO 9652,53,71, GANDHI COLONY, GANESH MANDIR BYLANE, VISHRAMBAG, SANGLI, Maharashtra-416415",
        "Apex Heart Institute, Block: G-L, Mondeal Business Park, Near Gurudwara, S. G. Highway, Ahmedabad, Gujarat-380059",
        "Apex Hospital, Opposite Hotel Park Square, Majhola, Delhi Road, Moradabad, Uttar Pradesh-244001",
        "Apex Hospital, N7/2A-5D , DLW, Hydil Road, Varanasi, Uttar Pradesh-221004",
        "Apex Hospital, Jaipur, Rajasthan-302017",
        "Apex Superspeciality Hospital and IVF Centre, Atal chowk, Raigarh, Chhattisgarh , Chhattisgarh-496001",
        "APL and ANR Area Hospital, Gudiwada 12/35, Ward No-12, Opp To Head Post Office, Beside State Bank Of India,Gudiwada Andhra Pradesh , Andhra Pradesh-521301",
        "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Adlux Junction, Karukutty, Angamaly Ernakulam, Kerala-683576",
        "Apollo BGS Hospital, Adichunchanagiri Road, Kuvempunagar, Mysore, Karnataka-570023",
        "Apollo Excelcare Hospital, NH-37, Near Ganesh Mandir, Paschim Boragaon, Guwahati, Assam-781033",
        "Apollo First Med Hospital, No.154, Poonamallee High Road, Kilpauk, Chennai , Tamil Nadu-600010",
        "Apollo Hospital, Room No. 306, Office of the Director of Medical Education Jubilee Hills, Hyderabad, Telangana-500033",
        "Apollo Hospital, Parsik Hill Road, Plot no 13, Sector 23,CBD Belapur, Navi Mumbai, Maharashtra-400614",
        "Apollo Hospital, 21, Greams lane, Off Greams Rd, Thousand Lights, Chennai., Tamil Nadu-600006",
        "Apollo Hospital, A Unit of Apollo Hospital Enterprises Limited New No. 1, Old No. 28, Platform Road, Near Mantri Mall, Sheshadripuram, Bengaluru, Karnataka-560020",
        "Apollo Hospital, Village Lingiadih, Seepat Road, Bilaspur, Chhattisgarh-495006",
        "Apollo Hospital, (Unit International Hospitals) Lotus Tower, G S Road, Guwahati, Assam-781005",
        "Apollo Hospital, Plot No. 251 Sainik School Road Unit-15, Bhubaneshwar, Odisha-751005",
        "Apollo Hospital, Health City, Chinagdhili, Visakhapatnam, Andhra Pradesh-530040",
        "Apollo Hospital, D. No. 13-1-3, Main Road, Kakinada, East Godavari District, Andhra Pradesh-533001",
        "Apollo Hospital, 154/11, Opp. I.I.M., Bannerghatta Road, Bangalore, Karnataka-560076",
        "Apollo Hospital International, Plot No. 1A, GIDC Estate Bhat, District - Gandhi Nagar , Gujarat-382428",
        "Apollo Hospital,, PR. No. 3-5-836 to 838, Near Old MLA Quarters, Hyderguda,, Telangana-500029",
        "Apollo Hospital,, Adjacent to Keyes High School, St. Johns Road, Secunderabad, Telangana-500003",
        "Apollo KH Hospital, Ranipet-Vellore Byepass Road, Melvisharam, Tamil Nadu-631050",
        "Apollo Multispecialty Hospitals Limited, 58, Canal Circular Road, Kolkata, West Bengal-700054",
        "APOLLO PROTON CANCER CENTRE, 4/661, Dr Vikram Sarabai Instronic Estate 7th St, Dr. Vasi Estate, Phase II, Tharamani, Chennai, Tamil Nadu , Tamil Nadu-600096",
        "Apollo Rajshree Hospitals Pvt Ltd, Scheme No 74 C, Sector D, Vijay Nagar Indore, Madhya Pradesh-452010",
        "Apollo Speciality Hospital, # 2, Old No. 21/2, 14th Cross, 3rd Block, Near Madhavan Park, Jayanagar, Bengaluru, Karnataka-560011",
        "APOLLO SPECIALITY HOSPITALS, D.NO.16/111/1133,MUTHUKUR ROAD, PINAKANI NAGAR, NELLORE , Andhra Pradesh-524004",
        "Apollo Speciality Hospitals, Trichy Chennai by-pass road, near old palpannai, Ariyamangalam , Tamil Nadu-620010",
        "Apollo Specialty Hospital, Padma Complex, 320 Mount Road, Chennai, Tamil Nadu-35",
        "Apollo Specialty Hospital, No. 64, Vanagaram Ambattur Main Road, Off PH Road, Near Srivaru Kalyanamandapan, Ayanambakkam, Tamil Nadu-600095",
        "Apollo Specialty Hospital, Lake View Road, K K Nagar, Madurai, Tamil Nadu-625020",
        "Apollo Spectra Hospitals, (formerly known as Indo-US Super Speciality Hospital) (A unit of Nano Hospitals Pvt. Ltd.) 7-1-57/BandC, Shyam Karan Road, Anand Bagh, Ameerpet, Hyderabad, Telangana-500016",
        "APOLLO SPECTRA HOSPITALS , Mashallah Building, PG Road, Paradise Circle, Secunderbad , Telangana-500003",
        "Apollomedics Superspeciality Hospital, Sector B, Bargawan, LDA Colony , Lucknow, Uttar Pradesh-226012",
        "Apple Hospital, Udhna Darwaja, Ring Road, Surat, Gujarat-395002",
        "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.) 804/2, 805/2, E Ward, Bhosalewadi, Kadamwadi Road, Kolhapur, Maharashtra-416003",
        "Aralaguppe Mallegowda District Hospital, Azad Park, Chikkamagaluru, Karnataka-577101",
        "Arasan Eye Hospital, 26 Aannamalai Layout, Opp. Royal Theatre, Near Bus Stand, Erode, Tamil Nadu-638011",
        "Aravind Eye Hospital, 64, Sankari Main Road, Opp. To Kaliamman Temple, Nethimedu, Salem, Tamil Nadu-636002",
        "Aravind Eye Hospital, Poonamallee High Road, Noombal, Chennai , Tamil Nadu-600",
        "Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, No. 371, periyakulam Road, Theni, Tamil Nadu-625531",
        "Area Hospital, APVVP Chirala D. No: 175, Wood Nagar, Chirala, Prakasam District Andhra Pradesh , Andhra Pradesh-523155",
        "Area Hospital, Near Tower Clock, College road, Kadiri, Ananthapuramu Dist. State Andhra Pradesh Andhra Pradesh , Andhra Pradesh-515591",
        "Area Hospital, Adoni Andhra Pradesh , Andhra Pradesh-518301",
        "Area Hospital, Nuzvidu House No.1-9,2-9 Revenue ward No -24, Locality- Near RDO Office, City-Nuzvidu,District - Krishna Andhra Pradesh , Andhra Pradesh-521201",
        "Area Hospital, Gudur Hospital Road,Beside Old Rtc Bustand Andhra Pradesh , Andhra Pradesh-524101",
        "Area Hospital, Kuppam Near R and B guest House , Palamaner Road Area Hospital,Kuppam Andhra Pradesh , Andhra Pradesh-517425",
        "Area Hospital, Kandukur Area Hospital, Near NTR Statue, Kandukuru, Prakasam District, AP, 523105. Andhra Pradesh , Andhra Pradesh-523105",
        "AREA HOSPITAL, RAMACHANDRAPURAM MAIN ROAD, NEAR OLD BUS STAND , Andhra Pradesh-533255",
        "Area Hospital, Amalapuram KNF Road, near Black Bridge, Amalapuram Andhra Pradesh , Andhra Pradesh-533201",
        "AREA HOSPITAL, NARASARAOPET LINGUMGUNTLA, NARASARAOPET, PALNADU DISTRICT , Andhra Pradesh-522601",
        "Area Hospital, Nagari O/o The Medical Superintendent, Area Hospital, Nagari Andhra Pradesh , Andhra Pradesh-517590",
        "Area Hospital Sriikalahasthi, Near RTC busstand Sriikalahasthi Chittoor District AndhraPradesh Andhra Pradesh , Andhra Pradesh-517644",
        "ARIHANT HOSPITAL, CTS NO.10632/A1/B, C. SQUARE,NEHRU NAGAR, BELAGAVI, Karnataka-590010",
        "Arihant Hospital and Research Centre, 283-A, Gumasta Nagar, Indore , Madhya Pradesh-452009",
        "Armed Forces Medical College, Solapur Road, Pune, Maharashtra-411040",
        "Army Hospital (R and R), Delhi Cantt, New Delhi, Delhi-110010",
        "AROKYA HOSPITAL, 3 ROADS. OLD SURAMANGALAM, PALLAPATTI, SALEM, Tamil Nadu-636009",
        "ARORA EYE HOSPITAL AND RETINA CENTRE, 7A-LAJPAT NAGAR, JALANDHAr , Punjab-144003",
        "Artemis Hospital, Sector 51, Gurgaon, Haryana-122001",
        "Arvind Eye Hospital , Avinashi Road, COIMBATORE , Tamil Nadu-14",
        "Arvind Eye Hospital, Abishekapakkam Road, , Puducherry-605007",
        "Arvind Eye Hospital and PG Instt.of Ophthalmology, S N High Road, TIRUNELVELI Junction, Tamil Nadu-627001",
        "Asansol District Hospital, S B Gorai Road, PO + PS: Asansol, West Bengal-713301",
        "Asarfi Hospital, Baramuri, P O Bishunpur Polytechnic, Dhanbad, Jharkhand-828130",
        "Asha Hospital, 298, Road No. 14, Banjara Hills, Hyderabad, Telangana-500034",
        "Ashoka Medicover Hospital, Indira Nagar, Wadala, Nashik, Maharashtra-422009",
        "Ashwani Sahakari Rugnalaya, Survey No.7107-1, Plot No.180, North Sadar Bazar, SOLAPUR, Maharashtra-413003",
        "Ashwini Hospital, Plot No. 3(P), Sector - 1, CDA, Cuttack Orissa , Odisha-753014",
        "Ashwini Trauma Centre, Plot No. 1-COM-14-Sector -1, CDA BIDANASI, Cuttack, Odisha-753014",
        "Asian Heart Institute and Research Centre, G/N Block, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra-400051",
        "Asian Institute of Gastroenterology, 6-3-661, Somajiguda, Hyderabad, Telangana-500082",
        "Asian Institute of Medical Sciences, Sector 21-A, Badhkal Flyover Road, Faridabad, Haryana-121001",
        "Asian Institute of Medical Sciences, Plot No P -72, Milap Nagar, MIDC, Dombivli (E), Maharashtra-421203",
        "Asian Institute of Nephrology and Urology, 6-3-562/A, Erramanzil Colony, Somajiguda, Hyderabad, Telangana-500082",
        "Asian Vivekanand Super Speciality Hospital, Kanth Road, Moradabad,U.P. Uttar Pradesh , Uttar Pradesh-244001",
        "Associated Hospital Government Medical College, Nh1a , Kathua, Jammu and Kashmir , Jammu and Kashmir-184104",
        "Associated Hospital Government Medical College, Kanthbagh Baramulla, Jammu and Kashmir-193101",
        "Associated Hospital Govt. Medical College, Govt. Medical College Makeshift Campus Doda City Doda, Jammu and Kashmir-182202",
        "Aster Aadhar Hospital, R.S. No. 628 B Ward near shastri nagar Kolhapur, Maharashtra-416012",
        "Aster CMI Hospital, #43/2, New Airport Road, NH - 7, Sahakara Nagar, Hebbal, Bangalore, Karnataka-560092",
        "Aster Medcity, Kuttisahib Road, Near Kothad Bridge, South Chittoor P. O., Cheranalloor, Kochi, Kerala-682027",
        "Aster Prime Hospital, (A Unit of Sri Sainatha Multi Specialty Hospital) Plot No. 2 and 4, Behind Mitrivanam Building, Ameerpet, Hyderabad Telangana , Telangana-500038",
        "Aster RV Hospital, CA, 37, 24th Main Rd, ITI Layout, 1st Phase, J. P. Nagar, Bengaluru , Karnataka-560078",
        "ASYM DISTRICT HOSPITAL, BUDGAM NEAR FIRE SERVICE STATION, Jammu and Kashmir-191111",
        "Atal Bihari Vajpayee Regional Cancer Center, 79 Tilla, Post office Kunjaban, Agartala Tripura West , Tripura-799006",
        "Autonomous state medical College, Etah, Marehra Rd, Siraon, Uttar Pradesh-207001",
        "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital, Shahjahanpur, Uttar Pradesh-242001",
        "Autonomous State Medical college Society and S.N.M District Hospital, Rehna Rd, Bypass Rd, NH-2,Comapny Baag, Arya Nagar, Firozabad, Uttar Pradesh-283203",
        "AUTONOMOUS STATE MEDICAL COLLEGE, AYODHYA, DARSHAN NAGAR , AYODHYA (FAIZABAD), Uttar Pradesh-224123",
        "Autonomous State Medical College, Hardoi, Gaura Danda, Sitapur Road Uttar Pradesh , Uttar Pradesh-241001",
        "Aykai Hospital, Institute No.1, Sector 39a, Near Samrala Chowk, Ludhiana Punjab , Punjab-141008",
        "Ayushman Hospital and Health Services, Sector 10, Elephanta Lane, Dwarka, Delhi-110075",
        "B T Savani Kidney Hospital, Near University Gate University Road,Rajkot, Gujarat-360005",
        "B.M. Birla Heart Research Centre, 1/1 National Library Avenue, KOLKATA, West Bengal-27",
        "B.M. Gupta Hospital, H-9, 15, Arya Samaj Rd, SDM Market, Block O, Uttam Nagar, New Delhi Delhi , Delhi-110059",
        "B.R. Singh Hospital and Centre for Medical Education and Research, Eastern Railway, Sealdah, KOLKATA, West Bengal-700014",
        "Baby Memorial Hospital, Indira Gandhi Road, Kozhikode , Kerala-673004",
        "BABY MEMORIAL HOSPITAL KANNUR, (A UNIT OF GENESIS INSTITUTE OF MEDICAL SCIENCES PVT LTD) CHALA BYPASS,NADAL,KANNUR, Kerala-670007",
        "Baderia Metro Prime Multispecialty Hospital, (Formerly Metro Hospital and Cancer Research Centre) Kuchaini Parisar, Damoh Naka, Jabalpur, Madhya Pradesh-482002",
        "Bai Jerbai Wadia Hospital for Children, Institute of Child Health and Research Centre, Acharya Donde Marg, Parel, Mumbai, Maharashtra-400012",
        "Bakin Pertin General Hospital and Training Centre, Po Pasighat, East Siang District, Arunachal Pradesh , Arunachal Pradesh-791102",
        "Balaji Institute of Surgery, Research and Rehabilitation for the Disabled Hospital (BIRRD), Tirumala Tirupati Devasthanams, Tirupati, Chittoor District , Andhra Pradesh-517507",
        "Balrampur Hospital, Golaganj Lucknow, Uttar Pradesh-226018",
        "BALURGHAT DH and SSH, P.O - B.T.PARK PS - BALURGHAT DIST - DAKSHIN DINAJPUR, West Bengal-733103",
        "Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore, Karnataka-560024",
        "Bangalore West Lions Superspecialty Eye Hospital, No. 5, Lions Eye Hospital Road, Off J C Road, Bangalore, Karnataka-560002",
        "Bankers Heart Institute, Near Tagore Nagar, Opp. Suryakiran Complex, Old Padra Road, Vadodara, Gujarat-390015",
        "Bansal Hospital, C Sector, Shahpura Bhopal, Madhya Pradesh-462016",
        "BAPS Pramukh Swami Hospital, Shri Pramukh Swami Maharaj Marg, Adajan Char Rasta, Adajan Surat, Gujarat-395009",
        "Barasat Government Medical College and Hospital, (Formerly North 24 Parganas District Hospital), Barasat Banamalipur, Barasat, Kolkata,, West Bengal-124",
        "BARJORA SUPER SPECIALTY HOSPITAL , P.O.-Barjora, P.S.-Barjora, Dist.-Bankura, West Bengal-722202",
        "Baruipur Sub Division Hospital, Kulpi Road, P.O+P.S - Baruipur, District - South 24 Parganas, State - West Bengal West Bengal , West Bengal-700144",
        "Base Hospital, Delhi Cantt.,, Delhi-110010",
        "BASEL MISSION CSI HOSPITAL, NEAR AMBABAVANI CIRCLE, PB ROAD, BETGERI-GADAG, Karnataka-582102",
        "Batra Hospital and Medical Research Centre, 1, Tuglakabad Institutional Area, M.B. Road,, Delhi-110062",
        "BBC TRUST MULTISPECIALITY HOSPITAL, Behind SBI Bank Reliance Smart,R G ROAD,GANGAVATHI-583227 KOPPAL District , Karnataka-583227",
        "BCM Hospital, M/s BCM HOSPITAL C/o Northern Society of Sisters of the Holy cross Khairabad, Sitapur, Uttar Pradesh-261131",
        "Bejan Singh Eye Hospital, 2/1 313-C M S Road Vettornimadam Nagercoil,Kanniyakumari District, Tamil Nadu-629003",
        "Believers Church Medical College Hospital, St. Thomas Nagar, Kuttapuzha P O, Thiruvalla, Kerala-689103",
        "Bethany Hospital, Pokhran Road 2, Thane West, Thane , Maharashtra-400610",
        "Bethany Hospital, Nongrim Hills, Shillong , Meghalaya-793003",
        "Bhabha Atomic Research Centre and Hospital, Anushakti Nagar, MUMBAI, Maharashtra-400094",
        "Bhagat Phool Singh Govt. Medical College for Women, Khanpur Kalan (Sonepat), Haryana-131305",
        "BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, 2, RAWDON STREET, KOLKATA West Bengal , West Bengal-700017",
        "Bhagirathi Neotia Woman and Child Care Centre, New Town Premises No.- 27-0327, Street No.- 327, DG Block(Newtown), Action Area ID, Newtown, New Town, West Bengal-700156",
        "Bhagwan Mahaveer Jain Hospital, Millers Road, Vasanth Nagar Bangalore, Karnataka-560052",
        "Bhagwan Mahavir Cancer Hospital and Research Centre, Jawahar Lal Nehru Marg, Jaipur, Rajasthan-302017",
        "Bhagwan Mahavir Medica Superspecialty Hospital, Infront of P.H.E.D Colony, Near Booty More, Bariatu Road, Ranchi, Jharkhand-834009",
        "Bhagyoday Tirth Chikitsalay, Khurai Road, Sagar, Madhya Pradesh-470002",
        "Bhailal Amin General Hospital, Bhailal Amin Marg, Gorwa, Vadodara, Gujarat-390003",
        "Bhaktivedanta Hospital and Research Institute, Srishti Complex, Bhaktivedanta Swami Marg, Mira Road (East), Thane, Maharashtra-401107",
        "Bhandari Hospital and Research Centre, 138-A, Vasundhara Colony, Gopalpura Bypass, Tonk Road, Jaipur, Rajasthan-302016",
        "Bharat Cancer Hospital and Research Institute, Surat - Bardoli Road, Saroli, Surat, Gujarat-395010",
        "Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Village:Pendri, Rajnandgaon, Chhattisgarh-491441",
        "Bharath Hospital, Azad Lane Kottayam-1 686001 Kerala , Kerala-686001",
        "Bharati Vidyapeeth (Deemed to be University) Medical College, Dhanakawadi Pune-Satara Road, Pune, Maharashtra-411043",
        "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli Miraj Road Sangli, Maharashtra-416416",
        "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, S V Road, Parkeh Nagar, Opposite Kandivali Police Station, Kandivali (West), Mumbai, Maharashtra-400067",
        "Bhatia General Hospital, Tukaram Javji Road, Tardeo Road, Mumbai, Maharashtra-400007",
        "Bhattar Hospital (Balgopal Children Hospital and Research Institute), Byron Bazaar, Opposite Aashirwad Bhavan, Raipur Chhattisgarh, Chhattisgarh-492001",
        "BHEL Kasturba Hospital, BHOPAL, Madhya Pradesh-22",
        "Bhima Bhoi Medical College and Hospital,, Balangir At-Laltikra Po- Rajendra College, Balangir , Odisha-767002",
        "BHIMAVARAM HOSPITALS LIMITED, D.NO. 7-32/2, CHINAMIRAM, J.P. ROAD, BHIMAVARAM, WEST GODAVARI DISTRICT, Andhra Pradesh-534204",
        "BIG Apollo Spectra Hospitals, Sheetla Mandir Road, Agam Kuan, Patna, Bihar-800030",
        "Bihar State Institute of Mental Health and Allied Sciences(BIMHAS), Koilwar, Bhojpur, Bihar-802160",
        "Billroth Hospital, 43, Laxmi Talkies Road, Opp. Poonamallee Road, Shenoy Nagar, Chennai, Tamil Nadu-600030",
        "Bishnupur District Hospital, Rasikganj, P.O- Bishnupur, Dist-Bankura, West Bengal , West Bengal-722122",
        "Bokaro General Hospital, Bokaro Steel City, Sec. 4, BOKARO, Jharkhand-827004",
        "BOLPUR SUB DIVISION HOSPITAL , NANOOR CHANDIDAS ROAD, BOLPUR, BIRBHUM , West Bengal-731204",
        "Bombay Hospital, IDA Scheme no 94/95, Ring Road, Indore, Madhya Pradesh-452010",
        "Bombay Hospital and Institute of Medical Sciences, 12, New Marine Lines, Mumbai, Maharashtra-400020",
        "Breach Candy Hospital, 60-A, Bhulabhai Desai Road, Mumbai, Maharashtra-400026",
        "Brij Lal Hospital and Research Centre, Anandi Tower, Nainital-Road, NH-87, Haldwani, Nainital, Uttarakhand-263139",
        "Burnpur Hospital, Burnpur, Asansol, District - Paschim Bardhaman, West Bengal-713325",
        "Byramjee Jeejeebhoy Government Medical College, Jayprakash Narayan Road, near Pune railway station, Pune , Maharashtra-411001",
        "C.S.I. Kalyani General Hospital,, 15,Dr.Radhakrishnan Salai Mylapore, Chennai,, Tamil Nadu-600004",
        "Cachar Cancer Hospital and Research Centre, Meherpur,Silchar, Cachar, Assam-788015",
        "Cancer Hospital and Research Institute, Cancer Hills, Mandre Ki Mata Road, Lashkar Gwalior, Madhya Pradesh-474009",
        "Cancer Institute (WIA), No:38, Sardar Patel Road, Adyar, Chennai, Tamil Nadu-600036",
        "Capital Hospital, Bhubaneswar Unit-6, Near Airport Orissa , Odisha-751001",
        "Capitol Hospital, C/O Capitol Hospital Jalandhar Pathankot Road Nh 44 Near Reru Chowk Jal, Punjab-144001",
        "Care Hospital, Plot No.-324(P) Prachi Enclave, Chandrasekharpur, Bhubaneswar, Odisha-751016",
        "Care Hospital, Road No. 1, Banjara Hills, Hyderabad, Telangana-500034",
        "Care Hospital, Exhibition Road, Nampally, Hyderabad, Telangana-500001",
        "Care Hospital of Medical Sciences (CIMS Hospital), Near Shukan Mall, Opp. Science city Road, Sola, Ahmedabad, Gujarat-380060",
        "Care Hospital- Hi Tech City, Old Mumbai Highway, Near Cyberbad Police Commisionerate, Gachibowli,Hyderabad, , Telangana-500032",
        "Caritas Hospital, Thellakom P. O Kottayam , Kerala-686630",
        "Cauvery Heart and Multispeciality Hospital, Bannur Road Near Teresian Circle, Mysore, Karnataka-570029",
        "CC Shroff Memorial Hospital (a unit of Nandini Ramesh Gandhi Public Charitable Trust), #3-4-801, Barkatpura, Kachiguda, Hyderabad , Telangana-500027",
        "Center for Cancer Pathology (Indian Council of Medical Research), (Formerly Known as National Institute of Pathology), Safdarjung Hospital Campus Post Box No-4909, Delhi-110029",
        "Central Hospital, N F Railway Maligaon Guwahati, Assam-781011",
        "Central Hospital, North Western Railway Ganapti Nagar Railwaycolony, Hasanpura Road, Jaipur, Rajasthan-302006",
        "Central Hospital, Gandhinagar, Kanke Road, Ranchi, Jharkhand-834008",
        "Central Hospital, Jagjiwan Nagar, Dhanbad,, Jharkhand-826003",
        "Central Hospital, S.E.C.Railway, Opposite Golf Ground, Railway Colony, Bilaspur, Chhattisgarh-495004",
        "Central India Instt. Of Medical Sciences, 88/2, Bajaj Nagar, NAGPUR, Maharashtra-440010",
        "Central Super Speciality Hospital, East Central Railway Near Karbigahiya Railway Station, Karbigahiya Patna Bihar , Bihar-800001",
        "Centre for Sight, B-5/24 Safdarjung Enclave , Delhi-110029",
        "Centre for Sight, Plot No. 9, Sec. 9, Dwarka Delhi , Delhi-110075",
        "Chacha Nehru Bal Chiktsalaya, Geeta Colony , Delhi-110031",
        "Chaithanya Eye Hospital, Kesavadasapuram, TRIVANDRUM , Kerala-695004",
        "Chaithanya Eye Hospital and Research Institute, Thiruvalla , Kerala-689105",
        "Chaithanya Eye Institute, (A Unit of KGR Visionary LLP Palarivattom, Ernakulam , Kerala-682024",
        "Chalmeda Anand Rao Institute of Medical Sciences (CAIMS) Cancer Hospital and Research Institute, Bommakal Village, Karimnagar Mandal and District, Telangana-505001",
        "Chandan Hospital, Faizabad Road, Near High Court, Lucknow, Uttar Pradesh-226010",
        "Chandannagar Sub Division Hospital, Chandannagar, Hooghly West Bengal , West Bengal-712136",
        "Chandra Mother and Eye Care Pvt Ltd, ChandraTower: TC-13 V-III Vibhuti Khand Next to Hyatt Regency Gomti Nagar Lucknow, Uttar Pradesh-226010",
        "Chandulal Chandrakar Memorial Hospital, Nehru Nagar Chowk, G E Road, Bhilai, Dist. Durg (C.G.), Chhattisgarh-490020",
        "Charak Hospital and Research Center, Hardoi Road, Dubagga, Lucknow Uttar Pradesh , Uttar Pradesh-226003",
        "Chettinad Super Speciality Hospital, Rajiv Gandhi Salai (OMR), Kelambakkam, Chengalpattu District, Tamil Nadu Tamil Nadu , Tamil Nadu-603103",
        "Chinmaya Mission Hospital, 1/1, CMH Road, Indira Nagar, Bangalore, Karnataka-560038",
        "Chirayu Hospital, Hathoj, Kalwar Road, Jaipur, Rajasthan-302012",
        "Chittaranjan National Cancer Institute, 37- S.P. Mukherjee Road, KOLKATA, West Bengal-700026",
        "Chittaranjan National Cancer Institute, New Town Campus Plot No. DJ-01, Premises No. 02-0321, Action area - 1D, New Town, Rajarhat, Kolkata , West Bengal-700156",
        "Chittaranjan Seva Sadan College of Obstetrics, Gynaecology and Child Health, 37, Shyama Prasad Mukherjee Road, Bhowanipore. West Bengal , West Bengal-700026",
        "CHL Hospital, A.B Road, LIG Square, Indore, Madhya Pradesh-452008",
        "Choithram Hospital and Research Centre, Manik Bagh Road, INDORE, Madhya Pradesh-452014",
        "Choithram Netralaya, Plot No. 54-56, Shriram Talawadi, Dhar Road, Indore , Madhya Pradesh-445300",
        "Choudhury Eye Hospital And Research Centre, Shyamaprasad Road (Shillongpatty), Silchar Assam , Assam-788001",
        "Christian Fellowship Hospital, Palani Main Road, Oddanchatram DINDIGUL DIST, Tamil Nadu-624619",
        "Christian Institute of Health Sciences and Research, 4th Mile Central Jail Road, Dimapur, Nagaland-797115",
        "Christian Medical College, VELLORE, Tamil Nadu-632002",
        "Citi Neuro Centre , (A Unit of Hyderabad Institute of Neuro Sciences Private Limited) Road No.12, MLA Colony, Banjarahills, Hyderabad, Telangana-500034",
        "Citizens Specialty Hospital, 1-100/CCH, Near Aparna Sarvor, Nallagandla, Serilingampally, Hyderabad, Telangana-500019",
        "CITY HOSPITAL, Moglaha, Medical College Road, Gorakhpur, Uttar Pradesh-273013",
        "Civil Hospital, Jalandhar Road, Hoshiarpur Punjab , Punjab-146001",
        "Civil Hospital, Near SBI Bank, Ram Bagh Road, Barnala Punjab , Punjab-148101",
        "Civil Hospital, Rohtak Subhash Road Near Gohana Adda Rohtak , Haryana , Haryana-124001",
        "Civil Hospital, Bahadurgarh, District Jhajjar, Haryana-124507",
        "Civil Hospital, Aizawl, Dawrpui, Mizoram-796001",
        "CIVIL HOSPITAL, Pathankot, Punjab , Punjab-145001",
        "Civil Hospital, NIT, Opp. MCF Faridabad, Haryana-121001",
        "Civil Hospital, Sector-10, Gurugram , Haryana-122001",
        "Civil Hospital, Ambala Cantt , Ambala Haryana , Haryana-133001",
        "Civil Hospital (Gov) Rani Durgawati Hospital, Beside High Court South Civil Line Jabalpur, Madhya Pradesh-482001",
        "Civil Hospital GURDASPUR, NEAR BYEPASS JEEVANWAL BABBRI,GURDASPUR Punjab , Punjab-143521",
        "Civil Hospital Kapurthala, Sultanpur Road Punjab , Punjab-144601",
        "Civil Hospital Palwal, Near Old Sohna Road Palwal , Haryana-121102",
        "Civil Hospital Shillong, Laban, Lachumiere, Shillong, , Meghalaya-793001",
        "CKS HOSPITAL (A UNIT OF CKS MEDICARE PRIVATE LIMITED), F-98A, VKIA, ROAD NO 6 SIKAR ROAD, JAIPUR , Rajasthan-302013",
        "CMC Hospital, Near Dabra Chowk,Delhi Road,Hisar. Haryana , Haryana-125001",
        "Coimbatore childtrust hospitals pvt ltd, 111,112 nanjappa nagar,Trichy Road Singanallur Coimbatore, Tamil Nadu-641045",
        "College of Medicine and JNM Hospital, P. O.: Kalyani District, Nadia, West Bengal-741235",
        "Command Hospital, Western Command, Chandimandir, Haryana-134107",
        "Command Hospital Air Force, Old Airport Road Agram Post Bangalore, Karnataka-560007",
        "Comtrust Eye Hospital, Mini Bye Pass Road, Puthiyara, Kozhikode, Calicut, Kerala-673004",
        "Contai Sub Divisional Hospital, VILL and P.O. - DARUA, P.S. - CONTAI, DIST. - PURBA MEDINIPUR West Bengal , West Bengal-721406",
        "Continental Hospital, Plot No. 3, Road No. 2, IT and Financial District, Nanakramguda, Gachibowli, Hyderabad, Telangana-500032",
        "Cosmopolitan Hospital, Murinjapalam, Pattom P O, TRIVANDRUM, Kerala-695004",
        "Cosmos Hospital, Prem Nagar, Po: Kazipura, Kanth Road, Moradabad , Uttar Pradesh-244001",
        "Cottage Hospital , Station Road , Upleta Dist.- Rajkot , Gujarat-360490",
        "Craft Hospital and Research Centre, Kodungallur, Trichur, Kerala-680664",
        "Credence Hospital, Near Ulloor Bridge, Trivandrum, Kerala-695011",
        "Cure Well Hospital, 15-1-48 Srinivasa Colony, Near M.G.M.Hospital, Warangal, Telangana-506007",
        "Cygnus Institute of Gastroenterology , Plot No 34 and 35, Nizampet X Road, Opp More Supermarket, Sardar Patel Nagar, Hyderabad, Telangana-500072",
        "Daga Memorial Women Government Hospital Nagpur, Ganjakhet Chowk Ganbhibag Nagpur Maharashtra , Maharashtra-440002",
        "Daya General Hospital, Shornur Road, Near Viyyur Bridge, Thrissur, Kerala-680022",
        "Dayanand Medical College and Hospital, Tagore Nagar, Ludhiana , Punjab-141001",
        "Deen Dayal Upadhyay Hospital, Hari Nagar, New Delhi, Delhi-110064",
        "Deenanath Mangeshkar Hospital and Research Centre., 8+13/2, Erandwane, Near Mhatre Bridge, Pune, Maharashtra-411004",
        "DEEP NURSING HOME AND CHILDREN HOSPITAL, 481, MODEL TOWN ROAD, PRITAM NAGAR, MODEL TOWN, LUDHIANA, Punjab-141002",
        "Delhi Heart and Lung Institute, 3 MM, II, Panchkuian Road,, Delhi-110055",
        "Delhi Hospital And Nursing Home, Delhi Rohtak Road Metro Pillar No. 812 Near Shyamji Complex Bahadurgarh,Dist Jhajjar, Haryana-124507",
        "Desun Hospital and Heart Institute, Desun More, E M Bypass, Kasba Golpark, Kolkata, West Bengal-700107",
        "Devadoss Multi Speciality Hospital, , Door No. 75/1, Surveyor Colony, Alagar Kovil Main Road, Madurai, Tamil Nadu-625007",
        "Dhanvantri Critical Care Center, 27-28, Poonkundranar Street Karungalpalayam Erode, Tamil Nadu-638003",
        "Dhanwantri Hospital and Research Centre, 67/56-A, Near Mandara Bus Stand, New Sanganer Road, Mansarovar, Jaipur, Rajasthan-20",
        "Dhanya Mission Hospital, Potta,Chalakudy, Thrissur Dt,, Kerala. Kerala , Kerala-680722",
        "Dharamshila Narayana Superspeciality Hospital, Dharamshila Marg, Vasundara Enclave,, Delhi-110096",
        "Dharan Hospital, 14,Seelanaickenpatti Bye-Pass, Salem , Tamil Nadu-636201",
        "Dhayal Hospital and Research Centre, Nh52,Near Math Mandir,Reengus,Sikar,Rajasthan Rajasthan , Rajasthan-332404",
        "Dhir Hospital, Meham Chowk Circular Road Bhiwani, Haryana-127021",
        "Diamond Harbour Government Medical College And Hospital, Harindanga, Newtown, Ward no. -04, PO and PS - Diamond Harbour, Pin - 743331, Dist - south 24 parganas West Bengal , West Bengal-743331",
        "Dinhata Sub-Divisional Hospital , Dinhata Main Road; P.O and P.S - Dinhata, Dist. - Cooch Behar, West Bengal-736135",
        "DISHARI HEALTH POINT PRIVATE LIMITED, 19, B G ROAD, P.O -MOKDUMPUR, P.S-ENGLISH BAZAR, DISTRICT - MALDA West Bengal , West Bengal-732103",
        "Distric Hopital Gwalior, Mall Road Morar Gwalior Madhya Pradesh , Madhya Pradesh-474006",
        "District Civil Hospital, Maharaja Agarsen, Hisar, Haryana-125001",
        "District Civil Hospital, Opposite Govt.Polytechnic Ambala City, Haryana-134003",
        "District Civil Hospital, Circular Road, Rewari (Haryana) Haryana , Haryana-123401",
        "District Combined Hospital, sector 39 noida gautam budh nagar, Uttar Pradesh-201301",
        "District Combined Hospital, Sec 23 Sanjay Nagar Ghaziabad, Uttar Pradesh-201002",
        "DISTRICT COMBINED HOSPITAL, VRINDAVAN MATHURA, Uttar Pradesh-281121",
        "DISTRICT HEADQUARTER HOSPITAL, GRAND ROAD PURI, Odisha-752001",
        "District Hospital, Phase- 6 Sas Nagar, Mohali, Punjab-160055",
        "District Hospital, 5, D L Roy Road, Krishnagar, Nadia, West Bengal-741101",
        "District Hospital, Near Sangam Circle, Dr. Rajkumar Road, Ballari, Karnataka-583101",
        "District Hospital, Dholpur Old City Road Dholpur Rajasthan , Rajasthan-328001",
        "District Hospital, Beside Bus Stand, Gollaguda, Nalgonda District, Telangana-508001",
        "District Hospital, Kanker In Front Of Main Post Office , Main Road , Kanker Chhattisgarh , Chhattisgarh-494334",
        "District Hospital, Killa Road, Dharwad, Karnataka-580008",
        "District Hospital, Near S P Office, Barshi Road Beed, Maharashtra-431122",
        "District Hospital , Court Road, Palakkad, Kerala-678001",
        "DISTRICT HOSPITAL, FATEHGARH SAHIB, PUNJAB, Punjab-140406",
        "DISTRICT HOSPITAL , UJJAIN CHAMUNDA MATA CHOWK AGAR ROAD UJJAIN , Madhya Pradesh-456001",
        "District Hospital, B H Road, Tumakuru, Karnataka-0",
        "District Hospital, Shivaji Ward, Tili Road, Sagar, Madhya Pradesh-470002",
        "District Hospital, B D Road, Beside DHO Office, Chitradurga, Karnataka-577501",
        "District Hospital , Pratapgarh Banswara Road Pratapgarh Rajasthan , Rajasthan-312605",
        "DISTRICT HOSPITAL , MANDI GATE PANDRI , Chhattisgarh-492001",
        "District Hospital, APVVP,Proddatur Yerraguntla Bye pass road, Proddatur Andhra Pradesh , Andhra Pradesh-516360",
        "District Hospital , Pulwama, Jammu and Kashmir-192301",
        "DISTRICT HOSPITAL, TANUKU RP ROAD, TANUKU, WEST GODAVARI , Andhra Pradesh-534211",
        "District Hospital, Duderhama Ganderbal, Jammu and Kashmir-191201",
        "District Hospital, Patel Road, Madanapalle Andhra Pradesh , Andhra Pradesh-517325",
        "District Hospital, K E B Road, Haveri, Karnataka-581110",
        "District Hospital, 10, Biplabi Haren Ghosh Sarani, Howrah, West Bengal-711101",
        "District Hospital, Oppposite To Maralu Siddeshwara Temple M G Road Chikkaballapur, Karnataka-562101",
        "District Hospital, SEVAN SKY RIVER, NEAR OF GANGA ASHRAM, SEHORE, Madhya Pradesh-466001",
        "District Hospital , Andhra Pradesh Vaidya Vidhana Parishad Cumbum Road Markapur Prakasam Dist Andhra Pradesh , Andhra Pradesh-523316",
        "District Hospital, Atmakur Govt General Hospital, Near market yard, Atmakur, SPSR Nellore Distrct. Andhra Pradesh , Andhra Pradesh-524322",
        "District Hospital , Alipurduar, Hospital Road, Alipurduar, West Bengal-736121",
        "District Hospital , Ramanagara Kumundhan Mohalla Near Police Stastion Ramanagara Karnataka , Karnataka-562159",
        "District Hospital , Kabirdham C.G. Near Collector office Block District Kabirdham , Chhattisgarh-491995",
        "District Hospital, Main Road Surajpur Chhattisgarh , Chhattisgarh-497229",
        "District Hospital, Reasi Aghar Ballian Reasi Jammu and Kashmir , Jammu and Kashmir-182311",
        "District Hospital, Nawanshahr, VPO Barnala Kalan, Chandigarh Road, SBS Nagar, Punjab, Punjab-144514",
        "District Hospital, Kulgam, Jammu and Kashmir-192231",
        "District Hospital , Mandi Road Baran Rajasthan , Rajasthan-325205",
        "District Hospital, Sector No-11, Navanagar Bagalkot, Karnataka-587103",
        "District Hospital, Palace Road Sirohi, Rajasthan-307001",
        "District Hospital , Darjeeling 20 Bazar Cart Road, Beside Sadar Police Station, Darjeeling West Bengal , West Bengal-73410",
        "District Hospital, Ratlam, Madhya Pradesh-457001",
        "DISTRICT HOSPITAL , GUNA MP LAXMI GANJ GUNA , Madhya Pradesh-473001",
        "District Hospital, Next to Sainik School second gate Athani Road, Vijayapura , Karnataka-586103",
        "District Hospital, House.No. 30/21-12-M, Block No - 30, Revenue Ward No. 30 Locality:-Malkapatnam, City:- Machilipatnam District:- Krishna,, Andhra Pradesh-521001",
        "District Hospital Bhandara, Near Bus Stand, District Hospital Bhandara Maharashtra , Maharashtra-441904",
        "District Hospital Bilaspur , near old bus stand Bilaspur , Chhattisgarh-495001",
        "District Hospital Chikkaballapur, Opposite to Marulusiddeshwara Temple,M G Road,Chikkaballapura,Karnataka. Karnataka , Karnataka-562101",
        "District Hospital Faridkot, Civil Hospital Faridkot, Opposite to DC Office, Railway Road , Punjab-151203",
        "District Hospital for Women, Akola ,Near Durga chowk, Akola-44400 Maharashtra , Maharashtra-444001",
        "District Hospital Kargil, kurbathang Kargil U.T Ladakh, Ladakh-194103",
        "District Hospital Morena, MS Road Morena , Madhya Pradesh-476001",
        "District Male Hospital , Aspatal Cauraha, Kotwali Rd, Basti, Uttar Pradesh, Uttar Pradesh-272002",
        "District Male Hospital, Sai ka Takiya, Agra, Uttar Pradesh , Uttar Pradesh-282001",
        "DISTRICT WOMEN HOSPITAL, AILWAL, CHOWK, NEAR BADADEV, DIST- AZAMGARH, Uttar Pradesh-276001",
        "DISTRICT WOMEN HOSPITAL, SAHARANPUR BAJORIA ROAD SAHARANPUR , Uttar Pradesh-247001",
        "DISTRICT WOMEN HOSPITAL, REKAB GANJ, Uttar Pradesh-224001",
        "District women hospital, District Women Hospital Raebareli, Uttar Pradesh-229001",
        "District Women Hospital, Amravati Maharashtra , Maharashtra-444601",
        "DISTRICT WOMEN HOSPITAL BARABANKI, NAKA SATRIKH,OPPOSITE GIC GROUND,BARABANKI, Uttar Pradesh-225001",
        "Divine Heart and Multispecialty Hospital, (An Unit of Divine Heart Hospital and Research Centre (P) Ltd.) Viraj Khand Institutional Area-5, Gomti Nagar, Lucknow, Uttar Pradesh-226010",
        "DIVISIONAL DISTRICT HOSPITAL, DEEN DAYAL UPADHYAY MARG, BALRAMPUR, HARRA KI CHUNGI, SADAR, AZAMGARH, Uttar Pradesh , Uttar Pradesh-276001",
        "Divisional Railway Hospital, Eastern Railway, Asansol, West Bengal-713301",
        "Divisional Railway Hospital, Madurai Near SBI ATM, Southern Railway Colony, , Tamil Nadu-625016",
        "Divisional Railway Hospital, Kalyan West, Near 41 Railway Gate, Dist Thane, Maharashtra-421301",
        "Divyam Hospital, Block no 84, Near Megha Plaza, N.H. -8, Palsana, Surat, Gujarat-394315",
        "Domkal Sub Divisional and Super Speciality Hospital, Office Of The Superintendent Domkal S D and SS Hospital P.O.and P.S-Domkal, Dist.- Murshidabad, Pin-742303 West Bengal , West Bengal-742303",
        "Down Town Hospital, G S Road, Dispur GUWAHATI , Assam-781006",
        "Dr Kamdar Eye Hospital, 123 Second Polo, Karni Marg, Paota, Jodhpur , Rajasthan-342006",
        "Dr M K Shah Medical College And SMS Multispeciality Hospital, Opp. Akshar -3 Complex Near Tapovan Circle Visat Gandhinagar Highway Chandkheda Ahmedabad, Gujarat-382424",
        "DR RAJAN EYECARE HOSPITAL, GURU HARGOBIND NAGAR, PHAGWARA, Punjab , Punjab-144401",
        "DR RAMANARAJUS VISIONTREE SUPERSPECIALITY EYE CARE CENTER, D.NO.10-171-1/1 VISALAKSHINAGAR OPP SRI KODANDARAMALAYAM VISAKHAPATNAM, Andhra Pradesh-530043",
        "Dr Shyama Prasad Mukherjee (Civil) Hospital, 4-A, Park Road, Hazratganj, Lucknow, Uttar Pradesh-226001",
        "Dr Y S Parmar Government Medical College, Nahan District Sirmaur, Himachal Pradesh-173001",
        "Dr. Agarwals Eye Hospital, No. 10, South By Pass road, Opp. To BSNL, Vannarpettai, Tirunelveli, Tamil Nadu-627003",
        "Dr. Agarwal`s Eye Hospital, BBC Tower, Link Road Square, Madhupatna, (Opp. To Madhupatna Police Station), Cuttack, Odisha-753010",
        "Dr. Agarwal`s Eye Hospital and Research Centre, 19 Cathedral Road CHENNAI, Tamil Nadu-600086",
        "Dr. Anji Reddy Multi Speciality Hospitals Private Limited, D.NO.7-319, MAIN ROAD,PIDURAGALLA-522 413. PIDURAGALLA-522 413. PIDURAGALLA-522 413., Andhra Pradesh-522413",
        "Dr. Anji Reddy Super Specialities Hospital, 2-10-10, Barampet 1 st lane, sattenapalli road, narasaraopet. Andhra Pradesh , Andhra Pradesh-522601",
        "Dr. B C Roy Post Graduate Institute of Paediatric Sciences, 111, Narkeldanga Main Road, Kolkata, West Bengal-700054",
        "Dr. B L Kapur Memorial Hospital, Pusa Road, New Delhi, Delhi-110005",
        "Dr. B. Nanavati Hospital, Vivekanand Rd., Vile Parle West, Mumbai, Maharashtra-400056",
        "Dr. B.R. Ambedkar State Institute of Medical Sciences, Phase-6, Sector 56, SAS Nagar (Mohali) , Punjab-160055",
        "Dr. Baba Saheb Ambedkar Hospital, Sector- 06 Rohini, Delhi-110085",
        "Dr. Babasaheb Ambedkar Central Railway Hospital, Opp- Jijamata Udyan, Byculla (East), Mumbai, Maharashtra-400027",
        "Dr. Bankar Healthcare Private Limited, (formerly known as NOVA Pulse IVF Clinic, (Formerly Pulse Womens Hospital) 108, Swestik Society, Navrangpura, Ahmedabad,, Gujarat-380009",
        "Dr. Bidaris Ashwini Hospital, B.L.D.E Road Bijapur, Karnataka-586103",
        "Dr. Chandrakanth Malabar Superspeciality Eye Hospital, Door No: 63/4726A-63/4726A10, K. P. Chandran Road, Cherooty Nagar, Calicut, Kerala-673004",
        "Dr. Hedgewar Rugnalaya, Near Gajanan Maharaj Mandir, Garkheda, Aurangabad, Maharashtra-431005",
        "Dr. Jeyasekharan Hospital and Nursing Home, K P Road, NAGERCOIL, Kanyakumari Distt., Tamil Nadu-629003",
        "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) No.1, Radial Road, Pallikaranai, Chennai, Tamil Nadu-600100",
        "Dr. Kothari`s Eye Hospital, Patel Circle, Udaipur, Rajasthan-313001",
        "Dr. L H Hiranandani Hospital, Hillside Avenue, Hiranandani Gardens, Powai, Mumbai, Maharashtra-400076",
        "Dr. Mohanlal Memorial Gandhi Eye Hospital, ALIGARH, Uttar Pradesh-202001",
        "Dr. Om Parkash Eye Institute Pvt. Ltd., 117-A, The Mall, Amritsar, Punjab-143001",
        "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, Shivaji Nagar, Panchavati Square AMRAVATI, Maharashtra-444603",
        "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) North South Road No.1, Juhu Scheme, Vile Parle (West), Mumbai, Maharashtra-400056",
        "Dr. Radhakrishnan Govt. Medical College , Hamirpur , Himachal Pradesh-177001",
        "Dr. Rajendra Gode Medical College and Hospital, Amravati University Road opposite achut maharaj heart hospital amravati, Maharashtra-444602",
        "Dr. Rajendra Prasad Govt. Medical College, Dist. Kangra At Tanda, , Himachal Pradesh-176002",
        "Dr. Ram Manohar Lohia Institute of Medical Sciences, Vibhuti khand, Gomti Nagar, Lucknow, Uttar Pradesh-226010",
        "Dr. Ramesh Cardiac and Multi Speciality Hospital, D. no. 26-14-1, Nagarampalem Collector Office Road. Guntur, Andhra Pradesh-522004",
        "Dr. Ramesh Cardiac and Multispeciality Hospital, (Formerly Citi Cardiac Research Centre Ltd.) Ring Road, Near ITI College, Vijayawada, Andhra Pradesh-52008",
        "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) Santhi Colony, Fourth Avenue, Anna Nagar, CHENNAI, Tamil Nadu-600040",
        "Dr. Raos ENT Super Speciality Hospitals, Plot No - 29, KPHB Road No - 4 , KPHB, Kukatpally, Hyderabad , Telangana-500082",
        "Dr. Rela Institute and Medical Centre, A Unit of JR Super Speciality Hospitals Pvt. Ltd. No. 7, CLC Works Road, Chrompet, Chennai, Tamil Nadu-600044",
        "Dr. Shroff`s Charity Eye Hospital, 5027, Kedar Nath Road, Daryaganj, , Delhi-110002",
        "Dr. TMA Pai Hospital, Udupi, Karnataka-576101",
        "Dr. Tony`s Super Speciality Eye Institute, Near Govt. Higher Secondary School, High Road, Aluva, Ernakulam, Kerala-683101",
        "Dr. Virendra Laser, Phaco Surgery Center - Eye Hospital , Vision Tower, Drishti Path, Tonk Road, Gandhi Nagar,Jaipur, Rajasthan-302015",
        "Dr.B.N.Bose Sub Divisional Hospital , Talpukur, Barrackpore, West Bengal-700123",
        "Dr.Pinnamaneni Siddhartha Institute of medical sciences and research foundation, Chinna Avutapalli Vijayawada, Andhra Pradesh-521286",
        "Dream flower ivf centre, Opposite Town police station Bank Road Kasaragod, Kerala-671121",
        "Durgabai Deshmukh Hospital, Andhra Mahila Sabha, Vidya Nagar, University Road, Hyderabad, Telangana-500044",
        "Durgapur Steel Plant Hospital, Durgapur, West Bengal-713205",
        "DURGAPUR SUB DIVISIONAL HOSPITAL , DR.ZAKIR HUSSIEN AVENUE, BIDHANNAGAR, DURGAPUR , West Bengal-713206",
        "Edappal Hospital, Edappal, MALAPURAM, Kerala-679576",
        "Egra Sub Divisional Hospital and SSH , Egra Town, PO and PS - Egra, Dist- Purba Medinipur, West Bengal-721429",
        "Ekashilaa Hospitals ( Formerly Maxcare Hospital, A Unit of Kakatiyan Medical Services Pvt. Ltd.), H. No. 6-1-236, Opp: KUDA Office, Hanamkonda, Warangal Urban District, Telangana-506001",
        "Ekta Institute of Child Health, Near- Ram Mandir, Shanti Nagar, Raipur, Chhattisgarh, Chhattisgarh-492001",
        "Emergency Medical Care (EMC) Hospital, Green Avenue, Amritsar , Punjab-143001",
        "Employee State Insurance Corporation Model Hospital, Bharat Nagar, Ludhiana, Punjab-141001",
        "EMS Memorial Co-operative Hospital and Research Centre, Panambi, Perinthalmanna, Malappuram District, Kerala-679322",
        "Endolife Hospital, D.no. 12-12-94, Oldclub road, kothapet, guntur, Andhra Pradesh-522001",
        "Endoworld Hospital Private Limited, 723, In front of Airport, Chikalthana, Aurangabad , Maharashtra-431006",
        "Ernakulam Medical Centre, NH - Bye Pass, Palarivattom KOCHI, Kerala-682028",
        "Erode Medical Centre, R.S. No.: 374/2B,3B (Opp to Rana Mandabam), Perundurai Road, Erode. Tamil Nadu 63801 , Tamil Nadu-63801",
        "ESI Hospital, Manicktala 54, Bagmari Road, Kolkata , West Bengal-700054",
        "ESI Hospital, Jhilmil Shahdara, Delhi-110032",
        "ESIC Dental College and Hospital, Sector - 15, Rohini, Delhi-110089",
        "Esic Hospital , Varanasi Esic Hospital Pandeypur Varanasi Uttar Pradesh , Uttar Pradesh-221002",
        "ESIC Hospital, Sri Maa Anandmayee Marg, Okhla Phase-1,, Delhi-110020",
        "ESIC MC PGIMSR and Model Hospital, 2nd Block, Rajajinagar, Bangalore, Karnataka, Karnataka-560010",
        "ESIC Medical College and Hospital, NH-3, NIT, Faridabad , Haryana-121001",
        "ESIC MEDICAL COLLEGE AND HOSPITAL, SIKANDARPUR, BIHTA, PATNA, BIHAR, Bihar-801103",
        "ESIC Medical College Hospital and Super Specialty Hospital, Sanath Nagar, Hyderabad, Telangana-500038",
        "ESIC Model Hospital, A3, Sector-24, Noida, Uttar Pradesh-201301",
        "ESIC Model Hospital, Hardash Nagar Police Choki Bapunagar Ahmedabad, Gujarat-380024",
        "ESIC Model Hospital, Laxminagar, Ajmer road, Jaipur, Rajasthan-302006",
        "ESIC Model Hospital and Occupational Disease Centre, Nanda Nagar, MR-09, Indore, Madhya Pradesh-452011",
        "Eternal Heart Care Centre and Research Institute, 3-A, jagatpura Road, Near Jawahar Circle, Jaipur, Rajasthan-302017",
        "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL , REMUNA, BALASORE, Odisha-756019",
        "Fehmicare Hospital, 8-3-229/37 A, Tahirvilla, Yousufguda Checkpost, Hyderabad, Telangana-500045",
        "Felix Hospital, NH-01, Sector-137, Expressway, Noida Uttar Pradesh , Uttar Pradesh-201305",
        "Fernandez Hospital, No.8-2-698, Rd Number 12, Bhola Nagar, Banjara Hills, Hyderabad, Telangana Telangana , Telangana-500034",
        "Fernandez Hospital, 4-1-1230, Bogulkunta, Hyderabad, Telangana-500001",
        "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), SONIPAT-BAHALGARH ROAD, SONIPAT NCR, , Haryana-131001",
        "First Neuro, Brain and Spine Super Speciality Hospital, D.No.3-89/8, Kannur Village, Padil, Mangalore,, Karnataka-575007",
        "Ford Hospital and Research Centre Pvt Ltd, NH-30, Khemnichak, New Jaganpura, Ramakrishna Nagar, Patna , Bihar-80002",
        "Fortis Escorts Heart Institute, Okhla Road, New Delhi, Delhi-110025",
        "Fortis Escorts Hospital, Neelam Bata Road, Faridabad, Haryana-121002",
        "Fortis Escorts Hospital, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur , Rajasthan-302017",
        "Fortis Hospital, Sector-62, Phase-VIII, Mohali, Punjab-160062",
        "Fortis Hospital, A- Block, Shalimar Bagh, Delhi-110088",
        "Fortis Hospital, Sector B, Pocket-1, Vasant Kunj, , Delhi-110070",
        "Fortis Hospital, Mundian Kalan, Chandigarh Road, Ludhiana, Punjab-141015",
        "Fortis Hospital, 154/9, Opp. IIMB Bannerghatta Road, Bangalore, Karnataka-560076",
        "Fortis Hospital, B-22, Sector-62, Noida, Uttar Pradesh-201301",
        "Fortis Hospital, Mulund Goregaon Link Road, Mumbai, Maharashtra-400078",
        "Fortis Hospital, 14, Cunningham Road, Bangalore, Karnataka-0",
        "Fortis Hospital,, 730, Anandapur, EM Bypass Road, Kolkata, West Bengal-700107",
        "Fortis Hospitals Limited Fortis Escorts Hospital, Majitha Verka Bypass Road Amritsar, Punjab-143004",
        "Fortis Memorial Research Institute, Sector-44, Opposite HUDA CITY centre Metro Station, Gurgaon,, Haryana-122002",
        "Fortune Hospital, 117/Q/40A41 Sharda Nagar Kanpur Uttar Pradesh , Uttar Pradesh-208025",
        "Frontier Lifeline Hospital, International Centre for Cardio Thoracic and Vascular Diseases, R-30-C, Ambattur Industrial Estate Road, Mogappair, Chennai, Tamil Nadu-600101",
        "G Kuppuswamy Naidu Memorial Hospital, Post Box No. 6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu-641037",
        "Gaba Hospital, Yamuna Nagar, Haryana-135001",
        "Gabberia State General Hospital, Gabberia, Panchla, Howrah West Bengal , West Bengal-711322",
        "Galaxy Care Multispeciality Hospital Pvt. Ltd., (Formerly known as Galaxy Care Laproscopy Institute) Rasthria Shiksan Mandals, 25/A, Karve Road, Pune, Maharashtra-411004",
        "Ganga Medical Centre and Hospital, 313, Mettupalayam Road, Coimbatore, Tamil Nadu-641043",
        "Gangasheel Advanced Medical Research Institute, C-17 Deen Dayal Puram Bareilly, Uttar Pradesh-243122",
        "Gastrocare Liver and Digestive Disease Center, E3-120, 121 Arera colony, Bhopal, Madhya Pradesh-462016",
        "GBH American Hospital, 101, Kothi Bagh, Bhatt Ji Ki Bari, Meera Girls College Road, Udaipur, Rajasthan-313001",
        "GCS Medical College, Hospital And Research Centre, Opp. Drm Office, Near Chamunda Bridge, Naroda Road, Ahmedabad, Gujarat-380025",
        "Geetanjali Medical College and Hospital, NH 8 Bypass, Near Eklingpura Chouraha, Manva Khera, Udaipur, Rajasthan-313001",
        "GEM Hospital, Thiruvengadam Nagar, Perungudi, Chennai, Tamil Nadu-600096",
        "GEM Hospital and Research Centre, 45-A, Pankaja Mill Road, Ramanathapuram, COIMBATORE, Tamil Nadu-641045",
        "General Hospital, Nh-4 Sira Sira Taluk Tumkur District, Karnataka-572137",
        "General Hospital, Salaya Char Rasta, Reliance circle, Jamkhambhaliya, Gujarat-361305",
        "General Hospital, Karauli Old Sabji Mandi Hinduan Gate Rajasthan , Rajasthan-322241",
        "General Hospital, Sector - 6, Panchkula, Haryana-134109",
        "General Hospital, (formerly known as District Head Quarters Hospital) Opposite Collectorate, Wyra Road, Khammam District, Telangana-507002",
        "General Hospital, General Hospital and MCH, Falls Road Gokak Dist.Belgaum Karnataka , Karnataka-591307",
        "General Hospital, Near TB Circle, Chikkaballapura Main Road, Doddaballapura Taluk, Bengaluru Rural District, Karnataka-561203",
        "General Hospital, Opp Daxinapath Vidhyalaya Vyara Dist Tapi, Gujarat-394650",
        "General Hospital, Panipat Near Bus Stand G T Road Panipat Haryana , Haryana-132103",
        "General Hospital, 4th T Block,(Beside Rajiv Gandhi University of Health Sciences) Jayanagar, Bangaluru , Karnataka-560041",
        "General Hospital, Opp. S T Deopt. Raj Mahel Road, Mehsana, Gujarat-384001",
        "General Hospital, Arakalagud Road, Dr. B. R Ambedkar Circle, Holenarasipura Taluk, Hassan District,, Karnataka-573211",
        "General Hospital, Amravati Irwin Square Morshi Road Amravati Maharashtra , Maharashtra-444601",
        "General Hospital, Kanakapura Near Melekote Ksrtc Garage Kanakapura Taluq Ramanagara District Karnataka , Karnataka-562117",
        "General Hospital, Chintamani MG Road. Chintamani. Chikkaballapur District Karnataka 563125, Karnataka-0",
        "General Hospital , Jalore Hospital Chouraha Jalore Rajasthan , Rajasthan-343001",
        "General Hospital, Tiptur Karnataka , Karnataka-572201",
        "General Hospital, Pavagada Tumakuru District Karnataka , Karnataka-561202",
        "General Hospital, (formerly known as District Head Quarters Hospital) MNCL X Roads, Karimnagar District Telangana, Telangana-505001",
        "General Hospital - Sub District Hospital, Opp-Nagarpalika Office Bagicha Road Deesa Banaskantha, Gujarat-385535",
        "General Hospital KGF, General Hospital Robertsonpet KGF Karnataka , Karnataka-563122",
        "General Hospital Shikaripura, General Hospital Shikaripura Near Ib Circle Doddapete Main Road Karnataka , Karnataka-577427",
        "GG Hospital, Murinjapalam Junction, Medical College Post, Trivandrum, Kerala-695011",
        "Ghatal S.D Hospital, Kushpata , Ghatal, Paschim Medinipur, West Bengal-721212",
        "Gheesibai Memorial Mittal Hospital and Research Centre, Pushkar Road, Ajmer, Rajasthan-305004",
        "Giridhar Eye Institute , 28/2576 A Ponneth Temple Road, Kadavanthra, Kochi, Kerala-682020",
        "Gleneagles BGS Hospital, (Formerly Known as BGS Global Hospital) 67, Uttrahalli Road, Kengeri, Bangalore, Karnataka-560060",
        "Gleneagles Hospital (A Unit of Gleneagles Healthcare India Pvt. Ltd., (Formerly known as Gleneagles Global Hospital) 6-1-1070/1 to 4, Lakdi-Ka-Pool, Beside District Collector Office, Hyderabad, Telangana-4",
        "Gleneagles Hospitals - Super Speciality and Transplant Centre, (Formerly Known as Global Hospital - Super Specialty and Transplant Centre) 35, Dr. E Borges Road, Hospital Avenue, Opp Shirodkar High School, Parel, Mumbai, Maharashtra-400012",
        "Global Hospital and Health City, (A unit of Ravindernath GE Medical Associate Pvt Ltd) No-439, Cheran Nagar, Perumbakkam, Chennai, Tamil Nadu-600100",
        "Global Hospital Institute of Ophthalmology, Shantivan, Talhati, Abu Road, Sirohi, Mount Abu,, Rajasthan-307510",
        "GMERS Medical College, Nr. Pathikasharam, Civil Hospital Campus, Sector-12, Gandhinagar, Gujarat-382012",
        "GMERS Medical college, (formerly known as General Hospital) Morbi Gandhi Choke, Near Town Hall, Morbi, Gujarat-363641",
        "GMERS Medical College and General Hospital , Near Government Polytechnic Hadiyol-Gadhoda Road Himmatnagar , Gujarat-383001",
        "GMERS Medical College and General Hospital, Kharavad Medan Sipor Road Ta :- Vadnagar Dist :- Mehsana, Gujarat-384355",
        "GMERS Medical College and General Hospital, Gotri road, Vadodara, Gujarat-390021",
        "GMERS Medical College and Hospital, Unjha-Patan Highway, Near Dharpur Village, District: Patan, Gujarat-384265",
        "GMERS Medical College and Hospital, Nanakwada, Valsad , Gujarat-396001",
        "GMERS Medical College and Hospital, Sola, Ahmedabad, Gujarat-380060",
        "GMERS Medical College and Hospital, Behind GMERS Medical College Majevdi Gate Junagadh, Gujarat-362001",
        "GMERS Medical College and Hospital, Porbandar, (Formerly known as Bhavsinhji and Maharani Rupaliba General Hospital) S T Bus Station Road, Porbandar, Gujarat-360575",
        "GMR Varalakshmi Care Hospital, Gmr Nagar Rajam Srikakulam, Andhra Pradesh-532127",
        "GNRC Hospital (A Unit of GNRC Ltd), DISPUR, GUWAHATI, Assam-781006",
        "Gopala Gowda Shanthaveri Memorial Hospital, T. Narasipur Road, Nazarbad, Mysore, Karnataka-570010",
        "Goutami Eye Institute, 1, R V Nagar, Korukonda Road, Rajahmundry Andhra Pradesh , Andhra Pradesh-533105",
        "Goverment District Hospital Bundi, Goverment District Hospital In Front Of Bus Stand Bundi Rajasthan , Rajasthan-323001",
        "Government Area Hospital, Narsipatnam, Beside Police Station, Chintapalli Road, Narsipatnam, Visakhapatnam District, Andhra Pradesh Andhra Pradesh , Andhra Pradesh-531116",
        "Government District Hospital, Anakapalli APVVP Pool Baghroad, Near Fruit Market, Anakapalli, Visakhapatnam District, Andhra Pradesh-531001",
        "Government District Hospital, Ayikkara, Near St. Angelos Fort, Kannur, Kerala-670017",
        "Government District Hospital, Chenchupet, Tenali Guntur District,, Andhra Pradesh-522202",
        "Government District Hospital, Shopian Kashmir , Jammu and Kashmir-192303",
        "Government General Hospital, (formerly known as Government District General Hospital) Cantonment, Vizianagaram, Andhra Pradesh-535003",
        "Government General Hospital, (formerly known as Government District General Hospital) Noonepalle, Nandyal, Kurnool District, Andhra Pradesh-518502",
        "Government General Hospital, Kunnukuzhi PO, Thycud, THIRUVANANTHAPURAM, Kerala-35",
        "Government General Hospital , Beach (PO), Kozhikode Kerala , Kerala-673032",
        "Government General Hospital, Sawai Madhopur Alanpur Link Road Sawai Madhopur Rajasthan , Rajasthan-322001",
        "Government General Hospital, (formerly known as Government District General Hospital) Near Central Jail, Rajamahendravaram, East Godavari District, Andhra Pradesh-533105",
        "Government General Hospital, (formerly known as Government District General Hospital) Ramachandraraopet, Eluru, West Godavari District, Andhra Pradesh-534006",
        "Government General Hospital, Ernakulam Road, Cochin, Kerala-682011",
        "Government Head Quarters Hospital, No. 5, Railway Road, Kancheepuram , Tamil Nadu-631501",
        "Government Head Quarters Hospital, Kovilpatti, Thoothukudi District, , Tamil Nadu-628501",
        "Government Head Quarters Hospital, Karaikudi, Sivaganga Railway Feeder Road, Karaikudi, Tamil Nadu-630001",
        "Government Head Quarters Hospital, Nellikuppam Main Road, Cuddalore , Tamil Nadu-607001",
        "Government Head Quarters Hospital , huraiyur Main Road, Perambalur, Tamil Nadu-621212",
        "Government Head Quarters Hospital, EVN Road, Erode, Tamil Nadu-638009",
        "Government Headquarters Hospital, Udumalai road, Pollachi, Tamil Nadu-642001",
        "Government Headquarters Hospital padmanabhapuram, Nagercoil trivandrum Highway Thuckalay , Tamil Nadu-629175",
        "Government Hospital, Near Tilak Nagar Bus Stand, Barmer, Rajasthan-344001",
        "Government Hospital, Gandhi Nagar Jammu, Jammu and Kashmir-180004",
        "Government Institute of Medical Sciences, GREATER NOIDA, GAUTAMBUDDHA NAGAR, Uttar Pradesh-201310",
        "Government Kamaraj Hospital, SP Koil Street Chidambaram Cuddalore (DT) , Tamil Nadu-608001",
        "Government Medical College, Karan- Nagar, Srinagar, Jammu and Kashmir-190010",
        "Government Medical College, Civil Hospital Campus, Sindhudurg Nagari, Oros, Tal.- Kudal, Dist. Sindhudurg, Maharashtra-416812",
        "Government Medical College, Tires, Kannauj, Uttar Pradesh-209732",
        "Government Medical College, (formerly known as District Hospital) Sallian Talab Dhar Road Udhampur , Jammu and Kashmir-182101",
        "GOVERNMENT MEDICAL COLLEGE, GONDIA GMC,GONDIA, KTS HOSPITAL COMPOUND, NEHRU SQUARE, GONDIA, Maharashtra-441601",
        "Government Medical College, Gunera, Wazidpur, Ujhani Road, Badaun, Uttar Pradesh-243601",
        "Government Medical College Alibag Raigad District Hospital, Raigad Alibag Government Medical College Alibag Raigad, District Hospital Raigad Alibag, Limayewadi, Alibag Beach road, District- Raigad, Maharashtra-402201",
        "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital, New Hospital Chauraha, Dungarpur, Rajasthan., Rajasthan-314001",
        "Government Medical College and Associated Hospital, Janglat Mandi, Anantnag, Jammu and Kashmir-192101",
        "Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131",
        "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401",
        "Government Medical College and Government General Hospital, (Formerly known as District Head Quarters Hospital) Main Road, Sangareddy District, Telangana-502001",
        "Government Medical College and Hospital, sadar bazar camp, Satara, Maharashtra-415001",
        "Government Medical College and Hospital, Nandurbar Civil hospital campus, Sakri Road, Nandurbar, Maharashtra-425412",
        "Government Medical college and New Civil Hospital, Surat, Medical college campus, Near majuragate.,Surat, Gujarat-395001",
        "Government Medical College and Super Facility Hospital, Chakrapanpur, Azamgarh, Vill- Chakrapanpur, Post-Kanaila, Distt.-Azamgarh, Uttar Pradesh-276128",
        "GOVERNMENT MEDICAL COLLEGE OSMANABAD, OSMANABAD, Maharashtra-413501",
        "Government Mental Health Centre, Kuthiravattom Kozhikode, Kerala-673016",
        "Government Multi Specialty Hospital, Sector-16,, Chandigarh-160016",
        "Government Periyar Hospital, G. H. Road, Mayiladuthurai, Nagapattinam District , Tamil Nadu-609001",
        "Govt Amrit Kaur Hospital, Beawar, District Ajmer, Rajasthan-305901",
        "Govt. BDK hospital, govt. Bdk hospital road no 1 opp. Roadways bus stand maan nagar jhunjhunu Rajasthan , Rajasthan-333001",
        "Govt. DB General District Hospital, Churu Naya Bass, Churu, Rajasthan , Rajasthan-331001",
        "Govt. District Civil Hospital, Suratgarh Road Sri Ganganagar Rajasthan Rajasthan , Rajasthan-335001",
        "Govt. District Hospital, Kekri Ajmer Road Kekri District Ajmer Rajasthan Rajasthan , Rajasthan-305404",
        "Govt. District Saadat Hospital, Tonk near patel circle college road tonk Rajasthan , Rajasthan-304001",
        "Govt. Mahatma Gandhi District Hospital, Collector-Ate Choraha , Near Mohan Colony Banswara Rajasthan, Rajasthan-327001",
        "Govt. Medical College, NH21, Village Rampura , Bharatpur Rajasthan , Rajasthan-321001",
        "Govt. Medical College, B-5, Medical Enclave, Jammu, Jammu and Kashmir-180001",
        "Govt. Medical College and hospital, Near Ashok Watika, Collector Office Road, Akola, Maharashtra-444001",
        "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences), Milap Nagar, Tonk Road, Jaipur,, Rajasthan-302018",
        "Govt. Seth Govind Das (Victoria) District Hospital , Omti, Jabalpur, Madhya Pradesh-482002",
        "Gujarat Institute of Mental Health Hospital, For Mental Health O/S Delhi Gate, Shahibag Ahmedabad, Gujarat-380004",
        "Gujarat MRI Centre, Samved Hospital, Nr.- Sopan Flats, Stadium-Commerce College Road, Navarangpura, Ahmedabad, Gujarat-380009",
        "Gunam Super Speciality Hospital, #35, Tank street, (Survey No: 120/2B2), Opp to Govt Hospital, Denkanikottai Road Hosur635109 Krishnagiri (Dt), Tamil Nadu, India. Tamil Nadu , Tamil Nadu-635109",
        "Guru Gobind Singh Govt. Hospital, , GNCT of Delhi Raghubir Nagar , Delhi-110027",
        "Guru Gobind Singh Medical College and Hospital, Sadiq Road, Faridkot, Punjab-151203",
        "Guru Hasti Chikitsalya (A unit of Shree PCB Trust), Mundra circle, Sindhipura, Pipar city, Rajasthan-342601",
        "GURU HOSPITAL, 4/120F PANDIKOVIL RING ROAD MADURAI, Tamil Nadu-625107",
        "Guru Teg Bahadur Hospital, , Dilshad Garden,, Delhi-110095",
        "GURUKRIPA HOSPITALS , JYOTI NAGAR, PIPRALI RAOD, SIKAR , Rajasthan-332001",
        "Habra State General Hospital, Habra, North 24 Parganas West Bengal , West Bengal-743263",
        "HAJI ABDUL MAJID MEMORIAL HOSPITAL and RESEARCH CENTER, MAIN ROAD ,HOJAI, PUB DHANIRAM PATHAR Assam , Assam-782435",
        "Haldia Sub-Divisional Hospital, Basudevpur, Haldia, Purba Medinipur, West Bengal-721602",
        "Hannah Joseph Hospital, R.S. No: 115/3B2 and 116/1A2, Madurai Tuticorin Ring Road, Near Chinthamani Tollgate, Chinthamani, Madurai 625009 Tamil Nadu , Tamil Nadu-625009",
        "Haria L G Rotary Hospital, Plot Number 363/1 and 364, Housing Sector, GIDC, Vapi, Gujarat-396195",
        "Hartej Hospital (Hartej Nursing Home), A-290-192, Ranjit Avenue, Amritsar, Punjab-143001",
        "Hayat Hospital, Kahilipara Road, Odalbakra, Lalganesh, Guwahati, Assam-781034",
        "HCG Cancer Centre, Holy cross road, IC colony, off Borivali-Dahisar link road, Borivali West, Mumbai, Maharashtra-400092",
        "HCG Cancer Centre (HCG Oncology LLP), 30 Mtrs Sunpharma Road, Opposite Satsang Party Plot, Vadodara,, Gujarat-390012",
        "HCG Cancer Centre,, Sola Science City Road, Nr. Sola Bridge, S G Highway, Ahmedabad - 380006, Gujarat-380006",
        "HCG Hospital, ( A unit of health care global Enterprises LTD HCG Hospital ( A unit of health care global Enterprises LTD ,Sector 5 Shipra Path Mansarovar , Jaipur Rajasthan , Rajasthan-302020",
        "HCG Hospital,, 1, Maharastra Society, Mithakhali, Ellisbridge Ahmedabad , Gujarat-380006",
        "HCG Manavata Cancer Centre, A Unit of HCG Manavata Oncology LLP, Near Mylan Circle, Mumbai Naka, Nashik, Maharashtra-422002",
        "HCG Panda Cancer Hospital, N H - 16, Telengapitha, District - Cuttack, Odisha-754001",
        "HEALTH CITY HOSPITAL , (A UNIT OF NORTH EAST PRIVATE LIMITED) NH - 37, UDAYNAGAR, KAINADHARA, KHANAPARA, GUWAHATI , Assam-781022",
        "HealthCare Global Specialty Hospital, (Formerly Bangalore Institute of Oncology) Deans Office #8, P. Kalinga Rao Road, Sampangiram Nagar, Bangalore, Karnataka-560027",
        "Healthway Hospital, Plot no.132/1 (Part), Ella Village, Kadamba Plateau, Tiswadi Taluka, Old Goa- , Goa-403402",
        "Healthworld Hospital, C-49, Commercial Area,Gandhi More ,City Centre, Durgapur, West Bengal-713216",
        "Heart and General Hospital, 7, Vivekanand Marg, C- Scheme, Jaipur , Rajasthan-302001",
        "Heritage Hospital Pvt. Ltd., Lanka, VARANASI, Uttar Pradesh-221005",
        "HINDU MISSION HOSPITAL, 103, GST ROAD TAMBARAM WEST, Tamil Nadu-600045",
        "Hindu Rao Hospital, Subzi Mandi, Malkaganj, Delhi-110007",
        "Hindustan Aeronautics Hospital, Vimanapura PO, Bangalore, Karnataka-560017",
        "Hindusthan Hospital, 522/3, 523/3, Navaindia Road, Udaiyampalayam, Coimbatore Tamil Nadu , Tamil Nadu-641028",
        "Hiranandani Hospital, Vashi. A Fortis Network Hospital. Mini Seashore Road, Sector 10-A, Vashi, Navi - Mumbai, Maharashtra-400703",
        "Holy Cross Hospital, Kottiyam, KOLLAM, Kerala-691571",
        "Holy Family Hospital, Okhla road New Delhi, Delhi-110025",
        "Holy Family Hospital, St. Andrew`s Road, Bandra West, Mumbai, Maharashtra-400050",
        "Holy Heart Super Speciality and Trauma Centre, 330, Vinay Nagar, Delhi Bypass Chowk, Rohtak, Haryana-124001",
        "Holy Spirit Hospital, Mahakali Road, Andheri (East), Mumbai, Maharashtra-400093",
        "Homi Bhabha Cancer Hospital, Old Loco Colony, Ghanti Mill Road, Shivpurwa, Lahartara, Varanasi, Uttar Pradesh-221002",
        "Homi Bhabha Cancer Hospital and Research Centre, Aganampudi Village, Gajuwaka Mandal Visakhapatnam, Andhra Pradesh-530053",
        "HOSMAT Hospital, 45 Magrath Road, Off Richmond Road, Bangalore, Karnataka-560025",
        "Hospital for Mental Health, Near Jeevan Bharti School, Karelibaugh, Vadodara, Gujarat-390018",
        "Human care Medical Charitable Trust, Sector-06, Dwarka, Delhi-110075",
        "ICare Eye Hospital and Post Graduate Institute, E3A Sector 26, NOIDA, Uttar Pradesh-201301",
        "ICON HOSPITAL, NH-1, SECTOR-F, SAHARA STATE ROAD, JANKIPURAM, LUCKNOW , Uttar Pradesh-226021",
        "IGM Hospital , Agartala, West Tripura , Tripura-799001",
        "ILS Hospital, DD-6, Salt Lake City, (Near City Centre), Kolkata, West Bengal-700064",
        "Imambara District Hospital, Akhan Bazar Road, Hooghly, West Bengal-712101",
        "Indian Institute Of Liver and Digestive Sciences, Sitala(east), Malipukuria, jagadispur, Sonarpur, South 24 Parganas. , West Bengal-700150",
        "Indian Spinal Injuries Centre, Sector C, Vasant Kunj, , Delhi-110070",
        "Indiana hospital and Heart Institute, Near Mahaveer Circle, Pumpwell, Kankanady, Manglore, Karnataka-575002",
        "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, Rue Victor Simonal Street, PONDICHERRY, Puducherry-605001",
        "Indira Gandhi Institute of Cardiology, PMCH Campus, Ashok Raj Path, Patna, Bihar-800004",
        "Indira Gandhi Institute of Medical Sciences, Sheikhpura, PATNA, Bihar-14",
        "Indira Gandhi Medical College and Research Institute, Vazhudavur Road, Kathirkamam Puducherry 9 Pondicherry , Puducherry-605009",
        "Indira IVF Hospital Private Limited, 44, Amar Niwas, kumharon ka Bhatta, Rajasthan , Rajasthan-313001",
        "INDIRA IVF HOSPITAL PVT LTD, 2nd Floor (203-207) and 3rd Floor (301), Anand Emralad, Sakorenagar, Near Symbiosis college, Near Symbiosis College, New Airport Road, Vimannagar, Pune , Maharashtra-411014",
        "INDLAS Hospitals, (Formerly Indlas Vijaywada Institute of Mental Health and Neuro Sciences) V.V. Rao Street, Suryaraopet, Vijayawada, Andhra Pradesh-520002",
        "Indo American Hospital, Brain and Spine Centre, Chemmanakary, Near Vaikom, Kerala-686143",
        "Indo-American Cancer Institute and Research Centre, Road No.14, Banjara Hills, Hyderabad, Telangana-500034",
        "Indraprastha Apollo Hospital, Delhi-Mathura Road, Sarita Vihar, New Delhi, Delhi-110076",
        "Indus International Hospital, Near Jawaharpur, Chandigarh-Ambala Road, National Highway no. 22, Derabassi, Mohali, Punjab-140507",
        "INFECTIOUS DISEASES AND BELIAGHATA GENERAL HOSPITAL, KOLKATA 57 BELIAGHATA MAIN ROAD, KOLKATA, West Bengal-700010",
        "INHS Kalyani, Gandhigram, VISAKHAPATNAM, Andhra Pradesh-530005",
        "Institute of Child Health, 11 Dr. Biresh Guha Street, Kolkata, West Bengal-700017",
        "Institute of Child Health and Hospital for Children, Tamil Salai, Egmore Chennai, Tamil Nadu-600008",
        "Institute of Gastroenterology Sciences and Organ Transplant, Old Nursing College Building, Victoria Hospital Campus, Bengaluru Karnataka , Karnataka-560002",
        "Institute of Liver and Biliary Sciences, D-1 Vasant Kunj, Delhi-110070",
        "Institute of Mental Health, Govt. Mental Hospital, Amritsar Circular Road, Amritsar, Punjab-143001",
        "Institute of Neurosciences, Nr. Valeentino Business Hub, Opp.Khatodra BRTS Bus Stand,Surat, Gujarat-395001",
        "Institute of Neurosciences, 185/1, A J C Bose Road, Kolkata, West Bengal-700017",
        "Institute of Reproductive Medicine, HB-36/A/3, Sector III, Salt Lake City, Kolkata, West Bengal-700106",
        "Institute of Urology, Sakri Road, Dhule , Maharashtra-424001",
        "IQRAA HOSPITAL , KOTTAKUNNU ROAD SULTHAN BATHERY WAYANAD, Kerala-673592",
        "IQRAA International Hospital and Research Centre, Malaparamba, Calicut, Kerala-673009",
        "Islampur SDH/ ISSH, Islampur,PO and PS.Islampur,Sukantapally,Uttar Dinajpur,WB West Bengal , West Bengal-733202",
        "Ispat General Hospital, IGH, Sector-19,, Odisha-769005",
        "Ivy Health and Life Sciences Pvt. Ltd. , (Ivy Hospital Amritsar) Airport Road, Village Heir, Amritsar Punjab , Punjab-143001",
        "J.L.N. Main Hospital and Research Centre, Bhilai Steel Plant, Sector-9, BHILAI, Chhattisgarh-490009",
        "J.P.M. Rotary Eye Hospital and Research Institute, C.D.A. Sector-VI, Bidanasi, Cuttack, Odisha-753002",
        "Jabalpur Hospital and Research Centre, Russel Crossing, Jabalpur, Madhya Pradesh-482002",
        "JAG PRAVESH CHANDRA HOSPITAL, PANDUK SHILA MARG, NEAR NORTHERN ENGINEERING COLLEGE, SHASTRI PARK, SHAHDARA, Delhi-110053",
        "Jagjivan Ram Railway Hospital, Maratha Mandir Road, Mumbai Central, Mumbai, Maharashtra-400008",
        "Jai Prakash District Government Hospital, 1250 Quarters, Tulsi Nagar, Bhopal, Madhya Pradesh-462003",
        "Jaiprakash Hospital and Research Centre Pvt. Ltd, Dayanand Nagar, Dandiapali, Rourkela, Odisha, Odisha-769004",
        "Jaipur Golden Hospital, 2, Institutional Area, Sector 3, Rohini, New Delhi, Delhi-110085",
        "Jalpaiguri Government Medical College and Hospital, (Formerly Jalpaiguri District Hospital), Jalpaiguri,, West Bengal-735101",
        "Jamnabai General Hospital, Mandvi-Panigate Road, Opposite SBI Bank Main Branch, Vadodara, Gujarat-390017",
        "Jan Swasthya Sahyog,, Vill. and PO Ganiyari District Bilaspur,, Chhattisgarh-495112",
        "Janakpuri Super Speciality Hospital Society (An Autonomous Institute) Under Govt. of NCT of Delhi, C-2B, Janakpuri, West Delhi,, Delhi-110058",
        "Jangipur S. D. Hospital, PO+PS- Raghunathganj, Murshidabad , West Bengal-742225",
        "Jaslok Hospital and Research Centre, 15, Dr. Gopalrao Deshmukh Marg, Mumbai, Maharashtra-400026",
        "Jawahar Lal Nehru Cancer Hospital and Res. Centre, Idgah Hills, Bhopal, Madhya Pradesh-462001",
        "Jawaharlal Nehru Medical College and Hospital, Medical road, Aligarh Muslim University, Aligarh, Uttar Pradesh-202002",
        "Jaypee Hospital, Sec-128, Noida District Gautam Buddh Nagar, Uttar Pradesh-201304",
        "Jayprabha Medanta Superspeciality Hospital, Kankarbagh Main Road Kankarbagh Colony Patna, Bihar-800020",
        "JBMM District Hospital , Ram Bagh Phatak, Nandan Cinema Chowk Amritsar, Punjab-143001",
        "Jeevan Rekha Critical Care and Trauma Hospital Research and Training Institute, Jeevan Rekha Marg, Central Spine, Mahal Yojna, Jagatpura, Jaipur, Rajasthan-302025",
        "Jehangir Hospital, 32, Sassoon Road, Pune , Maharashtra-411001",
        "JLN Government Hospital, Nagaur Bikaner Road, Nagaur Rajasthan , Rajasthan-341001",
        "JLNM Hospital, Rainawari Srinagar, Jammu and Kashmir-190001",
        "Johal Multispeciality Hospital,, Hoshiarpur Road, Rama Mandi, Jalandhar,, Punjab-144007",
        "JOSHI HOSPITAL, Kapurthala Chowk, Jalandhar , Punjab-144001",
        "Jothi Eye Care Centre, No.152 and 154, Calve Subraya Chetty Street, Puducherry, Puducherry-605001",
        "Jubilee Mission Hospital, P B No- 737, Bishop Alapatt Road, Jubilee Mission P O, THRISSUR, Kerala-680005",
        "Jupiter Hospital, Eastern Express Highway, Thane (West), Maharashtra-400601",
        "Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharashtra-411045",
        "JYOTHI HOSPITAL, 19-1350, MIRYALAGUDA, NALGONDA DISTRICT, , Telangana-508207",
        "K B Bhabha Municipal General Hospital, Bandra (West), Mumbai, Maharashtra-50",
        "K C General Hospital, #89 5th Cross Malleshwaram, Bengaluru, Karnataka-560003",
        "K K Hospital, 87/88, Nabibullah RD, River Bank Colony, Opp. SSP Office, Daliganj, Lucknow, Uttar Pradesh-226007",
        "K S HOSPITAL, 9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD, KOPPAL Karnataka , Karnataka-583231",
        "K.E.M. Hospital, 489, Rasta Peth, Sardar Moodliar Road, Pune, Maharashtra-411011",
        "K.G. Hospital and PG Medical Institute , No.5, Arts College Rd, COIMBATORE, Tamil Nadu-641018",
        "K.G. Patel Children Hospital and Jayodia Research Institute,, Jalaram Marg, Kareli Baug, BARODA,, Gujarat-390018",
        "K.J. Somaiya Medical College, Eastern Highway, Sion, Mumbai, Maharashtra-400022",
        "Kabra Eye Hospital, C-59,60,Jamna Nagar Sodala Jaipur Raj Rajasthan , Rajasthan-302019",
        "Kailash Cancer Hospital and Research Centre, Muni Seva Ashram, At- Goraj, Ta.- Vaghodia, Dist- Vadodara- , Gujarat-391760",
        "Kailash Hospital and Heart Institute, H-33 Sector 27, Noida, Uttar Pradesh-201301",
        "Kailash hospital neuro institute, Kailash hospital Neuro institute NH-1 ,SECTOR 71 NOIDA ,UP 201309 , Uttar Pradesh-201309",
        "Kainos Super Speciality Hospital , Kheri Sadh Bypass, Delhi Road, Rohtak,, Haryana-124021",
        "KAKDWIP SDH and SSH , VILL - SUBHASNAGAR, PO - KAKDWIP, PS - HARWOOD POINT COASTAL, DISTRICT - DIAMOND HARBOUR HEALTH DISTRICT (SOUTH 24 PARGANAS), West Bengal-743347",
        "Kalda burn and plastic surgery centre, Near Colors Mall, Pachpedi naka, Raipur, Chhattisgarh-492001",
        "Kalinga Hospital, Bhubaneswar, Odisha-751023",
        "KALNA SD and SS HOSPITAL, S.T.K.K. ROAD, KALNA, PURBA BARDHAMAN, West Bengal-713409",
        "Kalpana Chawla Govt Medical College, Model Town Karnal, Haryana-132001",
        "Kalra Hospital and Shri Ram Cardiothoracic and Neuro-Sciences Centre, , A-5 and 6, Kirti Nagar, , Delhi-110015",
        "Kalyani Institute of Medical Sciences, Alisha, East Burdwan, Beside National Highway , West Bengal-713103",
        "Kalyani Kidney Care Centre, 104, Sampath Nagar Main Road Erode, Tamil Nadu-638011",
        "KAMAKSHI HOSPITAL, KUVEMPU NAGAR, MYSURU , Karnataka-570009",
        "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), Gut No. 43, Beed Bypass Road, Satara Parisar, Aurangabad, Maharashtra-431005",
        "Kamineni Health Care, 100 ft Road, Poranki, Vijaywada, Andhra Pradesh-521137",
        "Kamineni Hospital, L. B. Nagar, Hyderabad, Telangana-500068",
        "Kamineni Hospital , King Koti, Hyderabad , Telangana-500",
        "KAMLA NAGAR HOSPITAL, (A UNIT OF K N HOSPITAL PRIVATE LIMITED) PLOT A, 2-3 MARUDHAR NAGAR, PAL LINK ROAD, JODHPUR, Rajasthan-342008",
        "Kamla Nehru Memorial Hospital, 1, Hashimpur Road, Allahabad, Uttar Pradesh-211002",
        "Kanchi Kamakoti Childs Trust Hospital, 12A, Nageswara Road, Nungambakkam, CHENNAI, Tamil Nadu-34",
        "Kandi Sub Divisional Hospital, Murshidabad Po+Ps-Kandi, Murshidabad, West Bengal, West Bengal-742137",
        "Kanumuri Hospital, D.No-12 Sanakkayala Factory Road Kothapet, Guntur, Andhra Pradesh-522001",
        "Kanyakumari Medical Mission, C.S.I. Mission Hospital, Neyyoor, Kanyakumari-District, Tamil Nadu-629802",
        "Kashyap Memorial eye hospital Pvt. Ltd., Purulia Road, Ranchi Jharkhand , Jharkhand-834001",
        "Kasturba Medical College Hospital (KMC Hospital), Dr. B R Ambedkar Circle, Jyothi Balmatta Road, Mangalore, Karnataka-575001",
        "Katwa Sub Divisional Hospital, Kachari Road, Katwa, Purba Bardhaman, West Bengal , West Bengal-713130",
        "KAUSHALYA DEVI EYE INSTITUE, MOH NAI BASTI, NEAR CHHATRI CHAURAHA, Uttar Pradesh-262001",
        "Kaushalya Medical Foundation, Ganeshwadi, Panchpakhadi, Behind Nitin Company, Thane (West), Maharashtra-400601",
        "Kauvery Hospital, No:9/50, Trichy Main Road Seelanaickenpatti, Salem, , Tamil Nadu-636201",
        "Kauvery Hospital, No. 199, Luz Church Road, Mylapore, Chennai, Tamil Nadu-600004",
        "KAUVERY HOSPITAL, (BENGALURU) PVT. LTD. 92/1A, Konappana Agrahara, Electronic City, Bengaluru South , Karnataka-560100",
        "Kauvery Hospital Heart City, Old No. 12, New No.52 Alexandria Road Cantonment Trichy, Tamil Nadu-620001",
        "Kauvery Hospital Medical Services Pvt Ltd, No-35, Shanthi nagar, Opp to CSI Church,Hosur, Krishnagiri Tamil Nadu , Tamil Nadu-635109",
        "Kaveri Medical Centre and Hospital, No. 1 K C Road, Tennur, Trichy , Tamil Nadu-620017",
        "KD Hospital, Vaishnodevicircle, SG Road, Ahmedabad, Gujarat-382421",
        "Kerala Institute of Medical Sciences, P B No.1, Anayara P O, Trivandrum, Kerala-695029",
        "Kerudi Hospital and Research Centre, opp. Axis Bank, Extension Area, Bagalkot, Karnataka-587101",
        "Keshava Netralaya, Harshamahal Road, Hassan Karnataka , Karnataka-573201",
        "Khan Bahadur Bhabha Hospital, Belgrami Road, Opp. goal Building Near Kurla Police Station, Old L B S Road, Kurla Mumbai, Maharashtra-400070",
        "Kharagpur Sub-Divisional Hospital , At-Chotatengra, P.O.- Kharagpur, P.S.- Kharagpur Town, Dist.- Paschim Medinipur , West Bengal-721301",
        "KHATRA SUB DIVISINAL HOSPITAL SUB DIVISIONAL HOSPITAL , KHATRA, West Bengal-722140",
        "KIMS - Kingsway Hospitals, (formerly knowns as Kingsway Hospitals) (A Unit of SPANV Medisearch Lifesciences Pvt Ltd) Kingsway Hospitals 44, Kingsway Road, Near Kasturchand Park, Nagpur. , Maharashtra-440001",
        "KIMS Al Shifa Hospital, PO Box No.26, Ooty Rd, Perintalmanna, MALAPURAM, Kerala-679322",
        "KIMS Hospital, # 1-112/86, Survey No 55/ EE, Kondapur Village, Serilingampally Mandal, Hyderabad, Telangana-500084",
        "KIMS Hospital Kurnool, (Formerly Known as KIMS Hospital) (A unit of KIMS Hospital Kurnool Private Limited) D.NO-69/213-k-9, Joharapuram Road, Joharapuram, Kurnool, Andhra Pradesh-518002",
        "KIMS Icon Hospital, (A Unit of Iconkrishi Institute of Medical Sciences Pvt.Ltd) 32-11-02, BHPV Post, Sheelanagar, Visakhapatnam, Andhra Pradesh-53001",
        "KIMS Saveera Hospital, #1-1348,Srinagar Colony Extention,Opp Sakshi Office,Anantapuram, Andhra Pradesh-515004",
        "KIMS SunShine Hospital (Formerly known as SunShine Hospital), Begumpet, Metro Pillar No.C1327, Hyderabad, Telangana-500016",
        "KJK Hospital Fertility Research and Gynaec Centre, Shaw Wallace Lane, Near Mar Ivanios College, Nalanchira, Thiruvananthapuram , Kerala-695015",
        "KLE Centenary Charitable Hospital and MRC, Near Annpurneshwari Temple, Yellur Road Vadgaon, Belagavi, Karnataka-590005",
        "KMC Hospital and Research Centre, 187, Baghpat Road, Meerut , Uttar Pradesh-250002",
        "KMC Specialty Hospital, No. 6, Royal Road, Cantonment, Trichy, Tamil Nadu-620001",
        "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute, Achyutrao Patwardhan Marg, 4 Bunglows, Andheri (W), Mumbai, Maharashtra-400053",
        "Kolhapur Cancer Centre Pvt. Ltd., RS No 238, Opp. Orange Dhaba, Gokul Shirgaon, Kolhapur, Maharashtra-416234",
        "Kongunad Hospitals Private Limited, 116,117, 11th Street, Tatabad, Coimbatore, Tamil Nadu-641012",
        "Kothari Medical and Research Institute, Bangla Nagar, NH15, Jaiselmer Road, Bikaner-, Rajasthan-334701",
        "Kothari Medical Centre, 8/3 Alipore Road, KOLKATA, West Bengal-27",
        "Kovai Medical Centre, Post Box No. 3209, Avinashi Road, Civil Arodrom Post, COIMBATORE, Tamil Nadu-641014",
        "Kozhikode District Co-Operative Hospital, Eranhipalam, Kerala-673006",
        "Krishna Hospital, 377, Main Road, Sattur, Virudhunagar District Tamil Nadu , Tamil Nadu-626203",
        "Krishna Institute of Medical Sciences, 77-7-7, Seelam Nukaraju Complex Road, Katari Gadens, Rajahmundry, Andhra Pradesh-533103",
        "Krishna Institute of Medical Sciences, 1-8-31/1, Minister Road, Secunderabad, Telangana-500003",
        "Krishna Institute of Medical Sciences Ltd, Dargamitta, Nellore , Andhra Pradesh-524003",
        "Krishna Maternity Home and Pediatric Centre, Plot No.4, North High Ground Road, Palayamkottai, Tamil Nadu-627002",
        "Krishna Shalby Hospital, 319, Greencity, Ghuma, Ahmedabad, Gujarat-380058",
        "Krishnakumar Orthopedic Hospital, K.K.Nagar, Chunkankadai Post, NAGERCOIL, Tamil Nadu-629003",
        "Kulkarni Uro-Surgery Institute Private Limited, Plot No: 61/2/1, 11/1, Mumbai Pune Bypass Rd, Pune, Maharashtra-411045",
        "Kurji Holy Family Hospital, PO Sadaquat Ashram, PATNA, Bihar-800010",
        "Kushabhau Thakre District Hospital, Govindgarh road, Bichhiya ,Rewa (M.P.) , Madhya Pradesh-486001",
        "KVR Hospital, Near Reliance Petrol Pump, Server Khera, Moradabad Road, Kashipur Udham Singh Nagar, Uttarakhand-244713",
        "Laddhad Hospital, Wankhede Layout, Buldana, Maharashtra-443001",
        "LADY HARDINGE MEDICAL COLLEGE, SHAHEED BHAGAT SINGH MARG, Delhi-110001",
        "Lakeshore Hospital and Research Centre Ltd., Maradu, Nettoor P.O. Kochi, Ernakulam, Kerala, Kerala-682040",
        "Lakshmi Hospital, Diwans Road, Cochin, Kerala-682016",
        "Lakshmi Madhavan Hospital, No:4A, South Bye Pass Road, Near BSNL, Vannarpettai, Tirunelveli, Tamil Nadu-627003",
        "LAL BAHADUR SHASTRI HOSPITAL, RAMNAGAR VARANASI, Uttar Pradesh-221008",
        "Lal Bahadur Shastri Hosptial, Near Kalyanvas Colony, Mayur Vihar Phase II, Delhi-110091",
        "Lala Harbhagwan Das Memorial and Dr Prem Hospital, Han Saroop Colony, Opp Bust Stand, Panipat, Haryana-132103",
        "Lalbagh Sub Division Hospital, Golapbagh, Lalbagh, PO and PS- Murshidabad District- Murshidabad West Bengal, West Bengal-742149",
        "Lalitha Super Specialty Hospital, Kothapet, Guntur, Andhra Pradesh-522001",
        "Latha Super Specialty Hospital, # 29-14-58, PRAKASAM ROAD, SURYARAOPET, VIJAYAWADA, Andhra Pradesh-520002",
        "Laxmi Charitable Trust Eye Hospital, Uran Road, Panvel, Maharashtra-410206",
        "Laxmi Narasimha Hospital, H. No. 2-2-316, Beside Pushpanjali Function Hall, Nayeem Nagar, Hanamkonda Warangal, Telangana-506001",
        "Life Line Hospital and Research Centre, 496, Vivekanand Marg, Madya, Azamgarh,, Uttar Pradesh-276001",
        "Life line Super Specialty Hospital, 14th Mile, Melood, PO- Adoor, Pathananmthitta Dist. , Kerala-691523",
        "Lilavati Hospital and Research Centre, A-791, Bandra Reclaimation, Bandra West, Mumbai, Maharashtra-400050",
        "LINK HOSPITAL, Plot No.C-1 ,Green South Avenue ,Shivpuri Link Road, Gwalior, Madhya Pradesh-474001",
        "Lions Club Sadhu Ram Eye Hospital, 1-2-8 Gagan Mahal Rd, Domalguda, Hyderabad, Telangana-29",
        "Lisie Medical Institution, P.O. Box 3053, KOCHI-18 Kerala, Kerala-682018",
        "Little Flower Hospital and Research Centre, Post Box No. 23, Angamaly, Kerala, Kerala-683572",
        "Little Stars and She Women and Children, (Formerly known as Little Stars Childrens Hospitals) 8-2-596, Ascent Towers, Road No 10, Banjara Hills, Hyderabad, Telangana-500034",
        "Livasa Hospital, Mohali, (Formerly known as Ivy Health and Life Sciences) (A unit of Ivy Health and Life Sciences Pvt Ltd.) Sector 71, SAS Nagar Mohali, Punjab-160071",
        "Lok Bandhu Raj Narayan Combined Hospital, LDA Colony Kanpur Road Lucknow Uttar Pradesh , Uttar Pradesh-226012",
        "Lok Nayak Jai Prakash (L N J P) Hospital, (Maulana Azad Medical College) J L Nehru Marg,, Delhi-110002",
        "Lokmanya Tilak Municipal Medical College and General Hospital,, Mumbai Maharashtra , Maharashtra-400022",
        "Loknayak Jaiprakash Narayan Hospital, Rajbanshinagar, Patna, Bihar-800023",
        "Lord Mahavir Civil Hospital, Old Jail Road, Ludhiana, Punjab-141001",
        "Lotus Children`s Hospital, 6-2-29, Lakdi Ka Pul, Hyderabad, Telangana-500004",
        "Lotus Eye Hospital, SF No. 770/12, Avanashi Road, Civil Aerodrome Post, Peelamedu, Combatore, Tamil Nadu-641014",
        "Lotus Hospitals and Research Centre, 90, Thayumanava Sundaram Street, Poondurai Main Road, Kollampalayam, Erode, Tamil Nadu-638002",
        "Lourdes Hospital, Pachalam, Ernakulam, Cochin, Kerala-682012",
        "LPS Institute of Cardiology and Cardio Vascular Thoracic Surgery, Near Gole Chauraha, GT Road, Rawatpur, Kanpur, Uttar Pradesh-208002",
        "M J Naidu Super Specialty Hosptial, D. No. 33-27-32, Bellapu, Sobhanadeswarao Street, Near Pushpa Hotel, Suryaraopet, Vijayawada , Andhra Pradesh-521002",
        "M R Bangur Hospital, 241, DPS Road, Tollygunge, Kolkata, West Bengal-700033",
        "M. K. HAJI ORPHANAGE HOSPITAL, (Formerly known as MKH-Crescent Paediatric And Neonatology Centre) MK HAJI ORPHANAGE HOSPITAL, TIRURANGADI, MALAPPURAM DT, Kerala-676306",
        "M. N. Eye Hospital, 781, TH Road, Opp. Thondiarpet Police Station, CHENNAI, Tamil Nadu-600021",
        "M.G.M. Muthoot Hospital, Ring Road, PATHANMTHITTA, Kerala-689645",
        "M.I.O.T. Hospital, 4/112, Mt-Poonamallee Rd, Nanapakkam, CHENNAI, Tamil Nadu-600089",
        "M.K.C.G. Medical College Hospital, Berhampur M.K.C.G. Medical College Hospital Campus, Berhampur, Ganjam, Odisha Orissa , Odisha-760004",
        "M.M. Joshi Eye Hospital, Padmanarayanalaya, Gokul Road, Hosur, HUBLI, Karnataka-580021",
        "M.R.A. Medical College, Ambedkar Nagar, U.P. Saddarpur, Ambedkar Nagar, U.P. 224227 Uttar Pradesh , Uttar Pradesh-224227",
        "MAA E.N.T HOSPITALS, MAA HOSPITALS PVT. LTD) H.NO: 8-2-293/82/A/1266, ROAD NO.36, BESIDE ICICI BANK, JUBILEE HILLS, HYDERABAD, Telangana-500033",
        "Madhukar Rainbow Childrens Hospital, FC-29,Plot No 5,Geetanjali, Near Malviya Nagar Metro Station Gate no 1, New Delhi, Delhi-110017",
        "Madras Medical College and Rajiv Gandhi Government General Hospital, E V R Periyar Road, Chennai, Tamil Nadu-600003",
        "Madras Medical Mission Hospital, 4A Dr Jayalalitha Ngr, Mogappair CHENNAI, Tamil Nadu-600037",
        "MAGNUS HOSPITAL, 24, ROOP NAGAR, BUWANA, 80FT. ROAD, MEERA NAGAR, UDAIPUR , Rajasthan-313001",
        "MAHAJAN IMAGING AND LABS, (A UNIT OF MAHAJAN IMAGING PVT LTD) C 6/8 SAFDARJUNG DEVELOPMENT AREA, Delhi-110016",
        "Mahanadi Netra Chitsalaya, Pandapali, Birmaharajpur, Dist- Subarnapur, Orissa , Odisha-767018",
        "Maharaja Agrasen Hospital, Rohtak Road, West Punjabi Bagh New Delhi, Delhi-110026",
        "Maharaja Agrasen Medical College, Nh 9, Sirsa Road, Vpo - Agroha, Distt. Hisar, Haryana-125047",
        "Maharaja Jitendra Narayan Medical College and Hosptial, (formerly known as Coochbehar Govt Medical College and Hospital) Vivekananda Street , Kharimala Khagrabari, Pilkhana, Coochbehar West Bengal , West Bengal-736101",
        "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Bahraich k. D. C. Road Bahraich , Uttar Pradesh-271801",
        "Maharana Pratap District Combined Hospital, Kutubkhan Road Bareilly, Uttar Pradesh-243003",
        "Maharani Hospital, Near Chandni Chowk, Jagdalpur, District: Bastar, Chhattisgarh-494001",
        "Maharishi Vashistha Autonomous State Medical College and Opec Hospital, Rampur , Basti, Uttar Pradesh-272002",
        "Mahathma Eye Hospital, No.6, Seshapuram, Tennur, Tiruchirappalli, Tamil Nadu-620017",
        "Mahatma Gandhi Cancer Hospital and Research Institute, Plot No. 1, Sector - 7, MVP Colony, Visakhapatnam, Andhra Pradesh-530017",
        "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001",
        "Mahatme Eye Bank and Eye Hospital, 2163 C, Chintaman Nagar, Near Rajiv Nagar, Wardha Road, Nagpur, Maharashtra-440025",
        "Mahavir Cancer Sansthan and Research Centre, Phulwari Sharif, PATNA, Bihar-801505",
        "Mahavir Hospital and Research Centre, 10-1-1, Bhagwan Mahavir Rd, A.C.Guards, Hyderabad, Telangana-500004",
        "Mahavir Hospital Manish IVF Center, Mahavir Hospital and Sai Sonography Center Opp. District Library Lati plot Gujarat , Gujarat-363001",
        "Mahavir Jaipuriya Rajasthan Hospital, Opposite Govt. Jaipuriya Hospital, JLN Marg, Jaipur Rajasthan , Rajasthan-302018",
        "MAHAVIR VATSALYA Hospital, MAHAVIR VATSALYA ADPTAL L.C.T. GHAT , PATNA -Bihar , Bihar-800001",
        "Main Hospital , The Singareni Colleries Company Limited Kothagudem, Bhadradri Kothagudem Dist,, Telangana-507101",
        "Maitri Hospital, 2A, Talwandi, IL Choraha Rajasthan , Rajasthan-324005",
        "Mal SSH, Mal Bazar, P.O.- Mal, PS- Mal, District- Jalpaiguri West Bengal , West Bengal-735221",
        "Malabar Cancer Centre, Moozhikkara P O, Kodiyeri, Thalassery, Kannur, Kerala-670103",
        "Malabar Eye Hospital, Near Eranhipalam Post Office ,Wayanad Road, Eranhipalam, Kozhikode, Kerala, Kerala-673006",
        "Malabar Hospitals Pvt. Ltd., Karuvambrum Post, Manjeri, Malappuram, Kerala-67612",
        "Malabar Institute of Medical Sciences, Kottakkal, Mallappuram District, Kerala-676503",
        "Malabar Institute of Medical Sciences, Mini Bye Pass, Govindapuram, Calicut, Kerala-673016",
        "MALABAR INSTITUTE OF MEDICAL SCIENCES Ltd , CHALA EAST, KANNUR , Kerala-670621",
        "MALANKARA MEDICAL MISSION HOSPITAL, ADUPOOTTY HILLS KUNNAMKULAM P O THRISSUR, Kerala-680503",
        "Malankara Orthodox Syrian Church Medical College Hospital, Kolenchery, ERNAKULAM, Kerala-682311",
        "Mallige Medical Centre, 31/32 Crescent Road, BANGALORE, Karnataka-1",
        "MALLIKA HOSPITALS (Formerly known as MALLIKA SPINE CENTRE), #12-12-13, OLD CLUB ROAD, KOTHAPETA GUNTUR , Andhra Pradesh-522001",
        "MANASA NURSING HOME, JPN ROAD,1 ST CROSS SHIVAMOGGA, Karnataka-577201",
        "Manik Hospital, Plot No - 148, Near Mohata Devi Mandir, Bajaj Nagar, Waluj, Aurangabad Maharashtra , Maharashtra-431136",
        "Manipal Hospital, - Sarjapur road Survey no 45/2, ward. 150, Marathahalli - Sarjapur Road, opposite Iblur, Ambalipura, Bellandur, Bengaluru, , Karnataka-560102",
        "Manipal Hospital, 22, 2A, Mundhwa - Kharadi Rd, Near Nyati Empire, Santipur, Thite Nagar, Kharadi, Pune, Maharashtra-411014",
        "Manipal Hospital, (Formerly Columbia Asia Hospital) Sy No. 10P and 12P, Ramagondanahalli Village, Varthur Hobli Whitefield, Bangalore east taluk, Karnataka-560066",
        "Manipal Hospital, Sector 5, Vidhyadhar Nagar, Sikar Road, Jaipur, Rajasthan-302013",
        "Manipal Hospital, Near Varadhi, (Near Vijayawada) Tadepalli Guntur, Andhra Pradesh-522501",
        "MANIPAL HOSPITAL, Mysore No. 85-86, Bangalore-Mysore Ring Road Junction Bannimantapa A Layout, Siddique Nagar, Mandi Mohalla, Mysuru, Karnataka-570015",
        "Manipal Hospital, Malleshwaram No: 71,11th Main, Opposite to Malleshwaram Railway Station, Malleshwaram, Bangalore , Karnataka-560003",
        "Manipal Hospital, No. 98, Rustum Bagh, Old Airport Road, Bangalore, Karnataka-560017",
        "Manipal Hospital Pvt. Ltd., (Formerly Columbia Asia Referral Hospital) 26/1, Brigade Gateway, Beside Metro station, Malleshwarm west, Bangalore, Karnataka-560055",
        "Manipal Hospital Whitefield, #143, 212-215, EPIP Industrial Area, Hoodi Village, K R Puram, Hobli Bengaluru, Karnataka-560066",
        "Manipal Hospital, Bhubaneswar, (Formerly known as Advanced Medical Research Institute, AMRI Hospital) Plot No. 1, Beside Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha-751030",
        "Manipal Hospitals (Bengaluru) Pvt. Ltd., (Formerly Vikram Hospital) #71/1, Opp. St. Annes College, Millers Road, Bengaluru Karnataka , Karnataka-560052",
        "Manipal Hospitals (East) India Private Limited, (Formerly known as AMRI Hospital) Saltlake JC 16 and 17 Saltlake Sector-3, West Bengal-700106",
        "MAR SLEEVA MEDICITY PALAI, KEZHUVANKULAM P.O. CHERPUNKAL KOTTAYAM DT. , Kerala-686584",
        "Mark Hospital, Near Geeta Marriage Hall, Chantidih Road, Sarkanda, Bilaspur Chhattisgarh , Chhattisgarh-495001",
        "Marwari Hospitals, Sati Jaymati Road, Athgaon, Guwahati, Assam Assam , Assam-781008",
        "Masai Childrens Hospital, 1837, C Ward, Lugadi Lane, Somwarpeth, Kolhapur, Maharashtra-416002",
        "Masina Hospital, Sant Savta Marg, Byculla, (East) Mumbai, Maharashtra-400027",
        "Masonic Medical Centre for Children, 232, Race Course, Coimbatore, Tamil Nadu-641018",
        "Mata Chanan Devi Hospital,, C-1 Janakpuri, , Delhi-58",
        "Mata Kaushalya Governement Hospital, Near Court Complex Lahori Gate, Patiala, Punjab-147001",
        "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), Mandir Marg, Press Enclave Marg, Saket,, Delhi-110017",
        "Max Super Speciality Hospital (A unit of Alexis Multi-Speciality Hospital Private Limited, (Formerly Known as Alexis Multispecialty Hospital) Nagpur Survey Number-232, Mankapur, Koradi Road, Nagpur, 440030, Maharashtra, India Maharashtra , Maharashtra-440030",
        "Max Super Speciality Hospital (A unit of Starlit Medical Centre Private Limited), (Formerly Known as Sahara India Medical Institute Limited) Gomti Nagar, Lucknow, Uttar Pradesh-226010",
        "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre) 108A, Opp Sanchar Apartments, IP Extension, Patparganj,New Delhi , Delhi-110092",
        "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh-201012",
        "Max Super Specialty Hospital, FC-50, C and D Block, Shalimar Bagh, New Delhi, Delhi-110088",
        "Max Super Specialty Hospital, 1,2, Press Enclave Road, Saket,, Delhi-110017",
        "Max Super Specialty Hospital, Mussoorie Diversion Road, Malsi, Dehradun, Uttarakhand-248001",
        "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. Civil Hospital Premises Phase - VI, Mohali, Punjab-160055",
        "Maxwell Super Multispeciality Hospital Pvt. Ltd., Daffi Baipass, Near Toll Tax Plaza, Newada, Varanasi, Uttar Pradesh-221011",
        "Medanta Abdur Razzaque Ansari Memorial Weavers Hospital, NH 33, IRBA, Ranchi, Jharkhand-835217",
        "Medanta Hospital, Sector A Pocket 1, Sushant Golf City, Amar Shaheed Path, Lucknow, Uttar Pradesh-226030",
        "MEDANTA SUPER SPECIALITY HOSPITAL (UNIT OF GLOBAL HEALTH LIMITED), PLOT NO 8, PU4, SCHEME NO 54, VIJAY NAGAR SQUARE A.B.ROAD, INDORE , Madhya Pradesh-452010",
        "Medanta The Medicity, Sector-38, Gurgaon, Haryana-122001",
        "Medica Superspecialty Hospital, 127 Mukundapur, E M Bypass, Kolkata, West Bengal-700099",
        "Medical College Kolkata, 88, College Street, Kolkata West Bengal, West Bengal-700073",
        "Medical Trust Hospital, M G Road, Kochi, Kerala-682016",
        "Medicare Multispeciality Hospital, Pusad Behind Ramdev Baba Petrol Pump Nagpur Road Pusad Dist-Yavatmal Maharashtra , Maharashtra-445204",
        "Medicover Hospitals, 51/1-E2, 1-E3, 1-E4, Opp to Old Eidga, Near RTC busstand, Kurnool. Andhra Pradesh , Andhra Pradesh-51800",
        "Medicover Hospitals, (Formerly MaxCure Hospital) Behind Cyber Tower, Lane next to McDonald, Hi-tech City, Madhapur, Hyderabad, Telangana-500081",
        "Medicover hospitals (A unit of sahrudaya Healthcare Pvt. Ltd), #18-1-3, KGH Down, Maharanipeta, Visakhapatnam, Andhra Pradesh-530002",
        "Medicover Hospitals MVP, 1-1-83, NH16, Beside Petrol Bunk, Sector- 6, Venkojipalem, Visakhapatnam, Andhra Pradesh-530017",
        "Medivision Eye and Health Care Centre, 10-3-304/1 to 4, Indira Sadan, Humayun Nagar, Near NMDC, Hyderabad, Telangana-500028",
        "Meenakshi Hospital, 244/2, Trichy Main Road, Near New Bus Stand Nilagiri Therkku Thottam, Tanjore, Tamil Nadu-613005",
        "Meenakshi Mission Hospital and Research Centre, Lake Area, Melur Road, MADURAI, Tamil Nadu-625107",
        "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) 2, McNichols Road, 3rd Lane, Chetpet, Chennai, Tamil Nadu-600031",
        "Meitra Hospital, KARAPARAMBA-KUNDUPARAMBA MINI BYPASS ROAD, EDAKKAD POST, CALICUT, Kerala-673005",
        "Mental Health Centre, Peroorkada P.O., Thiruvananthapuram, Kerala-695005",
        "MES Medical College and Hospital, Perinthalmanna, Palachode Post, Kolathur Via, Malappuram District, Kerala-679338",
        "Metas Adventist Hospital, P.O. Box 24, Athwalines, Surat, Gujarat-395001",
        "Metro Heart Institute with Multispecialty, Sector-16 A, Faridabad, , Haryana-121002",
        "Metro Hospital and Cancer Institute, 21-22, Community Centre, Preet Vihar, Delhi-, Delhi-110092",
        "Metro Hospital and Heart Institute, H-BLOCK, PALAM VIHAR, GURUGRAM, Haryana-122017",
        "Metro Hospital and Heart Institute Multispecialty, (Formerly known as Metro Heart Institute) L-94, Sector- 11, Noida, Uttar Pradesh-201301",
        "Metromed International Cardiac Centre, Thondayad Bypass Road, G A College P O, Calicut, Kerala-673014",
        "Metrounited Healthcare LLP, Shivamurthy Circle, Savalanga Road, Shivamogga , Karnataka-577201",
        "MGM Eye Insitute,, 5th Mile, Vidhan Sabha Road, Raipur, Chhattisgarh-493111",
        "MGM Govt District Hospital, Hanumangarh Near New Dhan Mandi Hanumangarh Town Rajasthan , Rajasthan-335513",
        "MGM Health Care Private Limited, New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai � 600029 Tamilnadu State Tamil Nadu , Tamil Nadu-600029",
        "MGM Hospital and Research Centre Pvt Ltd., Jagat Narayan Lal Rd, East Lohanipur, Kadamkuan, Patna, Bihar-800003",
        "MGM Medical College, AB Road, Indore,, Madhya Pradesh-452001",
        "MGM New Bombay Hospital, Plot 35, Sector 3, Vashi, Navi Mumbai, Maharashtra-400703",
        "Midland Healthcare and Research Centre, B-55 and C-42, Mandir Marg, Mahanagar Extention, Mahanagar, Lucknow, Uttar Pradesh , Uttar Pradesh-226006",
        "MILANN A Unit of BACC Healthcare Pvt. Ltd., (formerly known as Bangalore Assisted Conception Centre (BACC) ) No. 3 and 7, East park Road, Kumara Park East, High Grounds, Bangalore, Karnataka-560001",
        "MIMHANS NEUROSCIENCES HOSPITAL, 281,283 SECTOR-1, MANGAL PANDEY NAGAR MEERUT, Uttar Pradesh-250004",
        "Mittal Medicare and hospital Pvt.Ltd., 10, Tilak marg, Narsing Choupati Dhar, M.P., Madhya Pradesh , Madhya Pradesh-454001",
        "MMI Narayana Multispecialty Hospital, (Unit of Narayana Hrudayalaya Ltd.) Dhamtari Road, Lalpur, Chhattisgarh-492001",
        "MNJ Institute of Oncology and Cancer Regional Center, Red Hills, Lakdi Ka Oil Hyderabad, Telangana-500004",
        "Mohan Eye Institute, 11/B Ganga Ram Hospital Marg,, Delhi-60",
        "Mohandai Oswal Hospital, GT Road, Sherpur By-Pass, Ludhiana, Punjab-141009",
        "Moolchand Khairatiram Hospital and Ayurvedic Research Institute,, Lajpat Nagar-III , Delhi-24",
        "Mother Hospital, Olari (PO) Pullazhi, THRISSUR, Kerala-680012",
        "Mothers Care Children Hospital and Research Centre, Sagolband Moirang Leirak, Imphal West,, Manipur-795001",
        "Moulana Hospital, PB No. 31, Ootty Road Perinthalmanna, Malappuram Dist., Kerala-679322",
        "Muljibhai Patel Urological Hospital, Dr. Virendra Desai Rd, NADIAD, Gujarat-387001",
        "Mumbai Port Trust Hospital, Antop Village, Nadkarni Park, Wadala (East), Mumbai, Maharashtra-400037",
        "Muslim Maternity and Childrens Hospital, H. No: 16-2-326, Osmanpura, Chaderghat, Hyderabad, Telangana-500024",
        "Muthoot Health Care, College Road, Kozhencherry PO, Pathanamthitta District, Kerala-689641",
        "MVR Cancer Centre and Research Institute, CP7/504 VELLALASSERI REC (NIT) VIA CALICUT, Kerala-673601",
        "Mydhili Hospital, 14/20, ICS Road, GUDUR SPSR Nellore District, Andhra Pradesh-524101",
        "Mythri Hospital, Mehdipatnam 13-6-434/c/148, 1-5 BRK Complex, Guddimalkapur, Mehdipatnam, Hyderabad, Telangana-500008",
        "N M Virani Wockhardt Hospital, Kalawad Road, Rajkot, Gujarat-360005",
        "N S Memorial Institute of Medical Sciences , Palathara, Thattamala , Kollam , Kerala-691020",
        "N U Trust Hospital, CA-6, 15th Main, 11th Cross, Padmanabanagar, Bangalore , Karnataka-70",
        "Nabadwip State General Hospital, Pratapnagar,P.o. Nabadwip, P.S. Nabadwip, Dist: Nadia West Bengal , West Bengal-741302",
        "Naga Hospital Authority, Kohima, Nagaland-0",
        "NAIHATI STATE GENERAL HOSPITAL , NAIHATI NORTH 24 PARGANAS , West Bengal-743165",
        "Nandadeep Eye Hospital, Opposite to Patidar Bhavan, Madhav Nagar Road, Sangli , Maharashtra-416416",
        "Nanjappa Hospital, 660, Kuvampu Road, SHIMOGA, Karnataka-577201",
        "Narayan Medical College and Hospital, AT+PO Jamuhar, PS-Dehri on Sone, Dist. Rohtas, Sasaram, Bihar-821305",
        "Narayana Hrudayalaya, Monogram Mill Compound Opp. Rakhial Police Station, Rakhial, Gujarat-380023",
        "Narayana Hrudayalaya Hospital, (NH-Narayana Health City, Bangalore) #258/A, Bommasandra Industrial Area, Anekal Taluk, Bangalore, Karnataka-560099",
        "Narayana Hrudayalaya Hospital, (Formerly Sparsh Hospital) 29/P2, The Narayana Health City, Bommasandra, Hosur Road, Kittignahalli Village, Attibele, Hobli, Bangalore, Karnataka-560099",
        "Narayana Hrudayalaya Surgical Hospital, CAH 1, 3rd Phase, Devanur, Mysuru, Karnataka-570019",
        "Narayana Multispeciality Hospital, 78, Jessore Road ( South), Barasat, North 24 Parganas, Kolkata, West Bengal West Bengal , West Bengal-700127",
        "Narayana Multispeciality Hospital , (unit of MMRHL) Andul Road,Chunavati, Howrah West Bengal , West Bengal-711109",
        "Narayana Multispecialty Hospital, Sec-28, Rana Sanga Marg, Pratap Nagar, Jaipur, Rajasthan-302033",
        "Narayana Netralaya, Narayana Health City Narayana Hrudayalaya Campus #258/A, Bommasandra, Hosur Road, Bangalore, Karnataka-560099",
        "Narayana Superspeciality Hospital, Near Tolaram Bafna Medical Complex, Amingaon, Guwahati Assam , Assam-781031",
        "Narayana Superspecialty Hospital, (A Unit of MMHRL) 120/1 Andul Road, Howrah, West Bengal-711103",
        "Narula Diagnostic Centre LLP, Civil Road Rohtak, Haryana-124001",
        "Naruvi Hospitals, A unit of Sanco Foundation, T.S. No 72/1B2, Collector Office Road, Chennai-Bengaluru NH 48, Vellore Tamil Nadu , Tamil Nadu-632004",
        "Nasa Brain And Spine Centre, 128, Guru Nanak Pura, Kapurthala Road, Jalandhar, Punjab Punjab , Punjab-144001",
        "National Cancer Institute, Khasara No. 25, Outer Hingna Ring Road, Mouza Jamtha, Nagpur, Maharashtra-441108",
        "National Heart Institute, 49 Community Centre, East of Kailash, New Delhi, Delhi-110065",
        "NATIONAL INSTITUTE OF GASTROENTEROLOGY AND LIVER DISEASES, PLOT NO 344/A, MLA COLONY, ROAD NO 12, BANJARA HILLS, HYDERABAD, Telangana, Telangana-500034",
        "National Institute Of Ophthalmology, 1187/30 Off Ghole Road, Shivaji Nagar, PUNE, Maharashtra-411005",
        "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs), Sri Aurobindo Marg, New Delhi, Delhi-110030",
        "National Neuro Sciences Centre, Peerless Hospital Campus, 2nd Flr, 360 Panchasayar, Kolkata, West Bengal-94",
        "Nazareth Hospital, Laitumukhrah, Dist- East Khasi Hills, Shillong, Meghalaya-793003",
        "Neelam Healthcare Private Limited, (Formerly Known as NEELAM HOSPITAL) NH-7, OPP. CHITKARA UNIVERSITY, RAJPURA-CHANDIGARH ROAD, FATEHPUR GARHI Punjab , Punjab-140401",
        "NEMCARE Hospital, G. S Road, Bhangagarh Guwahati, Assam-781005",
        "NEO Hospital, D-170A, Sector-50, NOIDA, Uttar Pradesh-201301",
        "Neotia Getwel (A Unit of Ambuja Neotia Healthcare Venture Limitied, (Formerly Known as NEOTIA GETWEL HEALTHCARE CENTRE) Uttorayon, Behind City Centre, Matigara, Siliguri West Bengal , West Bengal-734010",
        "Netaji Subhas Chandra Bose Cancer Hospital, 3081, Nayabad, West Bengal-700094",
        "NETAJI SUBHASH CHANDRA BOSE DISTRICT HOSPITAL, GORAKHPUR GHOSH COMPANY CHAURAHA GORAKHPUR, Uttar Pradesh-273001",
        "Nethradhama Super Specialty Eye Hospital, No. 256/14, Kanakapura Main Road, 7th Block, Jayanagar, Bangalore, Karnataka-560070",
        "NETRODAYA THE EYE CITY LLP, 651/652 DAFI TOLL PLAZA VARANASI, VARANASI, Uttar Pradesh-221011",
        "New Amrutha medical and Research centre, No 12-10-239/1/1, Near Chitragar kalyan mantap, Siyatalab, Raichur, Karnataka-584101",
        "NEW BHOSARI HOSPITAL, BHOSARI, PUNE, Maharashtra-411039",
        "New Thergaon Hospital, SN 9/38, Bapujibuva Nagar, Opposite Thergaon Police Chowki, Near Tapkir Chowk, Thergaon , Maharashtra-411033",
        "NH Brahamananda Narayana Multispeciality Hospital, Near Pardih Chowk, Tamolia, NH 33, Jamshedpur,, Jharkhand-831012",
        "Nice Hospital For Women, Newborn and Children, 10-2-247 and 248, Shanti Nagar, Masab Tank, Hyderabad, Telangana-500057",
        "NIDAAN HOSPITAL, (AUnit Of Narsigh Hospital And Heart Institute) Vill Revli Distt Sonipat , Haryana-131001",
        "NIMS Medicity, Noorul Islam Institute of Medical Sciences and Research Foundation Aralummoodu, Neyyattinkara, Trivandrum, Kerala-695123",
        "Niramay Hospital and Research Center, Plot No. 22, CTS No. 523A/1/19, Sadar Bazar, Opp. Zilla Parishad, Satara, Maharashtra-415001",
        "Nirmal Hospital, Ring Road, Surat,, Gujarat-395002",
        "Nirmala Multi Speciality Hospital, # 1004/963, Next to Doctors Layout Ring Road Alanahalli, Mysore, Karnataka-570028",
        "Nizam`s Institute of Medical Sciences, Punjagutta, Hyderabad, Telangana-500082",
        "Noble Hospital, 153, Magarpatta City Road, Hadapsar, Pune, Maharashtra-411013",
        "North District Hospital - Asilo Hospital, Near Govt ITI College, Peddem, Mapusa-Bardez , Goa-403507",
        "Northern Railway Central Hospital,, Basant Lane,, Delhi-110055",
        "NU Hospital, Door No. 4/1, West of chord Road, Rajajjinagar, Next to ISKCON Temple, Bangalore, Karnataka-560010",
        "O P Jindal Institute Of Cancer and Cardiac Research, Model Town, Hisar, Haryana, Haryana-125005",
        "Oasis Centre for Reproductive Medicine, Plot No. 8-2-269/3/1/4 Near NATCO Pharma, Banjara Hills, Road No. 2, Hyderabad, Telangana-500034",
        "Ojas Super Speciality Hospital, H-1, Sector-26, Panchkula Extension, Panchkula, Haryana Haryana , Haryana-134116",
        "Omega Hospital, ( A Unit of Kurnool Institute of Oncology Private Limited) H.no.87-1366,Maruthi Nagar,Near Vaartha Office,Kurnool, Andhra Pradesh-518003",
        "Omega Hospital, MLA Colony, Road No. 12, Banjara Hills, Hyderabad, Telangana-500034",
        "Omni RK Super Specialty Hospital, Waltair Main Road, Opp - Lions Club, Ramnagar, Visakhapatnam, Andhra Pradesh-530002",
        "Oriana Hospital, 6,7,8, Ravindrapuri Varanasi, Uttar Pradesh-221005",
        "Orthopaedics Hospital, Eastern Railway, 222/Church Road, Howrah, West Bengal-711101",
        "OZONE Institute of Medical Sciences, Green Hills Colony, Road No. 4, Kothapet, Hyderabad, Telangana-500035",
        "P D Hinduja Hospital, Marvella Building, 724, 11th Rd, Khar West, Mumbai, Maharashtra-400052",
        "P K DAS Institute of Medical Sciences, Vaniamkulam Post, Ottapalam, Palakkad District , Kerala-679522",
        "P.D. Hinduja National Hospital and Medical Research Centre, Veer Savarkar Marg, Mahim, Mumbai, Maharashtra-400016",
        "P.L. Sharma District Hospital, Ahmad Road, Near Ghantaghar, Meerut City, Uttar Pradesh-250002",
        "PAALANA INSTITUTE OF MEDICAL SCIENCES, KANNADI(PO), PALAKKAD,, Kerala-678701",
        "Pace Hospitals, Plot Number 23, HUDA Techno Enclave, Patrika Nagar, Madhapur, Hyderabad,, Telangana-500081",
        "Pacific Medical College and Hospital, Bhillo Ka Bedla, Amberi NH 76, Udaipur, Rajasthan-313001",
        "PADMINI CARE A UNIT OF DRIEMS INSTITUTE OF HEALTH SCIENCES AND HOSPITAL, AT-KAIRAPARI PO-KOTSAHI,TANGI DIST - CUTTACK, Odisha-754022",
        "Paediatric Surgery Centre and P.G. Institute, 8750, Vishrambag, Sangli, Maharashtra-416415",
        "Palakkad Institute of Medical Sciences, Opposite to Deer Park, Pudussery East, Kerala , Kerala-678624",
        "PANDIT DEEN DAYAL UPADHYAY DIVISIONAL DISTRICT HOSPITAL, MORADABAD (U.P.), CIVIL LINE MORADABAD , Uttar Pradesh-244001",
        "Pandit Deen Dayal Upadhyay Government Hospital, Pandeypur, Varanasi, Uttar Pradesh-221002",
        "Pandit Deendayal Upadhyay (P.D.U.) Medical College, Civil Hospital Campus, Jamnagar Road, Rajkot , Gujarat-360001",
        "Pandit Madan Mohan Malaviya Shatabdi Hospital, Waman Tukaram Patil Marg, Govandi, Mumbai, Maharashtra-400088",
        "Pandit Raghunath Murmu Medical College and Hospital , At-Rangamatia,Po-Laxmiposi,Baripada,Mayurbhanj , Odisha-757107",
        "Pandurang Ramarao Dongaonkar District Hospital , Durg G.E Road Near Gandhi Chowk District Durg Chhattisagrh , Chhattisgarh-491001",
        "Panihati State General Hospital, 218F Barasat Road, Sodepur, North 24 Parganas West Bengal , West Bengal-700110",
        "Panskura Super Specialty Hospital, Vill - Uttar Mechogram, PO- Panskura, PS- Panskura, Dist- Purba Medinipur West Bengal , West Bengal-721139",
        "PARAMITHA CHILDRENS HOSPITAL, KOMPALLY PLOT NO 37, NCL ENCLAVE, PET BASHEERBAD, QUTHBULLAPUR, MEDCHAL , Telangana-500067",
        "Paramitha Childrens Hospital, 11-13-728/5, Green Hills Colony, Lb Nagar, Hyderabad, Telangana-500074",
        "Paras HMRI Hospital, NH - 30, Raja Bazar, Bailey Road, Patna, Bihar-800014",
        "Paras Hospital, plotno.1 Lane shobhagpura circle, Udaipur, Rajasthan-313001",
        "Paras Hospital, C-1, Shushant Lok Phase-I, Gurgaon, Haryana-122002",
        "Paras Hospitals, Plot no. H-2, HSIIDC, Technology Park, Near Nada Sahib Gurudwara Sec. 22 Panchkula, Haryana-134109",
        "Park Clinic, 4, Gorky Terrace, Kolkata, West Bengal-700017",
        "Park Hospital, Q Block, South City- II, Sector-47, Gurgaon , Haryana-122002",
        "Park Hospital, Village Siwah, National Highway No1, GT Karnal Road. PANIPAT, Haryana-132103",
        "Park Hospital, , 12, Meera Enclave, Chowkhandi, Near Keshopur Bus Depot, Outer Ring Road, Delhi-110018",
        "Parmanand Special Surgery Hospital, (A Unit of Sant Parmanand Hospital) Plot NO 1,2 and 3, Park Area Yamuna Bazar, Ring Road Kashmiri Gate, Delhi-110006",
        "Parvathy Ortho Hospital Private Limited, No.241,G.S.T.Road,Chrompet,Chennai. Tamil Nadu , Tamil Nadu-600044",
        "Patel Hospital, Civil Lines, Jallandhar, Punjab-144001",
        "Patna Medical College and Hospital, Ashok Rajpath, Patna, Bihar-800004",
        "Pawana Hospital, Survey no.-167, Somatane Phata, Taluka-Maval, Pune. Maharashtra , Maharashtra-410506",
        "PBMA`S H V Desai Eye Hospital, 93, Taravade Vasti, Mohammadwadi, Hadapur, Pune, Maharashtra-411060",
        "Peerless Hospital and B K Roy Research Centre, 360, Panchasayar, KOLKATA, West Bengal-700094",
        "People Tree Hospital, No. 2 Thumkur Road, Goraguntepalya, (Next to Yeshwanthpur Industry Metro Stop) Bangalore, Karnataka-560022",
        "Petals Newborn and Children Hospital, Bheemsen Bhawan Marg, Krishna Talkies Road, Samta Colony, Raipur Chhattisgarh , Chhattisgarh-492001",
        "PGIMER, Dr. Ram Manohar Lohia Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi-110001",
        "PGI Chandigarh",
        "PIOS MEDILINKS PVT.LTD., Pios Medilinks Pvt Ltd Sangli Kolhapur Road Near Zele Petrol Pump , Maharashtra-416101",
        "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital, PB No. 2259, Palarivattom (PO), Cochin, Kerala-682025",
        "Pondicherry Inst. of Med. Scs., Ganapathichettikulam, Kalapet, Pondicherry, Puducherry-605014",
        "Poona Hospital and Research Centre, 27 Sadashivpeth, Pune, Maharashtra-411030",
        "Popular Medicare, N-10/60 A-2 Dlw Road Kakarmatta, Varanasi, Uttar Pradesh-221004",
        "Porwal Hospital, Love Garden Road R.C. Vyas Colony Bhilwara, Rajasthan-311001",
        "Post Graduate Institute of Child Health, Sector - 30 Noida, Uttar Pradesh-201303",
        "Prabha Eye Clinic And Research Centre, 504, 40th Cross, 8th Block, Jayanagar, Bangalore, Karnataka-560070",
        "Prafulla Chandra Sen Government Medical College and Hospital, (Formerly Arambagh Sub Divisional/ Super Speciality Hospital), Arambagh Hooghly , West Bengal-712601",
        "Pragna Childrens Hospital, 6-3-347/22/B/1, Dwarakapuri Colony, Near Saibaba Temple, Punjagutta, Hyderabad, Telangana-500082",
        "Prakash Hospital and Trauma Center, N H-29,Sahadatpura MAU, Uttar Pradesh-275101",
        "Prakash Netra Kendr Private Limited, NH-2, Vipul Khand IV, Gomti Nagar, Lucknow, Uttar Pradesh-10",
        "Prakriya Hospitals, 41 Raja Reddy Layout, Near 8th Mile Signal Tumkur Road, Nagasandra Bangalore , Karnataka-560073",
        "Pranaam Hospital, 1-58/6/40 and 41, Madinaguda, Miyapur, Hyderabad,, Telangana-500049",
        "PRANAYAM LUNG AND HEART INSTITUTE, PRANAYAM LUNG AND HEART INSTITUTE LAKULESH PARK SOCIETY , NEAR SADHU VASWANI SCHOOL, NEW VIP ROAD VADODARA GUJARAT , Gujarat-390022",
        "Prasad Netralaya Superspeciality EYe Hospital, A J Alse Road Behind Alankar Theatre Udupi , Karnataka-576101",
        "Prashanth Hospital, #39-2-19/12/1A, APSEB City Central Colony, Off. MG Road, Labbipet, Vijayawada, Andhra Pradesh-520010",
        "PRASHANTH HOSPITALS, No.36 and 36A, Velachery Main Road, Velachery, , Tamil Nadu-600042",
        "Prashanthi Hospital, # 7-3-94, Brahmana Wada, Machili Bazar, Hanamkonda, Warangal Dist. , Telangana-50601",
        "Pratiksha Hospital, Borbari, VIP Road, Hengerabari, Guwahati, ASSAM, Assam-781036",
        "Precision Urology Hospital, 652 B-37, Pratap Nagar, Ring Road, Tedhi Pulia, Vikas Nagar, Lucknow Uttar Pradesh , Uttar Pradesh-226022",
        "Preethi Hospital, 50, Melur Main Road, Uthangudi, Madurai, Tamil Nadu-625107",
        "Premier Hospitals Pvt. Ltd., Door No. 12-2-710/1, Opp Queba Masjid, Nanal Nagar X Road, Mehdipatnam, Hyderabad, Telangana-500028",
        "Prince Aly Khan Hospital, Aga Hall, Nesbit Road, Mazagaon, Mumbai, Maharashtra-400010",
        "Princess Durru Shehvar Children`s and General Hospital, Purani Haveli, Hyderabad, Telangana-500002",
        "PRIYA HOSPITAL, C/o Priya Hospital D-25, HIG, World Bank Barra, Bypass Road, Kanpur Nagar - 208027 (UP) , Uttar Pradesh-208027",
        "PRS Hospital, Tc 21/53 Killippalam Karamana Trivandrum , Kerala-695002",
        "Pt. B D Sharma, PGIMS,, Rohtak, Haryana-124001",
        "Pt. Jawaharlal Nehru Govt. Medical College, Chamba Himachal Pradesh , Himachal Pradesh-176310",
        "Public Health Centre, 114, Lake View Road, West Mambalam, CHENNAI , Tamil Nadu-33",
        "Punjab Institute of Medical Sciences, Jalandhar Garha Road, Jalandhar, Punjab-144006",
        "Purba Medinipur District Hospital, Tamluk,, West Bengal-721636",
        "Purulia Government Medical College and Hospital, (Formerly Deben Mahato (Sadar) Hospital), Purulia, West Bengal-0",
        "Pushpagiri Eye Institute, Plot No.241, Uma Plaza Door No.10-2-342, Road No.9, West Marredpally, Secunderabad, Telangana-500026",
        "Pushpagiri Institute of Medical Sciences and Research Centre, Pushpagiri Medical College Hospital, Tiruvalla, Kerala-689101",
        "Pushpanjali Hospital, Plot No 1, Sector 4 Rewari Haryana, Haryana-123401",
        "Pushpanjali Hospital and Research Centre, Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh-282002",
        "Pushpawati Singhania Hospital, Press Enclave, Sheikh Sarai Ph-II, , Delhi-110017",
        "Q1 Hospital, (A Unit Of Q1 Bone and Joint Hospital) Plot No 13a and 13b, Health City, Chinnagadhili, Arilova, Visakhapatnam , Andhra Pradesh-530040",
        "QRG Medicare, Plot No. 1, Sector-16, Faridabad, Haryana-121002",
        "Quality Care India Limited (Care Hospital), (Formerly Visakha Hospital and Diagnostics (Care Hospital)) A.S. Raja Complex, Door No: 10-50-11/5, Waltair Main Road, Ramnagar, Visakhapatnam, Andhra Pradesh-530002",
        "Queen`s NRI Hospital, (A Unit of M/s Chalasani Hospitals Pvt. Ltd.) Off. No.: 50-53 - 14, Gurudwara Lane, Seethammadhara, Visakhapatnam, Andhra Pradesh-530013",
        "R P HOSPITAL AND RESEARCH INSTITUTE, GUT NO. 539, 544, 545 PART, PEDGAON, PARBHANI-MANWAT ROAD, TQ. AND DIST. PARBHANI, Maharashtra-431537",
        "R.K. Govt District Hospital, Rajsamand Near RIICO area Dhoinda Bhilwara by pass Road Rajsamand Rajasthan , Rajasthan-313324",
        "Rabindranath Tagore International Institute of Cardiac Sciences, Premises No.1489, 124, Mukundapur, E M Bypass, Near Santhoshpur Connector, KOLKATA, West Bengal-700099",
        "Rafi Ahmad Kidwai Memorial District Male Hospital, Infrant of Government Inter College, Near Naka Satrikh Crossing, Nawabganj, Faizabad Road, Barabanki, Uttar Pradesh-225001",
        "Rahman Hospital, VIP Road, Sixmile, Guwahati, Assam-781022",
        "Rahmania Medical Centre, NH-28A, Bariyaarpur, Motihari, Bihar, Bihar-845401",
        "Raiganj Government Medical college and hospital, Dr. B C Roy Sarani, PO and PS - Raiganj District Uttar Dinajpur, West Bengal-733134",
        "Rainbow Childrens Hospital, 157, Anna salai, Tamil Nadu-600015",
        "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences Pvt. Lt 48-10-12/2A, Opp. NTR University Health Sciences. Vijayawada Currency Nagar, Krishna Dist, Andhra Pradesh-520008",
        "Rainbow Childrens Hospital, Plot no:15A, SY No: 21 and 27, Chinnagadhili, Health City, Visakhapatnam, Andhra Pradesh-530040",
        "Rainbow Childrens Hospital, Sy. No. 8/5, Marathahalli K R Puram Outer ring road, Doddanekundi, Marathahalli, Bengaluru, Karnataka-560037",
        "Rainbow Childrens Hospital, Sy No 178/1 and 178/2, Opp. Janardhan Towers, Billekahalli, Bannerghatta, Bengaluru, Karnataka-560076",
        "Rainbow Childrens Medicare Pvt Ltd, (Formerly Rainbow Children Hospital and Birthright) # H No 3-7-222, Sy No 51 to 54 Karkhana Main Road, Kakaguda Village, Secundrabad Contonment, Hyderabad District, Telangana-500009",
        "Rainbow Children`s Heart Institute, Plot No.22, Road No.10, Banjara Hills, Hyderabad, Telangana-500034",
        "Rainbow Children`s Hospital, 22, Rd#10, Banjara Hills, Hyderabad-500034, Telangana-500034",
        "Rainbow Hospital For Women and Children, Survey No. 141, Door No: 1-2-20, Opp. Chermas, Hydernagar, Hyderabad, Telangana-500072",
        "Rainbow hospital for women and children, Plot# 73/C and 73/D, Mansoorabad, LB Nagar, Hyderabad, Telangana-500074",
        "RAINBOW INSTITIUTE OF MEDICAL SCIENCES (RIMS), BESIDE HOTELNEELAM OPP EMPIRE MALL BADNERA ROAD AMRAVATI , Maharashtra-444605",
        "Raj Eye Hospital, Near Chhatra Sangh Chauraha, Cantt. Road, Gorakhpur, Uttar Pradesh-273001",
        "RAJ JINDAL HOSPITAL AND RESEARCH CENTRE PVT LTD, S.P.M. NAGAR BEHIND REGIONAL OFFICE RSPCB BHARATPUR RAJASTHAN, Rajasthan-321001",
        "RAJA PRATAP BAHADUR HOSPITAL ALLIED WITH DR SONELAL PATEL AUTONOMOUS STATE MEDICAL COLLEGE, ,GAI GHAT,PURE KESHAV RAI PRATAPGARH (U.P) , Uttar Pradesh-230001",
        "Raja Sukhdev Singh DH Poonch, Dh Poonch Radio Station Road, Jammu and Kashmir-185101",
        "Rajagiri Hospital, Chunangamveli Aluva Ernakulam District, Kerala-683112",
        "Rajan Babu Institute of Pulmonary Medicine and Tuberculosis, GTB Nagar, Kingsway Camp, Delhi-110009",
        "Rajan Eye Care Hospital, No.5 Vidyodaya East, 2nd Street, T. Nagar, CHENNAI, Tamil Nadu-600017",
        "Rajendra Institute of Medical Sciences, Ranchi, Jharkhand-834009",
        "Rajiv Gandhi Cancer Institute and Research Centre, Sec-5, Rohini, New Delhi, Delhi-110085",
        "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital, alwar Rajasthan , Rajasthan-301001",
        "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi. General Hospital) Ellapillaichavady,, Puducherry-605011",
        "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, Thane Belapur Road, Kalwa, Thane , Maharashtra-400605",
        "Rajiv Gandhi Super Speciality Hospital, Tahirpur,, Delhi-110093",
        "Rajmata Jijau Hospital, Airoli, Sector 3 , Plot No.31 , Near Bus Depot Airoli, Navi Mumbai, Maharashtra-400708",
        "Ramkrishna Care Hospital, Aurobindo Enclave, Pachpedhi Naka, Dhamtari Road, N. H. 43, Raipur, Chhattisgarh-492001",
        "Rampurhat Govt. Medical College and Hospital, Rampurhat, Birbhum West Bengal , West Bengal-731224",
        "RANA BENI MADHAV SINGH (RBMS) DISTRICT HOSPITAL, NIRALANAGAR, KACHAHARI ROAD, NEAR HATHI PARK, RAEBARELI, Uttar Pradesh-229001",
        "Ranaghat Sub Division Hospital, Jobpur Road, Anulia West Bengal , West Bengal-741255",
        "Ranchi Institute of Nuero - Psychiatry and Allied Sciences, Kanke, Ranchi, Jharkhand-834006",
        "Rangadore Memorial Hospital, 1st Cross, Shankarapuram, basavanagudi, Bangalore , Karnataka-560004",
        "Rani Durgavati Medical College<br/>(Formerly known as Government Allopathic Medical College), Banda Tindwara Road, Banda , Uttar Pradesh-210001",
        "Rani Hospital, Booty Road, Ranchi, Jharkhand-834001",
        "Ratan Jyoti Netralaya Opthalmic Institute and Research Centre, 18 Vikas Nagar, Near Sai Baba Mandir, Gwalior, Madhya Pradesh-474002",
        "Regency Hospital, A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh-208005",
        "Regency Hospital, A 4, Sarvodaya Nagar, Kanpur, Uttar Pradesh , Uttar Pradesh-208005",
        "Regional Cancer Centre, Medical College Campus, Trivandrum, Kerala-695011",
        "Renee Hospital, H.No:3-7-1 and 3-7-02, Indira Nagar, Karimnagar, Telangana-505001",
        "RENOVA NEELIMA HOSPITAL, 7-2-1735 CZECH COLONY SANATHNAGAR HYDERABAD , Telangana-500015",
        "RENOVA SOUMYA CANCER CENTRE, 3-7-218/P J- 19/P, KARKHANA , SECUNDERBAD , Telangana-500015",
        "RETINA SPECIALITY HOSPITAL, 324-325-312, Scheme Number 54, PU 4, Rasoma Square, A.B. Road Indore, Opposite to Medanta Hospital, Indore, Madhya Pradesh-452010",
        "Revathi Medical Center Tirupur (I) Pvt Ltd, 10, Kumar Nagar West, Avinashi Road, Tirupur Tamil Nadu , Tamil Nadu-641603",
        "Rex Hospital, 43, R.R. Layout, Behind Shanmuga Theatre, Poomarket Bus Stop, Mettupalayam Road, Coimbatore, Tamil Nadu-641002",
        "Rhythm Heart Institute, Near Siddharth Buglows, SAMA-SAVLI Road, Vadodara, Gujarat-390022",
        "Rich Hospital, 16-11-131 Kasthuri Devi Nagar, Poga Thota, Nellore, Andhra Pradesh-524001",
        "Rio Childrens Hospital Pvt Ltd, No:40/4C, 2B1, Masthanpatti, Madurai, Tamil Nadu-625020",
        "Rohini Medicare, (Dr. J Sudhakar Reddy) House No. 2-5-742, Subedari, Hanamkonda Warangal, Andhra Pradesh-506001",
        "Rotary Eye Institute, Dudhia Talav, NAVASARI,, Gujarat-396445",
        "ROURKELA GOVT HOSPITAL , STI CHOWK RAGHUNATHPALLI PS ROURKELA , Odisha-769004",
        "Royal Care Super Speciality Hospital, 1/520, LandT Road, Neelambur, Coimbatore, Tamil Nadu-641062",
        "Ruban Memorial Hospital, 19/Patliputra Colony Near Patliputra Golambar Patna , Bihar-800013",
        "Ruby Eye Hospital, Susruta Nagar, Govinda Bihar, M.G. Road, Berhampur, Odisha-760001",
        "Ruby General Hospital, Kasba, Golpark, E M Byapass, Kolkata, West Bengal-700107",
        "Ruby Hall Clinic, 40 Sassoon Road Pune, Maharashtra-411001",
        "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) Gopalpura Bypass Road, Gopalpura, Near Triveni Flyover, Jaipur, Rajasthan-302018",
        "Rural Development Trust Hospital, Kadiri Road, Bathalapalli, ANANTAPUR, Andhra Pradesh-515661",
        "S L Raheja Hospital (A Fortis Associate), Mumbai, Maharashtra-400016",
        "S P Institute of Neurosciences, Ramwadi Road, Solapur, Maharashtra-413001",
        "S R Kalla Memorial Gastro and General Hospital, 78-79 Dhuleshwar Garden Sardar Patel Marg Behind Hsbc Bank C-Scheme Jaipur, Rajasthan-302001",
        "S. G. L. Super Specialty Charitable Hospital, Garha Road, Jalandhar, Punjab, Punjab-144022",
        "S. K. S. Hospital, No. 23, SKS Hospital Road, Alagapuram, SALEM, Tamil Nadu-636004",
        "S.A.I Hospital, Aryapuram Middlestreet, Near Gokavarm Bustand, Rajamahendravaram , Andhra Pradesh-533104",
        "S.K.Hospital, Sikar Silver Jublee Road, Sikar Rajasthan , Rajasthan-332001",
        "S.N.M. Hospital , Leh Ladakh Ladakh, Ladakh-194101",
        "Sacred Heart Hospital, G. T. Road Maqsudan Jalandhar, Punjab-144008",
        "Sadar Hospital, Biharsharif, Nalanda, Bihar-803101",
        "SADAR HOSPITAL, ROUZA ROAD, SASARAM, Bihar-821115",
        "Sadar Hospital, Motihari Belisarai, Motihari, East Champaran, Bihar, Bihar-845401",
        "SADAR HOSPITAL, MAIN ROAD SITAMARHI , Bihar-843302",
        "SADAR HOSPITAL, GOPALGANJ , Bihar-841428",
        "SADAR HOSPITAL, WEST OF PATEL MAIDAN, KASHIPUR, SAMASTIPUR, Bihar-848101",
        "SADAR HOSPITAL, SADAR HOSPITAL BHOJPUR , ARA SHIVGANJ, Bihar-802301",
        "SADAR HOSPITAL, BEGUSARAI, Bihar-851101",
        "Sadar Hospital, Opposite Xaviers College, Purlia Road, Ranchi, Jharkhand-834001",
        "Sadguru Nethra Chikitsalaya, Jankikund-Chitrakoot Dist Satna, Madhya Pradesh-210204",
        "Sadhu Vaswani Missions Medical Complex, (earlier known as Inlaks and Budhrani Hospital) 7-9, Koregaon Park, Pune, Maharashtra-411001",
        "Safdarjung Hospital and Vardhman Mahavir Medical College, Ansari Nagar West, Aurobindo Marg, Ring Road,, Delhi-110029",
        "Sagar Hospital, No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
        "Sagar Hospital - DSI, Shavige Malleshwara Hills, Kumaraswamy Layout, Banashankari, Bangalore, Karnataka-560078",
        "Sahai Hospital and Research Centre, S P 15 Bhabha Marg, Moti Dungri JAIPUR, Rajasthan-302004",
        "Saheed Laxman Nayak Medical College and Hospital, Koraput At-Janiguda, PO/PS/Dist-Koraput, Odisha, Orissa , Odisha-764020",
        "Sahyadri Narayana Multispecialty Hospital, Harakere, N T Road, Shimoga, Karnataka-577202",
        "Sahyadri Super Speciality Hospital , Hadapsar Sr.No.163, Bhosale Nagar, Hadapsar, Pune , Maharashtra-411028",
        "Sahyadri Super Speciality Hospital, Nagar Road Survey No.185A, Shastri Nagar, Near MSEB office, Yerwada, Nagar Road, Pune, Maharashtra-411006",
        "Sahyadri Super Specialty Hospital, Plot No. 30C, Erandawane, Karve Road, Pune., Maharashtra-411004",
        "Sai Hospital, Mukhani Chauraha, Haldwani, Distt Nainital, Uttarakhand-263139",
        "Sai Sanjeevini Hospitals, (A Unit of Singapanga Health Care Pvt. Ltd.), Plot Number: 7, Narsimha Puri Colony, Saroor Nagar, Beside T N R Fort, Kothapet, Hyderabad, Telangana-500035",
        "Sai Sudha Hospital, D No: 21-1-24, Jawahar Street, Salipeta, Kakinada, Andhra Pradesh-533001",
        "Saideep Healthcare and Research Pvt Ltd, Viraj Estate, Yashwant Colony, Tarakpur Ahmednagar , Maharashtra-414003",
        "Saifee Hospital, Post Box 3771, 15/17, Maharshi Karve Marg, Mumbai, Maharashtra-400004",
        "Saijyothi Eye Institute, Plot No.185, Road No.1, West Marredpally, Secunderabad Telangana , Telangana-500026",
        "SAINATH HOSPITAL (Managed By Quality Healthguards Pvt Ltd), Sant Nagar, Sector No 4, Moshi Pradhikaran, Pune Nashik Highway, Pune, Maharashtra-412105",
        "Saket Hospital, Sector 10, Meera Marg, Mansarovar, Jaipur, Rajasthan-302020",
        "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited) No. 52/2, 52/3, Devarabeesanahalli, Varthur Hobli, Bangalore, Karnataka-560103",
        "SAL Hospital and Medical Institute, Opp. Doordarshan, Drive-in -Road, Ahmedabad, Gujarat-380054",
        "Salem Polyclinic, No.250, Omalur main road, Salem, Tamil Nadu-636007",
        "Samraksha super specialty Hospital, Venkatrama Theatre Junction, Narsampet road, Warangal, Telangana-506002",
        "Sandhya Hospital, No 565 , Phase 1, 16 th Corss, Sathuvachari , Vellore Tamil Nadu , Tamil Nadu-632009",
        "Sanghamitra Hospitals Private Limited, D.NO: 5-82-1, Ambedkar Colony Extension, Near South Bypass Junction, Ongole , Andhra Pradesh-523002",
        "Sanjay Gandhi Memorial Hospital, , Mangol Puri, S Block,, Delhi-110083",
        "Sanjay Gandhi Post Graduate Institute of Medical Sciences, Raebareli Road, Uttar Pradesh-226014",
        "SANJIVINI HOSPITAL AND RESEARCH CENTRE, CP 23 VIRAJ KHAND, GOMTI NAGAR, HAHNEMANN CHAURAHA, LUCKNOW, Uttar Pradesh-226010",
        "Sankar Foundation Eye Hospital,, D.No. 19-50, sai Madhva Nagar, Vishakhapatnam, Andhra Pradesh-530047",
        "Sankara Eye Hospital, No.1, 3rd Street, Sri Sankara Nagar, Pammal, Chennai, Tamil Nadu-600075",
        "Sankara Eye Hospital, (formerly known as Shri Kanchi Kamakoti Medical Trust Hospital), Shivanandapuram, Sathy Road, COIMBATORE, Tamil Nadu-35",
        "Sankara Eye Hospital, Village Panau Purwa, Post Tatyaganj, Chaubepur Road, Kanpur Nagar, Uttar Pradesh-209217",
        "Sankara Eye Hospital , Vijaywada-Guntur Express way, Pedakakani Guntur, Andhra Pradesh-522509",
        "Sankara Eye Hospital, Airport-White Field Road, Kundanahalli Gate, Bangalore, Karnataka-560037",
        "Sankara Eye Hospital, Harakere, Thirthahalli Road, Shivamogga, Karnataka-577202",
        "SANKARA NETHRALAYA, JKCN COMPLEX NO.21, PYCRAFTS GARDEN ROAD (off. Haddows Road), CHENNAI Tamil Nadu , Tamil Nadu-600006",
        "Sankara Nethralaya, (A Unit of Medical Research Foundation) No. 147 Mukundapur, E M Bypass, Near Purva Jadupar Thanam,, West Bengal-700099",
        "SANKARA NETRA CHIKITSALAYA, 59-14-1/2,BHARATHI SQUARE BUILDING,GAYATHRI NAGAR,NIRMALA CONVENT SIGNAL,VIJAYAWADA- , Andhra Pradesh-520008",
        "Sant Parmanand Hospital, 18 Shamnath Marg, Civil Lines,, Delhi-110054",
        "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute, Bhawani Singh Marg, JAIPUR, Rajasthan-302015",
        "Santosh Hospital, No. 6/1 Promnade Road, Near Goodwills High School, Bangalore, Karnataka-560005",
        "Sarakshi Netralaya, Plot No 19 Rajiv Nagar Wardha Road Nagpur, Maharashtra-440025",
        "Saraswathy Hospital, Parassala Thiruvanathapuram Kerala India Kerala , Kerala-695502",
        "SARASWATI MISSION HOSPITAL, AMBALA ROAD, PEHOWA NEAR KHUSHWANT PALACE DISTT. KURUKSHETRA , Haryana-136128",
        "SARDAR VALLABH BHAI PATEL DISTRICT HOSPITAL , SATNA NEAR SBI CITY BRANCH SATNA, Madhya Pradesh-485001",
        "Sarji Hospital, R M R Road,Park Extention, Shivamogga, Karnataka-577201",
        "Saroj Gupta Cancer Centre Welfare Home and Research Institute, M.G.Road, Thakurpurkar, KOLKATA, West Bengal-700063",
        "Sarvodaya Hospital and Research Centre, YMCA Road, Sector-08, Faridabad, Haryana-121006",
        "Sarvodaya Multispeciality And Cancer Hospital, Delhi Road, Opp. Red Cross Bhawan, Hisar, Haryana-125001",
        "SARVODYA HOSPITAL, OPPOSITE KHALSA COLLEGE, GT ROAD, JALANDHAR, Punjab-144001",
        "Satguru Partap Singh Hospital, Sherpur Chowk, G T Road, Ludhiana, Punjab-141003",
        "Savera Cancer and Multispeciality Hospital, R N Singh Road Near Rajendra Nagar railway station, Kankarbagh, Patna, Bihar-800020",
        "SBLS Civil Hospital, Jail Road, Near Jyoti Chowk, Jalandhar, Punjab-144001",
        "SCB Medical College and Hospital, Mangalabag, Cuttack, Odisha Orissa , Odisha-753010",
        "Scudder Memorial Hospital, Door No. 52E/1 kellys Road, Ranipet, vellore District, Tamil Nadu-632401",
        "SDH KUPWARA, Kupwara Hospital Road Main Market, Jammu and Kashmir-193222",
        "SDH Nathdwara, Lal bagh near RTDC hotel Nathdwara Rajasthan , Rajasthan-313301",
        "Second Sight Southend Eye Centre Pvt. Ltd., . Dhopagachi, Kundarali, Baruipur, South 24 Parganas, West Bengal, , West Bengal-743610",
        "Sehgal Neo Hospital, B-362, 363, 364, Meera Bagh, Outer Ring Road, Paschim Vihar,, Delhi-110063",
        "Senthil Multi Speciality Hospital, ,. Erode D.no: 547 Perundurai Road, Erode- , Tamil Nadu-638011",
        "Sentini Hospitals, D. No. 54-15B and 5C, Near Vinayaka Theatre, Ring Road, Vijayawada, Andhra Pradesh-520008",
        "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL), NEAR POST OFFICE, GHATKOPAR EAST MUMBAI, Maharashtra-400077",
        "Sevasadan Lifeline Superspeciality Hospital, Chandanwadi, Sangli Miraj Road Miraj, Maharashtra-416410",
        "Seven Hills Hospital, D.No-11-4-4/A, Rockdale Layout, Visakhapatnam, Andhra Pradesh-530002",
        "Sewa Sadan Eye Hospital, Behind Civil Hospital, Sant Hirdaram Nagar, Bhopal, Madhya Pradesh-462020",
        "SGVP Holistic Hospital, SGVP Campus,Opp. Nirma University,S.G.Highway,Ahmedabad. , Gujarat-382481",
        "Shaheed Bhai Mani Singh District Hospital, Opp. Police Line, Mansa Road, Bathinda, Punjab-151001",
        "Shaheed Hasan Khan Mewati Government Medical College , Nalhar, Nuh,, Haryana-122107",
        "Shahid Mahendra Karma Memorial Government Hospital(Formerly Govt. Medical College (Maharani Hospital)), Dimrapal, Jagdalpur, Chhattisgarh-494001",
        "Shalby Hospital, S.G. Highway, Ahmedabad, Gujarat-380015",
        "Shalby Hospital, Part 5 and 6 RS bhandari Marg, Janjeerwala Square, Indore , Madhya Pradesh-452003",
        "Shalby Hospitals, jaipur Under Pass, Delhi - Ajmer Expressway, 200 Feet Bypass Rd, near Gandhi Path, Chitrakoot Sector 3, Vaishali Nagar, Jaipur, Rajasthan-302021",
        "Shankus Hospitals , B/H. Divine child School, near shankus water park, Ahmedabad-Mehsana Highway, Baliyasan, Gujarat-382732",
        "SHANMUGA HOSPITAL PRIVATE LIMITED, (Formerly Shanmuga Hospital and Salem Cancer Institute) 51/24, SARADA COLLEGE ROAD, Tamil Nadu-636007",
        "Shanti Hospital, Plot No 7-8, Sector No 22, Navangar, Bagalkot, Karnataka-587103",
        "SHANTI MEMORIAL HOSPITAL PVT LTD, PATNAIK COLONY, THORIA SAHI ,CUTTACK, Odisha-753001",
        "Shanti Mukund Hospital,, 2, Institutional Area, Vikas Marg Extn.,, Delhi-110092",
        "Sharanya, Bamchandaipur, NH2, Bardhaman, Dist. Purba Bardhaman, Pin. 713104, West Bengal West Bengal , West Bengal-713104",
        "Sher-I-Kashmir Institute of Medical Sciences, Saura, Srinagar , Jammu and Kashmir-190011",
        "Sher-I-Kashmir Institute of Medical Sciences, Bemina, Srinagar, Jammu and Kashmir-190018",
        "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Ellisbridge, Ahmedabad. Gujarat , Gujarat-380006",
        "Shifaa Hospital, No.- 332, Dar-us-Salam, Queens Road, Bengaluru, Karnataka-560052",
        "Shija Hospitals and Research Institute, Langol, P.O.- Lamphelpat Imphal West, Manipur, Manipur-795004",
        "Shivani Hospital Pvt Ltd, Shivani Hospital Pvt Ltd 120/503,Shivaji Nagar,Kanpur Uttar Pradesh , Uttar Pradesh-208005",
        "Shree Jagannath Hospital and Research Centre, Booty Road, Behind Mechhali Ghar, Through Radium Road, Ranchi, Jharkhand-834001",
        "Shree Krishna Hospital associated with Paramukhswami Medical College, Gokal Nagar, Karamsad, Anand, Gujarat-388325",
        "Shree Mahavir Health and Medical Relief Society Hospital, (Smt. R B Shah Mahavir Super Specialty Hospital and Shri B D Mehta Mahavir Heart Institute) Shree Mahavir Health Campus, Athwagate, Ring Road, Surat, Gujarat-395001",
        "Shree Markendey Solapur Sahakari Rugnalya and Research Centre, Niyamit,Flat plot No. 19, New Pachha Peth, Solapur, Maharashtra-413005",
        "Shree Mata Vaishno Devi Narayana Superspeciality Hospital, Kakryal, Katra District REASI, Jammu and Kashmir-182320",
        "Shree Narayana Hospital, Behind Sector-5, Near Ganj Mandi, Devendra Nagar, Pandri, Raipur, Chhattisgarh-492001",
        "Shri Aurobindo Medical Research Centre, Pachpedi Naka, Lalpur, Raipur, Chhattisgarh-492001",
        "Shri B D Mehta Mahavir Heart Institute, Shree Mahavir Health Campus, Athwagate, Ringroad, Surat, Gujarat-395001",
        "Shri Balaji Action Medical Institute, FC-34, A-4, Paschim Vihar, New Delhi, Delhi-110063",
        "Shri Balaji Institute of Medical Sceince, Ekta Chowk, Dubey Colony, Mowa,Raipur, Chhattisgarh-492001",
        "Shri Balaji Institute of Neuro Sciences and Trauma, Hosur-Unkal Road, Behind KSRTC Depot, Vidyanagar, Hubli, Karnataka-580021",
        "SHRI BALAJI METRO HOSPITAL, (A UNIT OF RAIGARH INSTITUTE OF MEDICAL SCIENCE PRIVATE LIMITED) SHRI BALAJI METRO HOSPITAL, PAHAD MANDIR ROAD, VILLAGE- KAUHAKUNDA, RAIGARH, Chhattisgarh-49600",
        "SHRI BHAUSAHEB HIRE GOVT. MEDICAL COLLEGE, DHULE, CHAKKARDBARDI CAMPUS, SURAT BYPASS, MUMBAI -AGRA HIGHWAY, DHULE, Maharashtra-424311",
        "Shri Dada Dev Matri Avum Shishu Chikitsalaya, Dabri,, Delhi-110045",
        "Shri Ganapati Nethralaya, Devalgaon Raja- Mantha Road, Nr- Janta High School, JALNA, Maharashtra-431203",
        "SHRI GANESH VINAYAK EYE HOSPITAL, OPPOSITE COLORS MALL, NEAR MUSKAN RESIDENCY, LALPUR, PACHPEDI NAKA, Chhattisgarh-492001",
        "Shri Lal Bahadur Shastri Government Medical College and Hospital , Vpo - Bhangrottu, Tehsil-Balh, District-Mandi,, Himachal Pradesh-175009",
        "Shri Ramkaran Joshi Government District Hospital, Lalsot Road, Dausa , Rajasthan-303303",
        "Shri Shishu Bhawan Hospital, Eidgah Road Near Madhyanagari Square Bilaspur , Chhattisgarh-495001",
        "Shri Shiv Prasad Gupta Divisional District Hospital, Kabirchaura, Varanasi, Uttar Pradesh-221001",
        "Shri Vasant Rao Naik Govt. Medical College, Yavatmal, Maharashtra-445001",
        "Shri Vinoba Bhave Civil Hospital, S.R.Delkar Marg, Saily Road, Silvassa, UT of DNH , Dadar and Nagar Haveli-396230",
        "Shrimad Rajchandra Hospital And Research Centre, Dharampur Bypass Road, At. Sherimal Post. Bilpudi, Tal Dharampur District - Valsad, Gujrat, Gujarat-396050",
        "Shrimann Superspeciality Hospital, Nh-44, Jalandhar-Pathankot Road, Near Reru Chowk, Jalandhar Punjab , Punjab-144012",
        "SHRIMANT MADHAVRAO SCHINDHIYA DISTRICT HOSPITAL , VIDISHA SANCHI ROAD VIDISHA , Madhya Pradesh-464001",
        "Shroff Eye Centre, A-9, Kailash Colony, , Delhi-110048",
        "Siddh Multi Specialty Hospital, Harthala Industrial Estate, Kanth Road,Moradabad, Uttar Pradesh-244001",
        "SIDS Hospital and Research Center, A unit of SIDS Health Care Pvt. Ltd., Off Ring Road, Near Shell Petrol Pump, Ring Road - Sosyo circle Lane, Surat, Gujarat-395002",
        "Sigma Hospital, P/8D Thonachikoppal Road, Saraswathipuram, Mysore , Karnataka-570009",
        "Sikkim-Manipal Institute of Medical Sciences, 5th Mile, Tadong, Sikkim GANGTOK, Sikkim-737102",
        "Siliguri District Hospital, Kachhari Road, Siliguri, Darjeeling, West Bengal-734001",
        "Siliguri Greater Lions Eye Hospital, 2nd Mile, Sevoke Road, Behind Vishal Cinema Hall, District Jalpaiguri , West Bengal-734002",
        "Sinduja Hospital, Madurai Road,Manapparai,Trichy. Tamil Nadu , Tamil Nadu-621306",
        "Sir C V Raman General Hospital, 80 Feet Road, Indiranagara Bangaluru, Karnataka-560038",
        "Sir Ganga Ram Hospital, Rajinder Nagar, New Delhi , Delhi-110060",
        "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, Raja Ram Mohan Roy Road, Gordhan Bapa Chowk, Prathana Samaj, Mumbai, Maharashtra-40004",
        "SK HOSPITAL, EDAPPAZHANJI, THIRUVANANTHAPURAM , Kerala-695006",
        "SKR Hospital And Trauma Centre Pvt. Ltd, Malikpur Chowk Pathankot, Punjab-145001",
        "Sky Hospital and Research Centre, RIMS Road District Imphal-west, Manipur-795004",
        "SLG Hospitals, Hyderabad Sy. No 306, Nizampet Road Bachupally, Medchal Dist Telangana , Telangana-500090",
        "SMITA MEMORIAL HOSPTIAL AND RESEARCH CENTRE, SH8, Vengalloor, Thodupuzha, Kerala, Kerala-685608",
        "Smt. Rasilaben Sevantilal Shah Venus Hospital,, Ashaktashram Complex Rampura Surat,, Gujarat-395003",
        "SNR District Hospital, Kolar, Karnataka-563101",
        "SOORYA HOSPITALS, Plot No.B-4/B-1, E.C., Kushaiguda, Hyderabad, Telangana-500062",
        "Sorake Chandrashekar (S.C.S) Hospital, Upper Bendoor, Mangalore , Karnataka-575002",
        "South Central Railways Hospital, LALLAGUDA, SECUNDERABAD, TELANGANA, Telangana-500017",
        "South Eastern Railway Divisional Hospital, South Side Railway Colony, Kharagpur, Dist:West Midnapur, West Bengal-721301",
        "South Eastern Railways Central Hospital, 11, Garden Reach Road, KOLKATA, West Bengal-700043",
        "South Western Railway, Central Hospital, Gadag Road, Hubballi, Karnataka-580020",
        "Southern Railway Head Quarter Hospital, Aynavaram, Perumbur, CHENNAI, Tamil Nadu-600023",
        "SP FORT HOSPITAL, ANAKOTTIL STREET WEST FORT THIRUVANANTHAPURAM , Kerala-695023",
        "Span Critical Care Centre, 165, Railway Lines, Near Suyash Classes, Saat Rasta, Solapur, Maharashtra-413003",
        "Sparsh Hospital, No.8, Ideal Homes HBCS Layout, Rajarajeshwari Nagar, Bengaluru , Karnataka-560098",
        "Sparsh Hospital for Advanced Surgeries , #146, Infantry Road,Opposite to police commissioners Office, Bengaluru, Karnataka-560001",
        "Sparsh Super Specialty Hospital, #4/1, Tumkur Road, Yeshwanthpur, Bangalore, Karnataka-560022",
        "Specialists Hospital, K C M Mother Road, Eranakulam North, Kochi, Kerala-0",
        "SRCC Children`s Hoapital, 1A-A Haji Ali Park, K Khadye Marg, mahalaxmi, Maharashtra-400034",
        "Sree Balaram Seva Mandir S.G. Hospital, 32, M.S. Mukherjee road, Khardah West Bengal , West Bengal-700116",
        "Sree Gokulam Medical College Research Foundation, Venjaramoodu P.O., Thiruvananthapuram, Kerala-695607",
        "Sree Sudheendra Medical Mission, Chittoor Road, Cochin, Kerala-682018",
        "Sree Uthradom Tirunal Hospital, Pattom, Trivandrum , Kerala-695004",
        "Sreenethra Eye Care, Nandavanam road, Bakery junction, Near reserve Bank of India, Thiruvananthapuram. Kerala , Kerala-695034",
        "Sri Aurobindo Medical College and Post Graduate Institute (SAIMS HOSPITAL), Indore-Ujjain State Highway, Near MR-10 Crossing, Sanwer Road, Indore (M.P.) Madhya Pradesh , Madhya Pradesh-453555",
        "Sri Chandra Sekara Hospital, M-1, M-2, H-23, Avalaplli Housing Board, Bagalur Road, Hosur Krishnagiri District, Tamil Nadu-635109",
        "Sri Gokulam Hospital,, 3/60, Meyannur Road, SALEM, Tamil Nadu-636004",
        "Sri Jayachamarajendra General Hospital, B.H.Road, Arsikere, Hassan.Dist. Karnataka , Karnataka-573103",
        "Sri Kiran Institute of Ophthalmology, Penumarthy Road, APSP Camp Post, Kakinada, Andhra Pradesh-533005",
        "SRI KRISHNA CHILDRENS HOSPITAL, 2-7-730, OPP: SUBEDARI POLICE STATION, HANAMKONDA, Telangana-506001",
        "Sri Krishna Medical College and Hospital, Uma Nagar, Muzaffarpur,, Bihar-842004",
        "Sri Narayani Hospital and Res. Centre, Thirumalaikodi, Distt. Vellore, Tamil Nadu-632055",
        "Sri Padmavathi Childrens Heart Centre, BIRRD Hospital premises, Tirupathi, Andhra Pradesh-517501",
        "Sri Ram Hospital, Near Ayodhya Junction Railway Station Ayodhya, Uttar Pradesh-224123",
        "Sri Ramachandra Childrens and Dental Hospital, D. No.: 13-7-1, 6th Lane, Gunrutyvarithota, Guntur, Andhra Pradesh-522001",
        "Sri Ramakrishna Hospital, 395, Sarojini Naidu Road, Sidhapudur, COIMBATORE, Tamil Nadu-641044",
        "SRI SAI HOSPITAL, DELHI ROAD, MANSAROVAR, MORADABAD, Uttar Pradesh-244001",
        "SRI SANKARA NETRA CHIKITSALAYA, 23A-6-6 ,NEAR VENKATESWARASWAMY TEMPLE,RR PETA,ELURU,WESTGODAVARI DISTRICT, Andhra Pradesh-534002",
        "Sri Sankaradeva Nethralaya, 96, Bashistha Road, Beltola, GUWAHATI,, Assam-781028",
        "Sri Sathya Sai General Hospital, Prashanthi Nilayam, Puttaparthy, Ananthapur, Andhra Pradesh-515134",
        "SRI SATHYA SAI HEART HOSPITAL, PLOT NO. 23 B, OPP: VISHVAKARMA TEMPLE , SARKHEJ-DHOLKA ROAD, KASINDRA, AHMEDBAD, Gujarat-382210",
        "Sri Sathya Sai Institute of Higher Medical Sciences, Prasantha Gram, ANANTHPUR, Andhra Pradesh-515134",
        "Sri Sathya Sai Instt. of Higher Medical Sciences, EPIP Area, Whitefield, Bangalore, Karnataka-560066",
        "Sri Sathya Sai Sanjeevani Hospital, Sector 2, (Near International Cricket Stadium), Nava Raipur Atal Nagar, Chhattisgarh-492101",
        "Sri Sathya Sai Sarla Memorial Hospital, Sathya Sai Grama Muddenahalli Chikkaballapura district , Karnataka-562101",
        "Sri Shankara Cancer Hospital and Research centre, Shankara Math Premises, 1st cross, Shankarapuram, Basavanagudi, Bangalore, Karnataka-560004",
        "Sri Sri Holistic Hospital, #1-2-49/13b, Nizampet Road, Hydernagar, Kukatpally, Medchal,, Telangana-500072",
        "Sri Venkateswara Aravind Eye Hospital, No 588A, Alipiri to Zoo Park Road,beside NCC campus, Tirupati, Andhra Pradesh-517505",
        "SRM Institutes for Medical Sciences, No. 1, Jawaharlal Nehru Salai, 100 ft Road, Vadapalani, Chennai, Tamil Nadu-600026",
        "SSG government district hospital, Chittorgarh Udaipur road,Senthi, Chittorgarh,Rajasthan , Rajasthan-312001",
        "St. Gregorios Medical Mission Hospital, Parumala P.o, Pathanamthitta, Kerala-689626",
        "St. Isabels Hospital, 49, Oliver Road, Mylapore, Chennai, Tamil Nadu-600004",
        "St. James Hospital, Old Highway, Chalakudy Thrissur District, Kerala , Kerala-680307",
        "St. Johns Medical College Hospital, Sarjapur Road, Koramanagala Bangalore , Karnataka-560034",
        "ST. JOSEPH HOSPITAL, ST. JOSEPHHOSPITAL MANJUMMEL, ERNAKULAM - KERALA, INDIA , Kerala-683501",
        "ST. JOSEPH HOSPITAL, 15, TRICHY ROAD, DINDIGUL, Tamil Nadu, Tamil Nadu-624001",
        "St. Josephs General Hospital, Guntur Andhra Pradesh , Andhra Pradesh-522004",
        "St. Martha`s Hospital , No.5, Nrupathunga Road Bangalore , Karnataka-560001",
        "St. Philomena`s Hospital, No. 4, Campbell Road, Viveknagar PO, Bangalore, Karnataka-560047",
        "St. Stephen`s Hospital, Tees Hazari, New Delhi, Delhi-110054",
        "St. Theresa`s Hospital, Sanath Nagar, Hyderabad, Telangana-500018",
        "Star Hospital, 8-2-596/5, RD. NO-10, Banjara Hills, Hyderabad, Telangana-500034",
        "Star Pinnacle Heart Center, 10-11-12,Apiic Health City Chinagadili,Visakhapatnam, Andhra Pradesh-530040",
        "Star Women and Children Hospital, H.N.4-101,Saraswathi Nagar,Teegalaguttapally,Karimnagar Telangana , Telangana-505001",
        "Starcare Hospital Kozhikode Private Limited, Starcare Hospital, Nellikkode Post, Thondayad, Kozhikode Kerala , Kerala-673016",
        "State Hospital , Dharampur Garden Road Dharampur Dist Valsad, Gujarat-396050",
        "Sterling Hospital, Plot No. 251, 150 ft Ring Road, Near Raiya Circle. Nanavati Chowk, Rajkot, Gujarat-360005",
        "Sterling Hospital, Off. Gurukul Road Behind Drive-In Cinema, Ahmedabad, Gujarat-380052",
        "Sub District Hospital Sopore, Kupwara-Road Sopore , Jammu and Kashmir-193201",
        "Sub Division Hospital, Kollegal O/o Chief Medical Officer, Sub Division Hospital, M.M Hills Main Road, Kollegal, Chamarajanagar Dist Karnataka, Karnataka-571440",
        "Sub Division Hospital, Opp Town Police Station Gangavathi, Karnataka-583227",
        "Sub Divisional Hospital, Sagara Sub Divisional Hospital J C Road Sagara Shivamogga Dist Karnataka Karnataka , Karnataka-577401",
        "Suchirayu Mulitispeciality Hospital, Off Gokul Road, Hubballi , Karnataka-580030",
        "SUDARSANI EYE HOSPITAL, 12-25-167, MAIN ROAD, KOTHAPET, GUNTUR , Andhra Pradesh-522001",
        "Sudha Hospitals, No. 162, 181 Perundurai Road Erode, Tamil Nadu-638011",
        "SUDHA MEDICAL COLLEGE AND HOSPITAL, GRAM UMMEDPURA, VIA-JAGPURA, NH-52, JHALAWAR ROAD KOTA (RAJ.), Rajasthan-325003",
        "Sukhda Multispeciality Hospital, Delhi Road, Opposite Green Belt, Hisar, Haryana-125001",
        "SUM ULTIMATE MEDICARE, PLOT NO- C/6, K-8, KALINGA NAGAR GHATIKIA, BHUBANESWAR, Odisha-751003",
        "Sumitra Hospital, A-119 A, Sector - 35, Noida, Uttar Pradesh , Uttar Pradesh-201301",
        "Sunshine Global Hospital, Beside Big Bazaar,Dumas Road,Piplod,Surat , Gujarat-395007",
        "Suraj Eye Institute, 559, New Colony, Nagpur, Maharashtra-440001",
        "Suri Sadar Hospital, Laldighipara, Suri, Birbhum P. O. - Suri, Dist- Birbhum, West Bengal-731101",
        "Surya Childrens Medicare Private Limited, (Formely known as Surya Mother and Child Care Jaipur Pvt Ltd.) B-7,Sawai Ram Singh Road,Tonk Road,Opp SMS Hospital Gate No 1,C-Scheme Jaipur Rajasthan , Rajasthan-302001",
        "Surya Childrens Medicare Private Limited, B-7, Swai Ram Singh Road, Tonk Road, Opp SMS Hospital, C-Scheme, Jaipur, Rajasthan-302001",
        "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) Junction Of S. V. Road and Dattatray Road Santacruz (West), Mumbai, Maharashtra-400054",
        "Surya Mother and Child Superspeciality Hospital, S. No:8 Devi Yash, Near Octori Post Wakad, Pune, Maharashtra-411057",
        "SUSHIL EYE HOSPITAL AND BRAHMA LASER CENTER, P-10, MIDC, NEAR PF OFFCIE, SATPUR, NASHIK, Maharashtra-422007",
        "Sushrut Hospital And Research Centre, 365 Swastik Park, Maharashtra-400071",
        "SUSHRUT INSTITUTE OF PLASTIC SURGERY PRIVATE LIMITED, 29, SHAHMEENA ROAD, LUCKNOW , Uttar Pradesh-226003",
        "Susrut Eye Foundation, HB-36/A/1, Sector III, Salt Lake City, Kolkatta , West Bengal-700106",
        "Susrut Eye Foundation and Research Centre, Berhampore 25/1, Kalikapur Road, Berhampore - 742101, Murshidabad West Bengal , West Bengal-742101",
        "Suyash Hospital (Suyash Institute of Medical Science), Gudhiyari Road, Behind Hotel Piccadily, Kota, Raipur, Chhattisgarh , Chhattisgarh-492001",
        "Swagat Super Speciality Surgical Institute, Near gate 4, Maligaon Guwahati, Assam-781011",
        "Swami Dayanand Hospital, Shahdara, Delhi-110095",
        "SWAMI KALYAN DEV DISTRICT HOSPITAL MUZAFFARNAGAR, LADDHAWALA MUZAFFARNAGAR, Uttar Pradesh-251001",
        "Swami Vivekanand National Institute of Rehabilitation Training and Research, Olatpur, PO Bairoi, District- Cuttack, Odisha-754010",
        "Syed Ammal Trust Hospital, Dr.E.M.Abdullah Campus 127/Swami Vivekanada Street, Ramanathapuram , Tamil Nadu-623501",
        "Synergy Institute of Medical Sciences, Ballupur Canal Road, Ballupur, Dehradun, Uttarakhand Uttaranchal , Uttarakhand-248001",
        "Synergy Superspeciality Hospital, Ayodhya Chowk, 150 Feet Ring Road, Rajkot, Gujarat-360005",
        "Tagore Hospital and Heart Care Center Pvt. Ltd., Banda Bahadur Nagar, Mahavir Marg, Jalandhar, Punjab-144008",
        "Taluk General Hospital, Bhadravathi Channagiri Road, Old Town, Bhadravathi Karnataka , Karnataka-577301",
        "Taluk General Hospital, Kadur Nh 206 Kadur , Chikmagalur District Karnataka , Karnataka-577548",
        "Taluk General Hospital, Byadagi Haveri District Karnataka , Karnataka-581106",
        "Taluka General Hospital, Ramdurg Belgaum Road RAMDURG 591123 Karnataka , Karnataka-591123",
        "Taluka General Hospital, C/o Chief Medical Officer, Taluka General Hospital, Hanamageri Street, Saundatti. Tq-Saundatti Dist-Belgaum Karnataka , Karnataka-591126",
        "Taluka General Hospital , Athani C/o Chief Medical Officer Taluka General Hospital Athani Tq Athani Dist Belagavi Karnataka , Karnataka-591304",
        "Tamil Nadu Government Multi Superspeciality Hospital, Anna Salai,Chennai, Tamil Nadu-600007",
        "TAMRALIPTO GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, TAMLUK,PURBA MEDINIPUR , West Bengal-721636",
        "Tata Main Hospital, C Road (West), Northen Town, Bistupur, JAMSHEDPUR, Jharkhand-831001",
        "Tata Medical Center, 14 Major Arterial Road (E-W), Newtown, Rajarhat, Kolkata, West Bengal-700160",
        "Tata Memorial Hospital (TMH), Dr. Ernest Borges Marg, Parel, Mumbai, Maharashtra-400012",
        "Tata Motors Hospital, Telco, Jamshedpur, Jharkhand-831004",
        "Tej Bahadur Sapru Hospital, Stanley Road, Allahabad, Uttar Pradesh-211002",
        "Tejasvini Hospital and SSIOT, Kadri Temple Road, Mangalore, Karnataka-575002",
        "Thangam Hospital, No. 54, Dr.Sankaran Road, Namakkal, Tamil Nadu-637001",
        "The Bangalore Hospital, # 202, R V Road, Bangalore, Karnataka-560004",
        "The Calcutta Medical Research Institute, 7/2, Diamond Harbour Road, KOLKATA , West Bengal-700027",
        "The Eye Foundation, 582-A, D.B.Road, R.S. Puram, COIMBATORE, Tamil Nadu-641002",
        "The Fatima Hospital, 35-C, Mahanagar, Lucknow, Uttar Pradesh-226006",
        "The Gujarat Cancer and Research Institute, M.P.Shah Cancer Hospital, New Civil Hospital Campus, Asarwa, Gujarat-380016",
        "The Gujarat Research and Medical Institute (Rajasthan Hospitals), Camp Road, Shahibag, Ahmedabad, Gujarat-380004",
        "THE MEDICITY HOSPITAL, RUDRAPUR TEEN PANI KICHHA ROAD,RUDRAPUR,UDHAM SINGH NAGAR , Uttarakhand-263153",
        "The Mission Hospital, Plot No. 219 (P), Immon Kalyan Sarani Sector 2C, Bidhannagar, Durgapur, West Bengal-713212",
        "The Voluntary Health Services and Medical Centre, Rajiv Gandhi Salai, Adyar, CHENNAI, Tamil Nadu-600113",
        "Thind Eye Hospital , 701-L, Mall Road, Model Town, Jalandhar, Punjab-144003",
        "Thorat Netra Hospital, Amankha Plots, Near LRT commerce college, Akola , Maharashtra-444001",
        "TIBRA EYE HOSPITAL AND RETINA CENTRE, W-28, SANTOSHI MATA MANDIR KE PAS, RANI SATI ROAD, SIKAR , Rajasthan-332001",
        "TIMES HOSPITAL, N.T.ROAD DEURIGAON, TEZPUR PIN - 784001, SONITPUR, Assam , Assam-784001",
        "Tirath Ram Shah Hospital, 2-A, R.B.L. Ishwar Das Sahney Lane, Rajpur Road,, Delhi-110054",
        "Tirumala Hospitals, 21/16,Guntha Bazaar,R.S.Road,Near 7 Roads circle,Kadapa,YSR District, Andhra Pradesh-516001",
        "Tirumala Medicover Hospitals, (Formerly Tirumala Hospital) (A Unit of Sahrudaya Health Care Vizianagaram Pvt. Ltd.), Lower Tank Bund Road, Opp. R T C Complex, Vizianagaram, Andhra Pradesh-535003",
        "Tiruvalla Medical Mission, P B No. 74, Tiruvalla, Pathanamthitta, Kerala-689101",
        "Tomo Riba Institute of Health and Medical Sciences, Lummer Dai Road, Naharlagun, Arunachal Pradesh-791110",
        "Topiwala National Medical College and BYL Nair Charitable Hospital, Dr. A L Nair Road, Mumbai, Maharashtra-400008",
        "Tricolour Hospital, Dr.Vikram Sarabhai Road,Near Genda Circle, Wadi Vadi, Vadodara, Gujarat-390007",
        "Tristar Hospital, Nanpura, Athwagate, Surat, Gujarat-395001",
        "Trustwell Hospitals Pvt. Ltd, No.5, Chandrika Towers, JC Road, Bangalore Karnataka , Karnataka-560002",
        "TX Hospitals, Uppal, Near Old Masjid, Bharat Nagar Dist: Medchal Malkajgiri , Telangana-500039",
        "U.P. Rural Instiute of Medical Sciences and Research, Etawah , Uttar Pradesh-206130",
        "Udai Omni Hospital, 5-9-94,Chapel Road, Hyderabad, Telangana-500001",
        "Udaynarayanpur State General Hospital, Village - Jangalpara, P.O. - Singti Shibpur, P.S. - Udaynarayanpur, District - Howrah, West Bengal , West Bengal-711226",
        "Unique Hospital Multispeciality and Research Institute, Run by Surat Life Care Pvt Ltd Near Kiran Motors, Opp Unique BRTS Junction, Civil Hospital Char Rasta Sosyo Circle Lane, Off Ring Road, Surat, Gujarat-395017",
        "Unique Super Specialty Centre, 715-718, 703, 704 Vijay Syndicate, Annapurna Road, INDORE, Madhya Pradesh-9",
        "UNITED CIIGMA INSTITUTE OF MEDICAL SCIENCES PVT LTD, Plot No. 6,7, Servey No. 10 Shahanoorwadi, Darga Road, Aurangabad, Maharashtra , Maharashtra-431005",
        "Uppal Neuro Hospital and Super Speciality Centre, 4, Rani Ka Bagh, Amritsar, Punjab-143001",
        "Ursala Horsman Memorial Hospital, Bada Chuaraha Civil Lines, Kanpur, Uttar Pradesh-208001",
        "USHAHKAL ABHINAV INSTITUTE OF MEDICAL SCIENCES, DHAMNI ROAD, SANGLI, Maharashtra-416416",
        "Utkal Institute of Medical Sciences and Hospital, C-3, Niladri Vihar, Chandrashekharpur, Bhubaneswar, Dist-Khorda, Odisha-751021",
        "Uttarpara State General Hospital, 9 R.P.M. Road, Uttarpara, Hooghly, West Bengal-712258",
        "V Y Institute of Medical Science, Kamal Vihar,Sector -12, New Dhamtari Road, Beside Krishna Public School, Raipur, Chhattisgarh-492001",
        "Vadamalayan Hospital, Block A and B, 9A Vallabai Road, Chokkikulam, Madurai, Tamil Nadu-625002",
        "VADAMALAYAN HOSPITALS PRIVATE LIMITED, 649 A/2, Angu Nagar, Chettinaikanpatti Village Road, Dindigul, Tamil Nadu-624004",
        "Valluvanad Hospital Complex Limited, Kanniyampuram(PO) Ottapalam , Kerala-679104",
        "Vardhman Trauma and LaproscopyCentre Pvt. Ltd., 3RD KM. Jansath road, Muzaffarnagar , Uttar Pradesh-251001",
        "VARUN TRAUMA and BURN CENTRE (P) LTD, QUARASI CHAURAHA, RAMGHAT ROAD , Uttar Pradesh-202001",
        "VASUNDHARA HOSPITAL, SECTOR-11, NANDANWAN, CHOPASNI HOUSING BOARD, JODHPUR, Rajasthan , Rajasthan-342008",
        "Vedanayagam Hospital, 133 East Bhakshyakaralu Road, R S Puram, COIMBATORE, Tamil Nadu-641002",
        "Vedanta Medical Research Centre (Balco Medical Centre), Sector 36, Atal Nagar (New Raipur) PO : Uparwara, Raipur (CG) S, Chhattisgarh-493661",
        "Veerangana Awanti Bai Women Hospital, Golaganj, Quaiserbagh Lucknow, Uttar Pradesh-226018",
        "Velammal Medical College Hospital and Research Institute, Madurai-Tuticorin Ring Road, Anuppanadi Madurai, Tamil Nadu-625009",
        "Venkatapadma Hospital, D No. 15-1-45, 3 Lamps Junction, Vizianagram , Andhra Pradesh-535002",
        "Venkateshwar Hospital, Sector 18a, Dwarka,, Delhi-110075",
        "Venketeshwara Kidney Centre, Ambedkar Road, Karim Nagar, Telangana-505001",
        "Venu Eye Institute,, 1/31,Institutional Area Phase-II, Sheikh Sarai,, Delhi-110017",
        "Venugram Hospital, CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka-590006",
        "VGM Gastrocentre, 2100,Trichy Road, Rajalakshmi Mills Stop, Coimbatore, Tamil Nadu-641005",
        "Vidyasagar Institute of Mental Health and Neuro Sciences,, Institutional Area, Nehru Nagar,, Delhi-65",
        "Vidyasagar State General Hospital, 4 Brahma Samaj Road, Behala, Kolkata West Bengal , West Bengal-700034",
        "Vijay Marie Hospital and Educational Society, Khairatabad, Hyderabad , Telangana-4",
        "Vijay Super Speciality Hospital, Lachhirampur-Azamgarh, Uttar Pradesh-276001",
        "Vijaya Hospital, New No. 434, Old No. 180, N S K Salai, Vadapalani, CHENNAI, Tamil Nadu-600026",
        "VIKASH MULTI SPECIALITY HOSPITAL, AH - 46, BARAHAGUDA CANAL CHOWK, DIST - , BARGARH , STATE - ODISHA Orissa , Odisha-768040",
        "VInayaka hospital, kattayad road sulthan bathery[p.o] wayanad district , Kerala-673592",
        "Virinchi Hospital, Door No. 6-3-2,3 and 3/1 Road No 1, Banjara Hills, Virinchi Circle, Hyderabad, Telangana-500034",
        "Virk Hospital Private Limited, 363-364 Randhir Lane, Near Dyal Singh College, Karnal , Haryana-132001",
        "Visakha Eye Hospital , 8-1-64, Peda Waltair, Visakhapatnam, Andhra Pradesh-530017",
        "Vishesh Jupiter Hospital, Near, Scheme No. 94, Sector 1,Ring Road, Teen Imli Square, Indore, Madhya Pradesh-452020",
        "Vishnuprasad Nandrai Desai Municipal General Hospital, 11th Road, TPS, Golibar, Santacruz (E) Mumbai, Maharashtra-400055",
        "VishwaRaj Hospital, Pune Solapur Road , Near Loni Railway Station, Loni Kalbhor, Pune,, Maharashtra-412201",
        "Vision Eye Centre, 19, Siri Fort Road, New Delhi, Delhi-110049",
        "Vivekanand Hospital, Vidya Nagar Latur, Maharashtra-413531",
        "Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda puram, Lucknow , Uttar Pradesh-226007",
        "Vivekanandha Medical Care Hospital , Sf. No. 20/1,Elayampalayam Village, Tiruchengode Taluk, Namakkal District, Tamil Nadu-637205",
        "Walsh S.D.H., Serampore, 22A, T. C. Goswami Street, Po+Ps-Serampore, Dist-Hooghly , West Bengal-712201",
        "WAVIKAR EYE INSTITUTE, LEVEL 4 AND 5, AMBER ARCADE, BHIWANDI BYPASS ROAD, NEAR LODHA PARADISE, MAJIWADA, THANE WEST, Maharashtra-400601",
        "Wenlock District Hospital, Hampanakatta, Mangalore, Karnataka-575001",
        "Western India Instt. of Neurosciences, , 183/1,B, Nagala Park, Kolhapur, Maharashtra-416002",
        "Wockhardt Hospital, Dr. Anand Rao Nair Marg Mumbai Central Mumbai, Maharashtra-400011",
        "Wockhardt Hospitals , Wockhardt Hospitals Ltd, Unit-The Umrao Institute Of Medical Science and Research, Near Railway Station, Mira Road East, Dist. Thane, Maharashtra-401107",
        "Women Hospital, Infront Of Aakashwani Kendra,Osmanabad Maharashtra, Maharashtra-413501",
        "Womens Center by Motherhood, 146 B, Mettupalayam Road, Coimbatore, Tamil Nadu-641043",
        "Woodland Hospital, Dhanketi, Shillong, Meghalaya-793003",
        "Woodlands Multispeciality Hospital Limited, 8/5 Alipore Road, Kolkata, West Bengal-700027",
        "Yashoda Hospital, Behind Hari Hara Kala Bhawan, S.P. Road, Secunderabad, Telangana-500003",
        "Yashoda Super Speciality Hospital, Raj Bhavan Road, Somajiguda, Hyderabad, Telangana-500082",
        "Yashoda Super Speciality Hospital, H-1, 26-27, Kaushambi, Near DaburChauk, Ghaziabad,, Uttar Pradesh-201010",
        "Yashoda Super Speciality Hospital, Nalgonda X Road, Malakpet, Hyderabad, Telangana-500036",
        "Yashoda Super Specialty Hospitals, IIIrd M, Nehru Nagar, Ghaziabad, Uttar Pradesh-201001",
        "Yashoda Superspeciality Hospital and Cancer Institute, B-1 and 2, Sector-23, Sanjay Nagar, Ghaziabad, Uttar Pradesh, India , Uttar Pradesh-201003",
        "Yashodhara Super Specialty Hospital, 6158, Siddheshwar, Peth, Near Zila Parishad, Solapur, Maharashtra-413003",
        "Yashvedh Healthcare Services, 69-18-2-1/2 , Beside Lalitha Nagar park , Gaigolupadu Junction Kakinada - 05 East Godavari District Andhra Pradesh , Andhra Pradesh-533005",
        "YATHARTH SUPER SPECIALTY HOSPITALS, GREATER NOIDA PLOT NO 32,OMEGA-1, GREATER NOIDA, Uttar Pradesh-200130",
        "Yatharth Superspeciality Hospitals, HO-01, SECTOR-01, Greater Noida West, 201306 , Uttar Pradesh-201306",
        "Yatharth Wellness Super Specialty Hospital and Heart Center, Plot No 1 , Gejha Road , Near Lotus Panache Sector 110, Noida, Uttar Pradesh-201304",
        "Yenepoya Specialty Hospital, (A unit of Yenepoya Institute of Medical Sciences and Research Pvt Ltd) V.T Road Kodialbail Mangalore, Karnataka-575003",
        "Zen Multispeciality Hospital, 425/437, 10th Road, Chembur, Mumbai, Maharashtra-400071",
        "Zoi Hospital, (A unit of Panchvaid Healthcare Services Pvt. Ltd) #6-3-1106/1/A, RajBhavan Road, Somajiguda, Hyderabad, Telangana-500082",
        "ZOI HOSPITALS, ATTAPUR H.No.4-8-149/4/2/1, NEAR PILLAR No. 145, ATTAPUR, HYDERABAD Telangana , Telangana-500048",
        "Zoi Hospitals, 7-1-71/A/1, Dharam Karam Road, Ameerpet, Hyderabad, Telangana-500016",
        "Zydus Hospital, Near Sola Bridge, S G Highway, Ahmedabad , Gujarat-380054",
    ],
    courseList: [
        // "ALL DIPLOMA",
        // "ALL MD",
        // "ALL MS",
        // "ALL DM",
        // "ALL MCH",
        // "ALL EXCEPT MBBS",
        // "ALL MD/MS/DIPLOMA",
        // "ALL DM/MCH",
        // "M.B.B.S.",
        //"All PG Courses",
        "Dip. in Path.& Bact.",
        "Diploma - Aviation Medicine",
        "Diploma - Diploma in Pathology & Bacteriology",
        "Diploma in Anaesthesia",
        "Diploma in Anaesthesiology",
        "Diploma in Basic Medical Sciences (Anatomy)",
        "Diploma in Basic Medical Sciences (Pharmacology)",
        "Diploma in Basic Medical Sciences (Physiology)",
        "Diploma in Cardiology",
        "Diploma in Child Health",
        "Diploma in Clinical Pathology",
        "Diploma in Community Medicine",
        "Diploma in Dermatology",
        "Diploma in Dermatology Venereology and Leprosy",
        "Diploma in Diabetology",
        "Diploma in Emergency Medicine",
        "Diploma in Family Planning",
        "Diploma in Forensic Medicine",
        "Diploma in General Medicine",
        "Diploma in General Surgery",
        "Diploma in Gynaecological Endoscopy",
        "Diploma in Health Administration",
        "Diploma in Health Education",
        "Diploma in Hemato-Oncology",
        "Diploma in Hospital Administration",
        "Diploma in Immuno-Haematology and Blood Transfusion",
        "Diploma in Industrial Health",
        "Diploma in Industrial Hygiene",
        "Diploma in Infertility & Assisted Reproductive Technique",
        "Diploma in Intensive Care",
        "Diploma in Learning Disablity & Neuro Development Paediatrics",
        "Diploma in Leprosy",
        "Diploma in Maternity & Child Welfare",
        "Diploma in Medical Oncology",
        "Diploma in Medical Radiology and Electrology",
        "Diploma in Medical Radiology and Electrology / Diploma in Medical Radio Diagnosis",
        "Diploma in Medical Virology",
        "Diploma in Microbiology",
        "Diploma in Minimal Access Surgery",
        "Diploma in Neonatology",
        "Diploma in Nephrology",
        "Diploma in Neurology",
        "Diploma in Obstetrics & Gynaecology",
        "Diploma in Ophthalmic Medicine and Surgery",
        "Diploma in Ophthalmology",
        "Diploma in Orthopaedics",
        "Diploma in Otorhinolaryngology",
        "Diploma in Oto-Rhino-Laryngology",
        "Diploma in Paediatric Cardiology",
        "Diploma in Paediatric Gastoenterology Hepatology & Neutrition",
        "Diploma in Paediatric Intensive Care",
        "Diploma in Paediatric Nephrology",
        "Diploma in Paediatric Neurology",
        "Diploma in Paediatric Orthopaedics",
        "Diploma in Paediatric Urology",
        "Diploma in Physical Medicine & Rehabilitation",
        "Diploma in Psychological Medicine",
        "Diploma in Public Health",
        "Diploma in Radiation Medicine",
        "Diploma in Radio-Diagnosis",
        "Diploma in Radio Therapy",
        "Diploma in Sports Medicine",
        "Diploma in Transfusion Medicine",
        "Diploma in Tropical Medicine Health",
        "Diploma in Tropical Medicine & Hygiene",
        "Diploma in Tuberculosis & Chest Diseases",
        "Diploma in Venereology",
        "Diploma (Marine Medicine)",
        "Diploma- Urology",
        "Diplom -Diploma V & D",
        "DM - Addiction Psychiatry",
        "DM - Cardiac-Anaesthesia",
        "DM - Cardiac Anaesthesia and Critical Care",
        "DM - Cardiac Intensive Care",
        "DM - Cardiac Radiology",
        "DM Cardiac surgical Intensive care",
        "DM - Cardiology",
        "DM - Cardiovascular Imaging and Vascular Interventional Radiology",
        "DM - Cardiovascular Radiology and Endovasular Intervention",
        "DM - Child & Adolescent Psychiatry",
        "DM - Clinical Haematology",
        "DM - Clinical Immunology",
        "DM- Clinical Immunology & Rheumatology",
        "DM - Clinical Immunology & Rheumatology (Internal Medicine)",
        "DM - Clinical Immunology & Rheumatology (Paediatrics)",
        "DM( Clinical Infectious Diseases)",
        "DM - Clinical Pharmacology",
        "DM - Critical Care Medicine",
        "DM - Endocrinology",
        "DM- Endocrinology & Metabolism",
        "DM - Geriatric Mental Health",
        "DM - Geriatric Psychiatry",
        "DM - Haematology Pathology/Hematopathology",
        "DM - Hepatology",
        "DM - High Altitude Medicine",
        "DM - Histopathology",
        "DM - Immunology",
        "DM - Infectious Disease",
        "DM - Infectious Diseases",
        "DM - Infectious Diseases (Direct 6 years)",
        "DM - Intensive Care",
        "DM - Interventional Radiology",
        "DM( Medical and Forensic Toxicology)",
        "DM - Medical Gastroenterology",
        "DM- Medical Gastroentrology",
        "DM - Medical Genetics",
        "DM-Medical Oncology",
        "DM - Neonatology",
        "DM - Nephrology",
        "DM - Neuro Anaesthesia",
        "DM- Neuro - Anesthesiology",
        "DM- Neuro - Anesthesiology & Critical Care",
        "DM - Neuroimaging & Interventional Neuroradiology",
        "DM - Neuroimaging & Interventional Radiology",
        "DM Neurology",
        "DM - Neurology",
        "DM - Neuropathology",
        "DM - Neuro Radiology",
        "DM - Onco-Anesthesia",
        "DM- Onco - Anesthesiology & Palliative Medicine",
        "DM - Oncology",
        "DM - Onco Pathology",
        "DM - Organ Transplant Anaesthesia & Critical Care",
        "DM - Paediatic Nephrology",
        "DM - Paediatic Rehabilitation",
        "DM - Paediatric Anaesthesia",
        "DM - Paediatric and Neonatal Anaesthesia",
        "DM - Paediatric Critical Care",
        "DM- Paediatric Emergency Medicine",
        "DM - Paediatric Endocrinology",
        "DM - Paediatric Haematology Oncology",
        "DM - Paediatric Hepatology",
        "DM - Paediatric Nephrology",
        "DM - Paediatric Neurology",
        "DM - Paediatric Oncology",
        "DM - Paediatric Pulmonology",
        "DM - Paediatric Pulmo-oncology & Intensive Care",
        "DM - Pain Medicine",
        "DM - Palliative Oncology",
        "DM - Pediatrics Cardiology",
        "DM - Pediatrics Gastroenterology",
        "DM - Preventive Oncology",
        "DM - Pul. Med. & Critical Care Med.",
        "DM- Pulmonary Critical Care & Sleep Medicine",
        "DM - Pulmonary Medicine",
        "DM- Pulmonary Medicine & Critical Care",
        "DM - Radiation Oncology (Head & Neck and Neuro)",
        "DM - Radiation Oncology (Urogenital & GYN)",
        "DM- Radiotherapy & Oncology ( 6 years )",
        "D.M.-Reproductive Medicine/M.Ch.-Reproductive Medicine and Surgery",
        "DM - Rheumatology/Clinical Immunology & Rheumatology",
        "DM - Therapeutic Nuclear Medicine",
        "DM - Theraputic Medicine",
        "DM - Trauma Anesthesia & Acute Care",
        "DM - Virology",
        "DM - Vitreo-retinal Surgery",
        "F.C.P.S.(Dermatology Venereology & Leprosy)",
        "F.C.P.S.(Medicine)",
        "F.C.P.S.(Mid. & Gynae)",
        "F.C.P.S.(Ophthalmology)",
        "F.C.P.S.(Pathology)",
        "F.C.P.S.(Surgery)",
        "Fellowship - Geriatic Mental Health Care",
        "Fellowship in Cardiac & Neuro Anesthesiolgy",
        "Fellowship in Critical Care",
        "Fellowship in Diabetology",
        "Fellowship in Medical Oncology",
        "Fellowship in Paediatric Clinical & Emergency Care",
        "Fellowship in Tropical Parasitology",
        "Fellowship - Mental Health Education",
        "Fellowship - Mental Health/Psychiatric Rehabilitation",
        "Fellowship - Skull Base Surgery Otorhinolaryngology",
        "Master Degree in Applied Epidemiology",
        "Master of Hospital Administration",
        "Master of Public Health",
        "Master of Public Health (Epidemiology)",
        "M. Ch Breast Endocrine and General Surgery",
        "M.Ch. - Breast Surgery",
        "M.Ch.(Burns & Plastic Surgery)",
        "M.Ch - Cardio Thoracic and Vascular Surgery",
        "M.Ch Cardiothoracic Surgery",
        "M.Ch Cardiothoracic & Thoracic Surgery",
        "M.Ch- Cardio- Vascular Surgery",
        "M.Ch. - Cosmetic Gynecology",
        "M.Ch. - Cranio Maxillofacial Surgery",
        "M.Ch - Endocrine Surgery",
        "M.Ch - Gynaecological Oncology",
        "M.Ch Gynaecologic Oncology",
        "M.Ch. - Hand Surgery",
        "M.Ch. - Head and Neck Surgery",
        "M.Ch Head - Neck Surgery and Oncology",
        "M.Ch - Hepato Pancreato Biliary Surgery",
        "M.Ch. - Joint Replacement & Reconstruction",
        "M.Ch. - Minimal Access Surgery and General Surgery",
        "M.Ch - Neuro Surgery",
        "M.Ch - Neuro Surgery 5 Year Course",
        "M.Ch - Neuro Surgery ( After M.S )",
        "M.Ch - Neuro Surgery (Direct 6 years)",
        "M.Ch - Paediatric Orthopaedics",
        "M.Ch - Paediatric Surgery",
        "M.Ch.- Paediatric Surgery - 3 yrs",
        "M.Ch - Paediatric Surgery (6 years Post MBBS)",
        "M.Ch.- Paediatric Surgery - 6 yrs",
        "M.Ch - Pediatric Cardio-Thoracic Vascular Surgery",
        "M. Ch - Pediatrics Surgery",
        "M.Ch - Pediatric Surgery",
        "M. Ch - Plastic & Reconstructive Surgery",
        "M.Ch - Plastic Surgery/Plastic & Reconstructive Surgery",
        "M. Ch- Reproductive Medicine & surgery",
        "M.Ch. - Spine Surgery",
        "M.Ch. - Sports Injury",
        "M.Ch.- Surgical Gastroenterology",
        "M.Ch - Surgical Gastroenterology/G.I. Surgery",
        "M.Ch - Surgical Oncology",
        "M.Ch - Thoracic Surgery/Cardio Thoracic Surgery/Cardio Vascular and thoracic Surgery",
        "M.Ch. - Trauma Surgery and Critical care",
        "M.Ch - Urology",
        "M.Ch - Urology/Genito-Urinary Surgery",
        "M.Ch - Vascular Surgery",
        "M C P S",
        "MD- Anaesthesia",
        "MD - Anaesthesiology",
        "MD- Anaesthesiology & Critical Care Med.",
        "MD - Anatomy",
        "MD - Aviation Medicine/Aerospace Medicine",
        "MD - Bacteriology",
        "MD- Biochemistry",
        "MD - Bio-Chemistry",
        "MD - Bio-Physics",
        "MD - CCM",
        "MD- Community & Family Medicine",
        "MD - Community Health Administration",
        "MD - Community Medicine",
        "MD - Dermatology",
        "MD - Dermatology Venereology & Leprosy",
        "MD - Emergency Medicine",
        "MD - Family Medicine",
        "MD - Forensic Medicine",
        "MD - Forensic Medicine/Forensic Medicine & Toxicology",
        "MD - General Medicine",
        "MD - Geriatrics",
        "MD - Hospital Administration",
        "MD - Immuno Haematology & Blood Transfusion",
        "MD Immunology Hematology and Blood Transfusion",
        "MD - Lab Medicine",
        "MD - Marine Medicine",
        "MD - Maternity & Child Health",
        "MD - Microbiology",
        "MD/MS - Anatomy",
        "MD/MS - Ophthalmology",
        "MD - Nuclear Medicine",
        "MD - Obstetrics & Gynaecology",
        "MD - Ophthalmology",
        "MD - Paediatrics",
        "MD - Palliative Medicine",
        "MD - Pathology",
        "MD- Pathology & Lab Medicine",
        "MD - Pathology & Microbiology",
        "MD - Pharmacology",
        "MD - Physical Medicine & Rehabilitation",
        "MD Physical Medicine Rehabilitation",
        "MD - Physiology",
        "MD - Psychiatry",
        "MD - Pulmonary Medicine",
        "MD - Radiation Oncology",
        "MD - Radio Diagnosis",
        "MD- Radio diagnosis and Interventional Radiology",
        "MD - Radio Diagnosis/Radiology",
        "MD - Radiology",
        "MD - Radiotherapy/ Radiation Oncology",
        "MD - Respiratory Medicine",
        "MD - Social & Preventive Medicine / Community Medicine",
        "MD - Sports Medicine",
        "MD - Transfusion Medicine",
        "MD- Transfusion Medicine and Blood Bank",
        "MD - Tropical Medicine",
        "MD - Tuberculosis & Respiratory Diseases / Pulmonary Medicine",
        "MD - Venereology",
        "M.Phil - Biophysics",
        "M.Phil - Neurophysiology",
        "M.Phil - Neurosciences",
        "MS - Anatomy",
        "M.Sc - Anatomy",
        "M.Sc - Medical Anatomy",
        "M.Sc - Medical Bacteriology",
        "M.Sc - Medical Bio-chemistry",
        "M.Sc - Medical Pathology",
        "M.Sc - Medical Pharmocology",
        "M.Sc - Medical Physics",
        "M.Sc - MLT (Cyto Pathology)",
        "M.Sc - MLT (Microbiology)",
        "M.Sc - Pathology",
        "M.Sc - Physiology",
        "MS - ENT",
        "MS - General Surgery",
        "MS - Neuro Surgery",
        "MS - Obstetrics and Gynaecology",
        "MS - Obstetrics & Gynaecology",
        "MS - Ophthalmology",
        "MS - Orthopaedics",
        "MS - Otorhinolaryngology",
        "MS - Traumatology and Surgery",
        "PDCC - Renal Pathology",
        "PDF - Acute Care & Emergency Psychiatry",
        "PDF - Addiction Medicine",
        "PDF - Apheresis",
        "PDF - Cardiac Electrophysiology and Pacing",
        "PDF - Certificate Course in Advance Imaging",
        "PDF - Child & Adolescent Psychiatry",
        "PDF - Clinical Neuroscience & Therapeutics in Schizophrenia",
        "PDF - Community Mental Health",
        "PDF - Consultation Liaison Psychiatry",
        "PDF - Critical care (Anesthesiology)",
        "PDF - Epilepsy",
        "PDF - Family Planning",
        "PDF - Forensic Psychiatry",
        "PDF - Geriatric Psychiatry",
        "PDF - Hemato Oncology",
        "PDF - Hemato Pathology",
        "PDF - Hepato Pancreatic Biliary Surgery",
        "PDF - Hospital Infection Control",
        "PDF - Infectious Diseases",
        "PDF - Lasers and Dermatosurgery",
        "PDF - Movement Disorder",
        "PDF - Nephro Pathology",
        "PDF - Neuroanaesthesia",
        "PDF - Neuro Critical Care",
        "PDF - Neuroinfection",
        "PDF - Neurological Rehabilitation",
        "PDF - Neuromuscular Disorder",
        "PDF - Neuropathology",
        "PDF - Non invasive Brain Stimulation of Psychiatric Disorder",
        "PDF - Obsessive Compulsive Disorder & Related disorders",
        "PDF - Obstetric Medicine",
        "PDF - Paediatric Dermatology",
        "PDF - Paediatric Neurology",
        "PDF - Paediatric Oncology",
        "PDF - Stroke",
        "PDF - Transfusion Medicine",
        "PDF - Womens Mental Health",
        "Ph. D - Anaesthesia",
        "Ph. D - Anatomy",
        "Ph. D - Bio- Chemistry",
        "Ph. D - Bio-Statistics",
        "Ph. D - Bio-Technology",
        "Ph. D - Cardiology",
        "Ph. D - Cardio Thoracic & Vascular Surgery",
        "Ph. D - Child & Adolescent Psychiatry",
        "Ph. D - Clinical Immunology",
        "Ph. D - Clinical Neuroscience",
        "Ph. D - Community Medicine",
        "Ph. D - Dermatology & Venereology",
        "Ph. D - Endocrinology & Metabolism",
        "Ph. D - ENT",
        "Ph. D - Forensic Medicine",
        "Ph. D - Gastro & Human Nutrition Unit",
        "Ph. D - Gastrointestinal Surgery",
        "Ph. D - Haematology",
        "Ph. D - Histo Compatibility & Immunogenetics",
        "Ph. D - History of Psychiatry",
        "Ph. D - Hospital Administration",
        "Ph. D - Lab Medicine",
        "Ph. D - Medical Oncology",
        "Ph. D - Medical Physics",
        "Ph. D - Medicine",
        "Ph. D - Mental Health Rehabilitation",
        "Ph. D - Microbiology",
        "Ph. D - Nephrology",
        "Ph. D - Neuroimaging & Interventional Radiology",
        "Ph. D - Neurology",
        "Ph. D - Neuro Magnetic Resonance",
        "Ph. D - Neuropathology",
        "Ph. D - Neurophysiology",
        "Ph. D - Neuro Surgery",
        "Ph. D - Nuclear Medicine",
        "Ph. D - Obst. & Gynae",
        "Ph. D - Ocular Bio Chemistry",
        "Ph. D - Ocular Microbiology",
        "Ph. D - Ocular Phramacology",
        "Ph. D - Orthopaedics",
        "Ph. D - Paediatric",
        "Ph. D - Paediatric Surgery",
        "Ph. D - Pathology",
        "Ph. D - Pharmacology",
        "Ph. D - Physical Medicine & Rehabilitation",
        "Ph. D - Physiology",
        "Ph. D - Psychiatry",
        "Ph. D - Radio Diagnosis",
        "Ph. D - Radiotherapy",
        "Ph. D - Surgery",
        "Ph. D - Urology",
        "MDS in Conservative Dentistry and Endodontics",
        "MDS in Oral and Maxillofacial Surgery",
        "MDS Oral Medicine and Radiology",
        "MDS in Oral Pathology and Microbiology",
        "MDS Orthodontics and Dentofacial Orthopaedics",
        "MDS in Pediatric and Preventive Dentistry",
        "MDS Periodontology",
        "MDS in Prosthodontics and Crown & Bridge",
        "MDS Public Health Dentistry",
        "PG Diploma	Orthodonitics & Dentofacial Orthopedics",
        "PG Diploma	Conservative Dentistry & Endodontics",
        "PG Diploma	Prosthodontics and Crown & Bridge",
        "PG Diploma	Pediatric and Preventive Dentistry",
        "Diploma	Dental Mechanics",
        "Postdoctoral Fellowship in CNS Demyelinating Disorders",
        "Postdoctoral Fellowship in Cognitive Neurosciences",
        "Postdoctoral Fellowship in Diagnostic Neuroimaging",
        "Postdoctoral Fellowship in Functional Neuroimaging",
        "Postdoctoral Fellowship in Integrative Mental Health and Neurosciences",
        "Postdoctoral Fellowship in Integrative Psychiatry",
        "Postdoctoral Fellowship in Neuropathy",
        "Postdoctoral Fellowship in Neuropsychiatry",
        "Postdoctoral Fellowship in Psychiatric Rehabilitation",
        "Postdoctoral Fellowship in Tele- Psychiatry",
        "Postgraduate Diploma in Genetic Counselling",
        "Radio-therapy",

        "Paediatrics - DCH (NBEMS)",
        "ENT - DLO (NBEMS)",
        "Obstetrics & Gynaecology - DGO (NBEMS)",
        "Anesthesiology - DA (NBEMS)",
        "Radio Diagnosis - DMRD (NBEMS)",
        "Ophthalmology - DO (NBEMS)",
        "Family Medicine - D Fam. Med. (NBEMS)",
        "Tuberculosis & Chest Disease - DTCD (NBEMS)",
        "Emergency Medicine - DEM (NBEMS)",

        "General Medicine",
        "Anaesthesiology",
        "Dermatology, Venereology and Leprosy",
        "Orthopaedics",
        "Paediatrics",
        "General Surgery",
        "Obstetrics and Gynaecology",
        "Otorhinolaryngology (ENT)",
        "Emergency Medicine",
        "Minimal Access Surgery",
        "Arthroplasty",
        "Radio Diagnosis",
        "Radiation Oncology",
        "Vascular Surgery",
        "Nephrology",
        "Critical Care Medicine",
        "Gynaecological Oncology",
        "Onco- Anaesthesia",
        "Medical Oncology",
        "Surgical Oncology",
        "Neuro Surgery (Direct 6 Years Course)",
        "Family Medicine",
        "Cardiology",
        "Ophthalmology",
        "Vitreo Retinal Surgery",
        "Nuclear Medicine",
        "Urology",
        "Medical Gastroenterology",
        "Bariatric Surgery",
        "Cardiac Electrophysiology",
        "Respiratory Medicine",
        "Interventional Radiology",
        "Neurology",
        "Spine Surgery",
        "Hand & Micro Surgery",
        "Plastic & Reconstructive Surgery",
        "Paediatric Critical Care",
        "Surgical Gastroenterology",
        "Paediatric Cardiology",
        "Neuro Surgery",
        "Neonatology",
        "Plastic & Reconstructive Surgery (Direct 6 Years Course)",
        "Pathology",
        "Paediatric Urology",
        "Pain Medicine",
        "Paediatric Anaesthesia",
        "Cardiac Anaesthesia",
        "Transplant Anaesthesia",
        "Neurovascular Intervention",
        "Infectious Diseases",
        "Paediatric Hemato-Oncology",
        "Cardio Vascular & Thoracic Surgery (Direct 6 Years Course)",
        "Head & Neck Oncology",
        "Interventional Cardiology",
        "Microbiology",
        "Colorectal Surgery",
        "Neuro Anaesthesia",
        "Endocrinology",
        "Clinical Haematology",
        "Immunohematology and Blood Transfusion",
        "Cardio Vascular & Thoracic Surgery",
        "Clinical Immunology and Rheumatology",
        "Paediatric Surgery",
        "Sports Medicine",
        "Psychiatry",
        "Minimal Access Urology",
        "Community Medicine",
        "Anatomy",
        "Physiology",
        "Pharmacology",
        "Palliative Medicine",
        "Paediatric Nephrology",
        "Paediatric Surgery (Direct 6 Years Course)",
        "Paediatric Gastroenterology",
        "Paediatric Neurology",
        "Maternal & Foetal Medicine",
        "Geriatric Medicine",
        "Reproductive Medicine",
        "Biochemistry",
        "Forensic Medicine",
        "Fetal Radiology",
        "Trauma Anaesthesia & Critical Care",
        "Musculoskeletal Radiology (MSK Radiology)",
        "Trauma & Acute Care Surgery",
        "Liver Transplantation",
        "Hospital Administration",
        "Breast Imaging",
        "Renal Transplant",
        "Physical Medicine and Rehabilitation",
        "Thoracic Surgery",
        "Stroke Medicine",
        "Medical Genetics",
        "Minimally Invasive Gynaecologic Surgery",

        "DNB Anesthesiology",
        "DNB Anatomy",
        "DNB Biochemistry",
        "DNB Dermatology, Venereology and Leprosy",
        "DNB Emergency Medicine",
        "DNB Forensic Medicine",
        "DNB Family Medicine",
        "DNB General Medicine",
        "DNB Nuclear Medicine",
        "DNB General Surgery",
        "DNB Hospital Administration",
        "DNB Immunohematology and Blood Transfusion",
        "DNB Maternal and Child Health",
        "DNB Microbiology",
        "DNB Otorhinolaryngology (ENT)",
        "DNB Obstetrics and Gynaecology",
        "DNB Ophthalmology",
        "DNB Orthopaedics",
        "DNB Paediatrics",
        "DNB Palliative Medicine",
        "DNB Pathology",
        "DNB Pharmacology",
        "DNB Physical Medicine and Rehabilitation",
        "DNB Physiology",
        "DNB Psychiatry",
        "DNB Radio Diagnosis",
        "DNB Radiation Oncology",
        "DNB Respiratory Medicine",
        "DNB Community Medicine",

        "Diploma Anesthesiology",
        "Diploma Family Medicine",
        "Diploma Obstetrics & Gynecology",
        "Diploma Ophthalmology",
        "Diploma Otorhinolaryngology (ENT)",
        "Diploma Radio Diagnosis",
        "Diploma Pediatrics",
        "Diploma Tuberculosis and Chest Diseases",

        "DRNB Cardiac Anaesthesia",
        "DRNB Cardio Vascular & Thoracic Surgery",
        "DRNB Cardiology",
        "DRNB Clinical Haematology",
        "DRNB Clinical Immunology & Rheumatology",
        "DRNB Critical Care Medicine",
        "DRNB Endocrinology",
        "DRNB Medical Gastroenterology",
        "DRNB Urology",
        "DRNB Gynaecological Oncology",
        "DRNB Interventional Radiology",
        "DRNB Plastic & Reconstructive Surgery",
        "DRNB Surgical Gastroenterology",
        "DRNB Surgical Oncology",
        "DRNB Thoracic Surgery",
        "DRNB Medical Genetics",
        "DRNB Neurology",
        "DRNB Vascular Surgery",
        "DRNB Paediatric Surgery",
        "DRNB Pediatric Cardiology",
        "DRNB Paediatric Critical Care",
        "DRNB Paediatric Neurology",
        "DRNB Medical Oncology",
        "DRNB Neonatology",
        "DRNB Nephrology",
        "DRNB Neuro Anaesthesia",
        "DRNB Neuro Surgery",
        "UnSpecified",
    ],
    easeLevels: ["Almost impossible", "Very difficult", "Moderately difficult", "Not at all difficult", "Super chill"],
    ratingFields: ["Work-Life Balance", "Hands-on Experience", "Work Culture in Your Department", "Infrastructure", "Research Opportunities", "Faculty", "Security", "Academics", "Overall Rating"],
    toxicityLevels: ["Highly toxic", "Moderately toxic", "Slightly toxic", "Not at all toxic", "Super chill"],
    patientLoadOption: [
        {
            label: "Daily New Admissions",
            name: "weeklyNewAdmissions",
            options: ["0-10", "10-20", "20-30", "30-40", "40+"],
        },
        {
            label: "Daily OPD Load",
            name: "weeklyOpdLoad",
            options: ["0-200", "200-400", "400-600", "600-800", "1000+"],
        },
    ],
    sampleFormData: {
        user: "64f45dc19a134f001f4d1e72",
        organizationName: "ABC",
        ratings: [
            { criteria: "Work-Life Balance", stars: 4 },
            { criteria: "Infrastructure", stars: 5 },
            { criteria: "Research Opportunities", stars: 3 },
        ],
        toxicity: {
            level: "Slightly toxic",
            specificIncident: "Occasional arguments in the department.",
        },
        leaves: {
            ease: "Moderately difficult",
            additionalComments: "Leave approval process could be streamlined.",
        },
        hostel: {
            available: true,
            sufficient: false,
        },
        bond: {
            isApplicable: true,
            durationYears: "2 years",
            amount: "500,000",
        },
        workingHours: {
            weeklyHours: 60,
            maxDutyStretchHours: 36,
        },
        passingRate: 85,
        improvementSuggestions: "Better work-life balance and flexible scheduling.",
        positiveAspects: "Supportive faculty and good hands-on experience.",
    },
    validateSchema: {
        organizationName: { required: true, message: "Organization Name is required." },
        //  designation: { required: true, message: "Designation is required." },
        course: { required: true, message: "Course is required." },
        ratings: [
            { criteria: "Work-Life Balance", required: true, message: "Rating for Work-Life Balance is required." },
            { criteria: "Hands-on Experience", required: true, message: "Rating for Hands-on Experience is required." },
            { criteria: "Work Culture in Your Department", required: true, message: "Rating for Work Culture in Your Department is required." },
            { criteria: "Infrastructure", required: true, message: "Rating for Infrastructure is required." },
            { criteria: "Research Opportunities", required: true, message: "Rating for Research Opportunities is required." },
            { criteria: "Faculty", required: true, message: "Rating for Faculty is required." },
            { criteria: "Security", required: true, message: "Rating for Security is required." },
            { criteria: "Academics", required: true, message: "Rating for Academics is required." },
            { criteria: "Overall Rating", required: true, message: "Rating for Overall Rating is required." },
        ],
        patientLoad: {
            weeklyNewAdmissions: { required: true, message: "Weekly New Admissions is required." },
            weeklyOpdLoad: { required: true, message: "Weekly OPD Load is required." },
        },
        toxicity: {
            level: { required: true, message: "Toxicity Level is required." },
            // specificIncident: { required: true, message: "Specific Incident is required." },
        },
        leaves: {
            ease: { required: true, message: "Ease of taking leaves is required." },
        },
        hostel: {
            available: { required: true, message: "Hostel availability is required." },
            //  sufficient: { required: (formValues) => formValues.hostel?.available, message: "Hostel sufficiency is required." },
        },
        bond: {
            isApplicable: { required: true, message: "Bond applicability is required." },
            durationYears: { required: (formValues) => formValues.bond?.isApplicable, message: "Bond duration is required." },
            amount: { required: (formValues) => formValues.bond?.isApplicable, message: "Bond amount is required." },
        },
    },
};

export default constants;
