import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import CustomSelect from "../FormFields/Select/CustomSelect";
import RatingInput from "../FormFields/rating";
import Stepper from "../FormFields/Stepper";
import CustomCheckbox from "../../../../Components/Inputs/CustomCheckbox";
import constants from "../../Utils/constants";
import { useReviewsJobContext } from "../../../../Api/reviews";
import SubmittedAlerts from "../SubmittedAlerts";
import ICON from "../../Utils/icons";
import Utils from "../../Utils";
import ErrorMessage from "../ErrorMessage";
import ReviewAuth from "../reviewAuth";
import { useSearchParams } from "react-router-dom";

const ReviewForm = ({ setProgress }) => {
    const initialState = { ratings: [] };
    const [formValues, setFormValues] = useState(initialState);
    const [show, setShow] = useSearchParams();
    const [errors, setErrors] = useState({});
    const fieldRefs = useRef({});
    const { postReviewsInfo, loading, success } = useReviewsJobContext();
    const [showPopUp, setShowPopUp] = useState(false);

    const handleChange = (event) => {
        // console.log(event);
        const { value, name, parentKey } = event.target;
        if (parentKey) {
            setFormValues((pre) => ({ ...pre, [parentKey]: { ...pre[parentKey], [name]: value } }));
        } else {
            setFormValues((pre) => ({ ...pre, [name]: value }));
        }
    };

    const handleChangeRating = (event) => {
        const { name, value } = event.target;
        const data = { criteria: name, stars: value };
        setFormValues((pre) => {
            if (pre?.ratings) {
                let dataIndex = null;
                pre.ratings.map((item, index) => {
                    if (item.criteria === name) {
                        dataIndex = index;
                    }
                });
                if (dataIndex || dataIndex === 0) {
                    pre.ratings[dataIndex] = data;
                } else {
                    pre.ratings.push(data);
                }
            } else {
                pre.ratings = [data];
            }

            return pre;
        });
    };
    const scrollToFirstError = () => {
        const firstErrorKey = Object.keys(errors)[0];
        if (firstErrorKey && fieldRefs.current[firstErrorKey]) {
            fieldRefs.current[firstErrorKey].scrollIntoView({ behavior: "smooth" });
            fieldRefs.current[firstErrorKey].focus();
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors({});
        if (!Utils.validateForm(setErrors, formValues)) {
            scrollToFirstError();
            return;
        }
        formValues.user = Utils.generateRandomString();
        await postReviewsInfo("/v1/review", formValues, () => {
            setShow(
                (prev) => {
                    prev.set("show", true);
                    prev.set("auth-option", true);
                    return prev;
                },
                { replace: true }
            );
            setFormValues(initialState);
        });
    };

    useEffect(() => {
        Utils.calculateProgress(formValues, setProgress);
    }, [formValues]);

    const extractOptionsText = () => {
        // Select the <select> element by its id
        const selectElement = document.getElementById("hosp");
        if (!selectElement) return [];

        // Get all <option> elements within the <select>
        const options = Array.from(selectElement.options);

        // Extract the text content of each option
        const optionTexts = options.map((option) => option.textContent.trim()).filter((text) => text !== "Select Hospital / Institute Now" && text); // Exclude placeholder and empty strings

        return optionTexts;
    };
    // console.log(extractOptionsText());

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit}>
                <div className={styles.input_field}>
                    <label htmlFor="hospital_list">
                        Hospitals Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <CustomSelect
                        name={"organizationName"}
                        formValues={""}
                        handleSelectChange={handleChange}
                        options={constants.collegeList.map((item) => ({ value: item, label: item }))}
                        multiple={false}
                        required={true}
                        disabled={false}
                        classNames={[]}
                        placeholder={`Enter ${"Hospital name"}`}
                    />
                    {errors.organizationName && <ErrorMessage message={errors.organizationName} />}
                </div>

                <div style={{ marginTop: "-0.6rem" }} className={styles.input_field}>
                    <label htmlFor="course">
                        Course <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    {/* <input placeholder="Enter Course" id="course" type="text" name="course" onChange={handleChange} /> */}

                    <CustomSelect
                        name={"course"}
                        formValues={""}
                        handleSelectChange={handleChange}
                        options={constants.courseList.map((item) => ({ value: item, label: item }))}
                        multiple={false}
                        required={true}
                        disabled={false}
                        classNames={[]}
                        placeholder={`Enter ${"Course"}`}
                    />
                    {errors.course && <ErrorMessage message={errors.course} />}
                </div>

                <div className={styles.rating_field_container}>
                    <h2>{"Rate the hospital on the following criteria"}</h2>
                    <div ref={(el) => (fieldRefs.current.ratings = el)}>
                        {constants.ratingFields.map((ratingField, index) => (
                            <>
                                <div className={styles.start_field}>
                                    <label htmlFor={ratingField}>
                                        {ratingField} <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <RatingInput name={ratingField} onChange={handleChangeRating} />
                                </div>
                                {errors.ratings?.[ratingField] && <ErrorMessage message={errors.ratings[ratingField]} />}
                            </>
                        ))}
                    </div>
                </div>
                <div className={styles.patient_load}>
                    <h2>{"Patient Load"}</h2>
                    <div ref={(el) => (fieldRefs.current.patientLoad = el)}>
                        {constants.patientLoadOption.map((item) => (
                            <div>
                                <p>
                                    {item.label} <span style={{ color: "red" }}>*</span>
                                </p>
                                {item.options.map((option) => (
                                    <CustomCheckbox
                                        key={option}
                                        parentKey="patientLoad"
                                        name={item.name}
                                        label={option}
                                        checked={formValues?.patientLoad?.[item.name] === option}
                                        onChange={handleChange}
                                    />
                                ))}
                                {errors.patientLoad?.[item.name] && <ErrorMessage message={errors.patientLoad[item.name]} />}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.rating_field_container}>
                    <h2>
                        {"Toxicity in Your Department"} <span style={{ color: "red" }}>*</span>
                    </h2>
                    <div ref={(el) => (fieldRefs.current.toxicity = el)}>
                        {constants.toxicityLevels.map((label) => (
                            <CustomCheckbox key={label} parentKey="toxicity" name="level" label={label} checked={formValues?.toxicity?.level === label} onChange={handleChange} />
                        ))}
                        {errors.toxicity?.["level"] && <ErrorMessage message={errors.toxicity["level"]} />}
                    </div>
                    <div className={`${styles.input_field} ${styles.toxicity_description}`}>
                        <label htmlFor="specificIncident">Any specific Incident that you want to share representing the toxicity in your department</label>
                        <textarea
                            placeholder="Write here any Incident"
                            parentKey="toxicity"
                            id="specificIncident"
                            type="text"
                            name="specificIncident"
                            onChange={(event) => handleChange({ target: { name: event.target.name, value: event.target.value, parentKey: "toxicity" } })}
                        />
                    </div>

                    <div className={`${styles.input_field} ${styles.toxicity_description}`}>
                        <label htmlFor="specificIncidentSecond">
                            Have there been any specific incidents of residents resigning due to excessive workload or bias based on state, gender, or caste?
                        </label>
                        <textarea
                            placeholder="Write here any Incident"
                            parentKey="toxicity"
                            id="specificIncidentSecond"
                            type="text"
                            name="specificIncidentSecond"
                            onChange={(event) => handleChange({ target: { name: event.target.name, value: event.target.value, parentKey: "toxicity" } })}
                        />
                    </div>
                </div>

                <div className={styles.rating_field_container}>
                    <h2>
                        {"Leaves - How easy is it to take leave for an emergency?"} <span style={{ color: "red" }}>*</span>
                    </h2>
                    <div ref={(el) => (fieldRefs.current.leaves = el)}>
                        {constants.easeLevels.map((label) => (
                            <CustomCheckbox key={label} parentKey="leaves" name="ease" label={label} checked={formValues?.leaves?.ease === label} onChange={handleChange} />
                        ))}
                        {errors.leaves?.ease && <ErrorMessage message={errors.leaves?.ease} />}
                    </div>
                </div>
                <div className={styles.hospital_available_container}>
                    <h2>{"Hostel Availability"}</h2>
                    <div ref={(el) => (fieldRefs.current.hostel = el)}>
                        <p>
                            Is accommodation available for residents? <span style={{ color: "red" }}>*</span>
                        </p>
                        <div>
                            <label htmlFor="available">
                                <CustomCheckbox parentKey="hostel" name="available" label="Yes" customValue={true} checked={formValues?.hostel?.available === true} onChange={handleChange} />
                            </label>
                            <label htmlFor="available">
                                <CustomCheckbox parentKey="hostel" name="available" label="No" customValue={false} checked={formValues?.hostel?.available === false} onChange={handleChange} />
                            </label>
                        </div>
                        {errors.hostel?.available && <ErrorMessage message={errors.hostel?.available} />}
                    </div>

                    {formValues?.hostel?.available === true && (
                        <div>
                            <p>
                                Is there enough accommodation for all residents? <span style={{ color: "red" }}>*</span>
                            </p>
                            <div>
                                <label htmlFor="sufficient">
                                    <CustomCheckbox parentKey="hostel" name="sufficient" customValue={true} label="Yes" checked={formValues?.hostel?.sufficient === true} onChange={handleChange} />
                                </label>
                                <label htmlFor="sufficient">
                                    <CustomCheckbox parentKey="hostel" name="sufficient" customValue={false} label="No" checked={formValues?.hostel?.sufficient === false} onChange={handleChange} />
                                </label>
                            </div>
                            {errors.hostel?.sufficient && <ErrorMessage message={errors.hostel?.sufficient} />}
                        </div>
                    )}
                </div>

                <div className={styles.hospital_available_container}>
                    <h2>{"Bond"}</h2>

                    <div ref={(el) => (fieldRefs.current.bond = el)}>
                        <p>
                            Is there a bond period in your course? <span style={{ color: "red" }}>*</span>
                        </p>
                        <div>
                            <label htmlFor="isApplicable">
                                <CustomCheckbox parentKey="bond" name="isApplicable" label="Yes" customValue={true} checked={formValues?.bond?.isApplicable === true} onChange={handleChange} />
                            </label>
                            <label htmlFor="isApplicable">
                                <CustomCheckbox parentKey="bond" name="isApplicable" label="No" customValue={false} checked={formValues?.bond?.isApplicable === false} onChange={handleChange} />
                            </label>
                        </div>
                        {errors.bond?.isApplicable && <ErrorMessage message={errors.bond?.isApplicable} />}
                    </div>
                </div>
                {formValues?.bond?.isApplicable === true && (
                    <div>
                        <div className={styles.bond_fields}>
                            <div className={styles.input_field}>
                                <label htmlFor="durationYears">
                                    Bond Duration (In Years) <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    placeholder="Enter Duration"
                                    id="durationYears"
                                    type="number"
                                    name="durationYears"
                                    required
                                    onChange={(event) => handleChange({ target: { name: event.target.name, value: event.target.value, parentKey: "bond" } })}
                                />
                            </div>
                            <div className={styles.input_field}>
                                <label htmlFor="amount">
                                    Bond Amount <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    placeholder="Enter Amount"
                                    id="amount"
                                    type="number"
                                    name="amount"
                                    required
                                    onChange={(event) => handleChange({ target: { name: event.target.name, value: event.target.value, parentKey: "bond" } })}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.hospital_available_container}>
                    <h2>{"Are you currently pursuing this course?"}</h2>

                    <div ref={(el) => (fieldRefs.current.isPursuingCourse = el)}>
                        <div>
                            <label htmlFor="isPursuingCourse">
                                <CustomCheckbox name="isPursuingCourse" label="Yes" customValue={true} checked={formValues?.isPursuingCourse === true} onChange={handleChange} />
                            </label>
                            <label htmlFor="isPursuingCourse">
                                <CustomCheckbox name="isPursuingCourse" label="No" customValue={false} checked={formValues?.isPursuingCourse === false} onChange={handleChange} />
                            </label>
                        </div>
                    </div>
                </div>

                <div className={styles.rating_field_container}>
                    <h2>Stipend (per month)</h2>
                    <div className={styles.working_hours_fields}>
                        <div className={styles.input_field}>
                            <label htmlFor="stipend">
                                Enter Monthly Stipend <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <input placeholder="Enter amount in rupees" id="stipend" type="number" name="stipend" required onChange={handleChange} />
                        </div>
                    </div>
                </div>

                <div className={styles.rating_field_container}>
                    <h2>{"Working Hours"} </h2>
                    <div className={styles.working_hours_fields}>
                        <div className={styles.input_field}>
                            <label htmlFor="weeklyHours">
                                Number of Hours Worked per Week <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <input
                                placeholder="Enter hours in number only"
                                id="weeklyHours"
                                type="number"
                                name="weeklyHours"
                                required
                                onChange={(event) => handleChange({ target: { name: event.target.name, value: parseInt(event.target.value), parentKey: "workingHours" } })}
                            />
                        </div>
                        <div className={styles.input_field}>
                            <label htmlFor="maxDutyStretchHours">
                                Max Duty in One Stretch <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                placeholder="Enter hours in number only"
                                id="maxDutyStretchHours"
                                type="number"
                                name="maxDutyStretchHours"
                                required
                                onChange={(event) => handleChange({ target: { name: event.target.name, value: parseInt(event.target.value), parentKey: "workingHours" } })}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.rating_field_container}>
                    <h2>{"Passing Rate"}</h2>
                    <div className={styles.working_hours_fields}>
                        <div className={styles.input_field}>
                            <label htmlFor="passingRate">
                                What is the passing rate in your program? <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <input required placeholder="Enter percentage in number only" id="passingRate" type="number" name="passingRate" onChange={handleChange} />
                        </div>
                    </div>
                </div>

                <div className={styles.rating_field_container}>
                    <h2>{"Overall Experience"}</h2>
                    <div className={styles.working_hours_fields}>
                        <div className={styles.input_field}>
                            <label htmlFor="improvementSuggestions">
                                What aspects of your residency program do you think could be Improved? <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea required placeholder="Write Improvement Suggestions" id="improvementSuggestions" type="text" name="improvementSuggestions" onChange={handleChange} />
                        </div>
                        <div className={styles.input_field}>
                            <label htmlFor="positiveAspects">
                                Things that you like about the program in your hospital <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea required placeholder="Write Positive Aspects" id="positiveAspects" type="text" name="positiveAspects" onChange={handleChange} />
                        </div>
                        <div className={styles.input_field}>
                            <label htmlFor="recommendCollege">
                                Would you recommend this college to your junior? If so, what are the reasons? If not, why not? <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea required placeholder="Write your reasons" id="recommendCollege" type="text" name="recommendCollege" onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className={styles.button_container}>
                    <button>
                        {loading ? (
                            ICON.LOADING
                        ) : (
                            <>
                                <span>{ICON.SAVE}</span>
                                <span>Submit Anonymously</span>
                            </>
                        )}
                    </button>
                </div>
            </form>
            <ReviewAuth show={show} setShow={setShow} />
            {/* {success && <SubmittedAlerts />} */}
        </div>
    );
};

export default ReviewForm;
