import React, { useState } from "react";
import styles from "./index.module.css";
import { useReviewsJobContext } from "../../../../../../Api/reviews";
import ICON from "../../../../Utils/icons";

const EmailForm = ({ setShow }) => {
    const { appendUserDetailsInReviewsForm, loading } = useReviewsJobContext();
    const [formValues, setFormValues] = useState({ email: "" });
    const handleChange = (event) => {
        const { value, name } = event.target;
        setFormValues({ [name]: value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const userId = localStorage.getItem("userId");
        appendUserDetailsInReviewsForm(
            `/v1/review/${userId}`,
            {
                email: formValues.email,
            },
            () => {
                setShow(
                    (prev) => {
                        prev.delete("auth-option");
                        prev.set("verify", true);
                        prev.delete("email");
                        return prev;
                    },
                    { replace: true }
                );
            }
        );
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit} action="">
                <div className={styles.input_field}>
                    <label htmlFor="email">Enter Email</label>
                    <input onChange={handleChange} type="email" name="email" id="email" />
                    <p>You'll receive a verification code via email.</p>
                </div>
                <div className={styles.button_container}>
                    <button disabled={!isValidEmail(formValues.email)}>
                        {" "}
                        {loading ? (
                            ICON.LOADING
                        ) : (
                            <>
                                <span>Continue</span>
                            </>
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EmailForm;

// Client ID
// 926107191670-pcgus6vcfo9f4j281ctt6dh53jnac7ao.apps.googleusercontent.com

// Client secret
// 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
