import { createContext, useContext, useState } from "react";
import { axiosInstance } from "./Interceptors";
import { toast } from "react-toastify";

const ReviewsJobContext = createContext();

export function useReviewsJobContext() {
    return useContext(ReviewsJobContext);
}

export function ReviewsJobProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [reviewList, setReviewList] = useState({});
    const notifyError = (message) => toast.error(message);
    const postReviewsInfo = async (url, data, refreshFunction) => {
        setLoading(true);
        axiosInstance
            .post(url, data)
            .then((response) => {
                console.log(response.data);
                localStorage.setItem("userId", response.data.data.user);
                localStorage.setItem("userSequence", response.data.userSequence);
                setSuccess("Review submitted successfully");
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
                notifyError(error.response.data.data.error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const appendUserDetailsInReviewsForm = async (url, data, refreshFunction) => {
        setLoading(true);
        await axiosInstance
            .patch(url, data)
            .then(() => {
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
                // notifyError(error.response.data.data.error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const verifyUserTokenInReviewsForm = async (url, data, refreshFunction) => {
        setLoading(true);
        await axiosInstance
            .post(url, data)
            .then(() => {
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getReviewList = async (url, refreshFunction) => {
        setLoading(true);
        axiosInstance
            .get(url)
            .then((response) => {
                console.log(response.data);
                setReviewList(response.data);
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
                notifyError(error.response.data.data.error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ReviewsJobContext.Provider
            value={{
                postReviewsInfo,
                appendUserDetailsInReviewsForm,
                verifyUserTokenInReviewsForm,
                loading,
                success,

                getReviewList,
                reviewList,
            }}
        >
            {children}
        </ReviewsJobContext.Provider>
    );
}
